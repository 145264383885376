// stylelint-disable selector-class-pattern, declaration-no-important, selector-no-qualifying-type

.vc-password-input {
  position: relative;
  box-shadow: none;
  border: none;
  border-radius: 0;
  transition: box-shadow 0.4s;

  &:has(input:focus),
  &:valid:has(input:focus),
  &.is-valid:has(input:focus) {
    outline: 2px solid $bm-primary;
    outline-offset: 3px;
  }

  &--no-focus {
    &:has(input:focus),
    &:valid:has(input:focus),
    &.is-valid:has(input:focus) {
      outline: none;
    }
  }
}

.vc-password-input__field {
  background-color: $bm-white;
  padding-right: 50px;
}

.vc-password-input__toggle {
  position: absolute;
  right: 12px;
  top: 16%;
  z-index: 2;
  padding: 5px;
  background-color: transparent;
  border: 0;

  &__icon {
    margin: auto;
    color: $bm-neutral-400;
  }

  &:focus {
    outline: 2px solid $bm-primary;
    outline-offset: 3px;
    box-shadow: none;
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
}
