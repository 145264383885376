.vc-video-tile {
  position: relative;
  display: block;
  overflow: hidden;
  user-select: none;

  &:hover {
    text-decoration: none;
  }

  &--light {
    --vc-video-tile-heading-color: #{$bm-black};
    --vc-video-tile-paragraph-color: #{$bm-grey-dark};
  }

  &--dark {
    --vc-video-tile-heading-color: #{$bm-white};
    --vc-video-tile-paragraph-color: #{$bm-neutral-100};
    --vc-video-tile-meta-info-color: #{$bm-neutral-200};
  }

  &__heading {
    color: var(--vc-video-tile-heading-color);
    font-size: 1rem;
    font-weight: 500;

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
    }
  }

  &__duration {
    bottom: map-get($bm_spacers, 3);
    right: map-get($bm_spacers, 3);
  }

  &__paragraph {
    font-size: 0.75rem;
    color: var(--vc-video-tile-paragraph-color);

    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
  }

  &__meta {
    font-size: 0.75rem;
    color: var(--vc-video-tile-meta-info-color);

    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
  }

  &__background {
    @include aspect-ratio(9, 16, 100%);

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3em;
    background-position: center;
    background-size: cover;

    &__progress-background {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: map-get($bm_spacers, 1);
      background: $bm-grey-medium-dark;
    }

    &__progress {
      position: absolute;
      bottom: 0;
      left: 0;
      height: map-get($bm_spacers, 1);
      background: $bm-primary;
    }
  }

  &__image-container {
    width: 25%;

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  &__image {
    align-items: flex-start;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }
}
