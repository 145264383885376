.bm-carousel {
  position: relative;
  max-width: (1104rem / 16);
  padding: 0 (15rem / 16);
  margin: 0 auto;

  &__slide {
    visibility: hidden;
    transition: visibility 0s;
    transition-delay: 0.6s;

    &-active {
      visibility: visible;
      transition-delay: 0s;
    }
  }

  & .bm-container-md {
    padding: 0;
  }

  & .bm-promo__container {
    // Avoid slides overflowing when not in view
    padding: 0 5px;
  }

  &__btn-container {
    display: none;
  }

  &__btn {
    position: absolute;
    touch-action: manipulation;

    // Overriding bootstrap rules
    // stylelint-disable selector-class-pattern, declaration-no-important
    &:hover,
    &:focus,
    &:active.btn {
      box-shadow: none !important;
    }
    // stylelint-enable

    & svg {
      width: 16px;
      height: 18px;

      & polyline {
        stroke: $bm-secondary;
      }
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  & .bm-promo__cta {
    text-align: center;
  }

  & .bm-slider__pagination-container {
    margin: 2rem 0 -1rem;
  }

  @include media-breakpoint-up(md) {
    padding: 0 5rem;

    &__btn-container {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      max-width: none;
      height: (82rem / 16);
      margin: 0;
      transform: translateY(-50%);
    }

    &__btn {
      padding: 2rem 1rem;

      &:hover {
        background-color: $bm-grey-lighter;
      }

      &--left {
        left: 1rem;
      }

      &--right {
        right: 1rem;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: (1200rem / 16);
    padding: 0 4rem;
  }

  @media only screen and (min-width: 1240px) {
    &__btn {
      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }
    }
  }
}
