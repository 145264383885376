.bm-checkout__survey {
  &--position-above-payment-button {
    position: relative;
    bottom: 100px;
  }

  &--position-above-payment-errors {
    position: relative;
    bottom: 210px;
  }

  &--position-below-survey {
    position: relative;
    top: 100px;
  }
}
