.bm-explore {
  &__featured_course + &__continue_learning {
    @include media-breakpoint-up(lg) {
      margin-top: -100px;
    }

    @include media-breakpoint-up(xl) {
      margin-top: -150px;
    }
  }

  &__featured_course + .bm-survey {
    @include media-breakpoint-up(xl) {
      margin-top: -100px;
    }
  }
}
