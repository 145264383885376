// Some things have to be overriden forcefully here
// stylelint-disable
@mixin permamnent-control-bar {
  .vjs-control-bar {
    display: flex !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.video-js button {
  &.btn-primary {
    @extend .btn-primary;

    &:hover,
    &:active {
      background-color: $bm-primary-dark;
    }

    &:focus {
      background-color: $bm-primary-dark !important;
    }
  }

  &.btn-ghost-white {
    background-color: $bm-neutral-900;

    &:hover,
    &:active {
      background-color: $bm-neutral-900;
      color: $bm-neutral-50;
    }

    &:focus {
      background-color: $bm-neutral-900 !important;
    }
  }
}

.vjs-bm.vjs-paused {
  .vjs-play-pause .vjs-icon-placeholder::before {
    background-image: url("../images/player/play.svg");
  }

  .vjs-play-pause .vjs-icon-placeholder {
    background-color: $bm-primary-500;
  }
}

.vjs-bm.vjs-playing {
  .vjs-play-pause .vjs-icon-placeholder::before {
    background-image: url("../images/player/pause.svg");
  }
}

.vjs-bm {
  & .vjs-control-bar {
    background: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.084) 7.6%,
      hsla(0, 0%, 0%, 0.171) 14.7%,
      hsla(0, 0%, 0%, 0.26) 21.2%,
      hsla(0, 0%, 0%, 0.35) 27.2%,
      hsla(0, 0%, 0%, 0.439) 33%,
      hsla(0, 0%, 0%, 0.526) 38.7%,
      hsla(0, 0%, 0%, 0.61) 44.2%,
      hsla(0, 0%, 0%, 0.689) 49.8%,
      hsla(0, 0%, 0%, 0.762) 55.6%,
      hsla(0, 0%, 0%, 0.827) 61.6%,
      hsla(0, 0%, 0%, 0.884) 68.1%,
      hsla(0, 0%, 0%, 0.932) 75%,
      hsla(0, 0%, 0%, 0.967) 82.6%,
      hsla(0, 0%, 0%, 0.991) 90.9%,
      hsl(0, 0%, 0%) 100%
    );
  }

  & .vjs-control-bar {
    flex-wrap: wrap;
    align-items: center;
    height: auto;
    padding: 1.3rem;
    font-family: $font-family-roboto-bold;
    font-size: $font-size-xs;
    color: $bm-tertiary;

    & .vjs-control {
      margin-right: 1.3rem;

      // Prevent text highlighting for touch devices
      -webkit-user-select: none; /* Chrome/Safari */
      -moz-user-select: none; /* Firefox */
      user-select: none;
    }

    & .vjs-progress-control {
      margin-right: 0 !important;
    }

    @include media-breakpoint-down(xs) {
      padding: 0.7rem 0.5rem;

      & .vjs-control {
        margin-right: 0.8rem;
      }
    }
  }

  // For mobile & tablet, the first click should show the controls, the second
  // should toggle play/pause. As the control bar fills the screen it captures all clicks
  &.vjs-touch-enabled.vjs-user-inactive:not(.vjs-paused) .vjs-control-bar {
    pointer-events: none;
  }

  @include media-breakpoint-down(md) {
    &.vjs-user-active .vjs-text-track-display,
    &.vjs-paused .vjs-text-track-display {
      height: 25%;
    }
  }

  & .vjs-slider:focus {
    box-shadow: 0, 0, 10px, 3px, $bm-primary;

    &:not(.focus-visible) {
      outline: none;
      box-shadow: none;
    }
  }

  & .vjs-control {
    &:focus,
    & button:focus {
      outline: 2px solid $bm-primary;

      & .vjs-icon-placeholder {
        background-color: rgba($bm-bg-grey-darker, 0.6);
      }

      &.vjs-play-pause .vjs-icon-placeholder {
        background-color: $bm-primary-500;
      }
    }

    &:focus:not(.focus-visible) {
      outline: none;

      & .vjs-icon-placeholder {
        background-color: rgba($bm-bg-grey-darker, 0.2);
        background-color: $bm-primary;
      }
    }
  }

  & .vjs-icon-placeholder {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    background-color: rgba($bm-grey-dark, 0.2);

    @media (hover: hover) {
      &:hover {
        background-color: $bm-primary;
      }
    }

    &::before {
      @include abs-center;
      width: 100%;
      max-width: 17px;
      height: 100%;
      max-height: 17px;
      content: "" !important;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
    }

    @include media-breakpoint-down(xs) {
      width: 35px;
      height: 35px;

      &::before {
        width: 12px;
      }
    }
  }

  // Progress
  & .vjs-progress-control {
    width: 100%;
    height: 3px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    & .vjs-progress-holder {
      margin: 0 0 1.3rem;
      background-color: rgba($bm-grey-light, 0.5);

      &:focus {
        background-color: rgba($bm-grey-light, 0.5);
      }
    }

    & .vjs-mouse-display {
      width: 3px;
      background-color: $bm-primary;

      & .vjs-time-tooltip {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      order: 4;
    }
  }

  & .vjs-play-progress {
    background-color: $bm-primary;

    &::before {
      top: -100%;
      right: 0;
      width: 10px;
      height: 10px;
      content: "";
      background-color: $bm-tertiary;
      transition: transform 0.2s, top 0.2s, right 0.2s;
    }
  }

  & .vjs-progress-control:hover {
    & .vjs-play-progress::before {
      top: -50%;
      right: 0;
      transform: scale(1.6);
    }
  }

  & .vjs-load-progress {
    background-color: $bm-grey-light;
  }

  // CONTROL BUTTONS

  // Play/Pause
  & .vjs-play-control {
    width: 35px;

    @include media-breakpoint-up(sm) {
      width: 40px;
    }

    @include media-breakpoint-down(md) {
      order: 5;
    }
  }

  & .vjs-play-control .vjs-icon-placeholder::before {
    content: url("");
    background-image: url("../images/player/play.svg");
  }

  & .vjs-play-control.vjs-playing .vjs-icon-placeholder::before {
    left: 49%;
    content: url("");
    background-image: url("../images/player/pause.svg");
  }

  & .vjs-play-control.vjs-ended .vjs-icon-placeholder::before {
    content: url("");
    background-image: url("../images/player/replay.svg");
  }

  & .vjs-skip-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: auto;
    padding: 0;
    margin-bottom: 15px;

    @include media-breakpoint-down(xxs) {
      position: static;
      flex: 1;
      justify-content: flex-end;
      order: 2;
      width: auto;
      margin-bottom: 0;
    }

    @media only screen and (min-width: 430px) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-up(sm) {
      position: absolute;
      bottom: 0;
      left: 0;

      // Vertically center skip container.
      // It's parent (control-bar) has a fixed height so
      // using it's width & ratio to add padding
      padding-bottom: calc(((100% / 100 * 56.25) / 2) - 40px);
      margin-bottom: 0;
      pointer-events: none;

      & > .vjs-control {
        pointer-events: all;
      }

      & .vjs-icon-placeholder::before {
        max-width: 28px;
        max-height: 28px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.vjs-fullscreen .vjs-skip-container {
      position: fixed;
      top: 50%;
      height: 60px;
      padding-bottom: 0;
      transform: translateY(-50%);
    }
  }

  // Skip back/forward
  & .vjs-play-pause,
  & .vjs-skip-back,
  & .vjs-skip-forward {
    width: 60px;
    height: 60px;
    padding: 0;
    margin: 0;

    // Remove default button margin
    &.vjs-control {
      margin: 0;
    }

    & .vjs-icon-placeholder {
      height: 60px;
      margin-left: auto;
      margin-right: auto;
      width: 60px;

      &::before {
        content: url("");
      }
    }

    @include media-breakpoint-down(lg) {
      height: 90px;
      width: 70px;
    }

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 1;
      height: 10%;
      margin-right: 0 !important;
      margin-bottom: 2rem;

      & .vjs-icon-placeholder {
        background-color: transparent;
        margin-bottom: map-get($bm_spacers, 2);
      }

      & .vjs-control-text {
        position: static;
        width: auto;
        height: auto;
        padding: 0;
        overflow: hidden;
        clip: rect(0 0 0 0);

        font-size: 1rem;
        border: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      height: 5%;
      margin-bottom: 0.8rem;
      width: 60px;

      & .vjs-icon-placeholder {
        margin-bottom: 0.5rem;
      }

      & .vjs-icon-placeholder::before {
        width: 40px;
        height: 40px;
      }

      & .vjs-control-text {
        font-size: 0.8rem;
      }
    }

    @include media-breakpoint-down(xs) {
      height: 5%;
      margin-bottom: 0.2rem;

      & .vjs-icon-placeholder {
        width: 40px;
        height: 40px;
        margin-bottom: 0.2rem;
      }

      & .vjs-icon-placeholder::before {
        width: 24px;
        height: 24px;
      }

      & .vjs-control-text {
        font-size: 0.8rem;
      }
    }

    @include media-breakpoint-down(xxs) {
      height: 5%;
      margin-bottom: 0;

      & .vjs-icon-placeholder::before {
        width: 24px;
        height: 24px;
      }

      & .vjs-control-text {
        font-size: 0.6rem;
      }
    }
  }

  & .vjs-skip-back .vjs-icon-placeholder::before {
    left: 45%;
    background-image: url("../images/player/skip-back.svg");
  }

  & .vjs-skip-forward .vjs-icon-placeholder::before {
    left: 55%;
    background-image: url("../images/player/skip-forward.svg");
  }

  & .vjs-play-pause .vjs-icon-placeholder::before {
    left: 50%;
    background-image: url("../images/player/pause.svg");
  }

  & .vjs-play-pause .vjs-control-text {
    font-size: 0;
  }

  & div.vjs-playback-rate {
    width: 40px;

    > .vjs-menu-button {
      position: unset;
    }

    .vjs-playback-rate-value {
      top: 2px;
      z-index: 1;
    }

    .vjs-menu-content {
      bottom: 23px;
    }

    order: 2;

    @include media-breakpoint-down(md) {
      order: 8;
    }

    @include media-breakpoint-down(xs) {
      display: none;
    }

    & button.vjs-playback-rate {
      padding: 0;
    }
  }

  // Volume
  & .vjs-mute-control {
    width: 40px;

    & .vjs-icon-placeholder,
    &.vjs-vol-0 .vjs-icon-placeholder,
    &.vjs-vol-1 .vjs-icon-placeholder,
    &.vjs-vol-2 .vjs-icon-placeholder,
    &.vjs-vol-3 .vjs-icon-placeholder {
      &::before {
        max-width: 19px;
        max-height: 19px;
        content: "";
        background-image: url("../images/player/volume.svg");

        @include media-breakpoint-down(xs) {
          width: 17px;
        }
      }
    }
  }

  & .vjs-mute-control.vjs-vol-0 {
    & .vjs-icon-placeholder,
    &.vjs-vol-0 .vjs-icon-placeholder,
    &.vjs-vol-1 .vjs-icon-placeholder,
    &.vjs-vol-2 .vjs-icon-placeholder,
    &.vjs-vol-3 .vjs-icon-placeholder {
      &::before {
        content: "";
        background-image: url("../images/player/volume-muted.svg");
      }
    }
  }

  // Only allow volume slider to reveal for desktop devices
  & .vjs-volume-panel-horizontal > .vjs-volume-horizontal {
    @media (hover: hover) and (min-device-width: 992px),
      (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      width: 90px !important;
      margin-left: -10px;
    }

    @include ipad-pro {
      display: none;
    }
  }

  & .vjs-volume-panel {
    width: 35px;
    height: 35px;
    overflow: hidden;

    @media only screen and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      transition-duration: 0;
    }

    @include media-breakpoint-up(sm) {
      width: 40px;
      height: 40px;
    }

    @include media-breakpoint-up(md) {
      overflow: visible;
    }

    &.vjs-volume-panel-horizontal:active,
    &.vjs-volume-panel-horizontal.vjs-slider-active,
    &.vjs-volume-panel-horizontal.vjs-hover {
      width: 35px;

      @include media-breakpoint-up(sm) {
        width: 40px;
        height: 40px;
      }

      @media only screen and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
        transition-duration: 0;
      }
    }

    &.vjs-volume-panel-horizontal.vjs-hover {
      @media (hover: hover) and (min-device-width: 992px),
        (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
        width: 130px;

        & ~ .vjs-lesson-title {
          max-width: calc(100% - 500px);
          transition: max-width 0.1s ease;

          @media only screen and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            transition-duration: 0;
          }
        }
      }

      @include ipad-pro {
        width: 40px;
      }
    }

    @include media-breakpoint-down(md) {
      order: 7;
    }

    @include media-breakpoint-down(xs) {
      &.vjs-volume-panel-horizontal.vjs-slider-active,
      &.vjs-volume-panel-horizontal.vjs-hover {
        width: 35px;
      }
    }
  }

  & .vjs-volume-control {
    display: flex;
    align-items: center;
    height: 100% !important;
    transition: visibility 1s, opacity 0.4s, height 1s 1s, width 1s, left 1s 1s,
      top 1s 1s !important;
  }

  & .vjs-volume-bar {
    width: 80px;
    height: 15px;
    background-color: $bm-grey-light;

    &.vjs-slider-horizontal {
      position: relative;
      margin: 0;
    }
  }

  & .vjs-volume-level {
    position: absolute;
    height: 100%;
    background-color: $bm-primary;

    &::before {
      top: 0;
      display: none;
      width: 9px;
      height: 100%;
      content: "";
      background-color: #fff;
    }
  }

  & .vjs-volume-reveal {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    & > li {
      width: 5px;
      background-color: transparent;

      &:nth-child(odd) {
        background-color: #090806;
      }

      &:nth-child(even):hover {
        background-color: $bm-tertiary;
      }
    }
  }

  &[data-portrait-trailer="true"] .vjs-time-control {
    @include media-breakpoint-up(xs) {
      order: 6;
    }
  }

  // Duration
  & .vjs-time-control {
    display: flex;
    align-items: center;
    order: 1;
    height: 40px;
    padding: 0;

    @include media-breakpoint-up(xs) {
      order: 7;
    }

    @include media-breakpoint-up(lg) {
      order: 0;
    }

    // Remove right margin to time duration to prevent
    // controls wrapping on small mob screens and space
    // the subsequent controls to the right of the control bar
    &.vjs-duration {
      @media only screen and (max-width: 475px) {
        margin-right: auto;
      }

      @media only screen and (min-width: 992px) and (max-width: 1024px) {
        margin-right: auto;
      }
    }

    // Position time above progress bar for
    // long time duration (> 1 hour) on small screens
    &.vjs-time-control--lg {
      @media only screen and (max-width: 440px) {
        order: 0;
      }
    }
  }

  & .vjs-control.vjs-current-time {
    margin-right: map-get($spacers, 2);
  }

  & .vjs-time-divider {
    min-width: 0;
    margin-right: map-get($spacers, 2);
  }

  // Next button
  & .vjs-next-lesson {
    display: flex;
    align-items: center;
    width: auto;
    max-width: 40px;
    margin-left: auto;

    & .vjs-icon-placeholder {
      flex: 0 0 40px;

      &::before {
        content: "";
        background-image: url("../images/player/next.svg");
      }
    }

    @include media-breakpoint-down(md) {
      order: 6;
      margin-left: unset;

      &:hover {
        max-width: 40px;
      }
    }

    @include media-breakpoint-down(xs) {
      max-width: 35px !important;

      & .vjs-icon-placeholder {
        flex: 0 0 35px;

        &::before {
          width: 12px;
          content: "";
          background-image: url("../images/player/next.svg");
        }
      }
    }
  }

  // Hide Next Lesson button when there's no next lesson
  &[data-next-lesson-path=""] .vjs-next-lesson {
    visibility: hidden;
  }

  &:not([data-next-lesson-path]) .vjs-next-lesson {
    visibility: hidden;
  }

  &[data-portrait-trailer="true"] {
    div.vjs-subs-caps-button {
      @include media-breakpoint-down(md) {
        order: 6;
      }
    }
  }

  // Captions
  & div.vjs-subs-caps-button {
    display: flex !important;
    align-items: center;
    order: 2;
    width: 40px;

    & button.vjs-subs-caps-button {
      display: flex !important;
      flex: 0 0 40px;
      order: 1;
      width: 40px;
      padding: 0;

      & .vjs-icon-placeholder::before {
        content: "";
        background-image: url("../images/player/subtitle.svg");
      }

      ~ .vjs-menu .vjs-captions-menu-item .vjs-icon-placeholder {
        display: none;
      }

      &[aria-pressed="true"] {
        & .vjs-icon-placeholder {
          background-color: $bm-primary;
        }
      }
    }

    @include media-breakpoint-down(md) {
      order: 8;
      width: 35px !important;
      margin-left: auto;

      &.vjs-hover {
        width: 40px;
      }
    }

    &.vjs-hide,
    &.vjs-hide button.vjs-subs-caps-button {
      display: none !important;
    }

    .vjs-menu-button-popup ~ .vjs-menu {
      z-index: 1;
    }

    .vjs-menu-content .vjs-captions-menu-item {
      text-transform: initial;
    }
  }

  // Fullscreen
  & .vjs-fullscreen-control {
    order: 2;
    width: 40px;
    margin-right: 0 !important;

    @include media-breakpoint-down(md) {
      order: 9;
    }
    @include media-breakpoint-down(xs) {
      &.vjs-control {
        width: 35px;
      }
    }
  }

  & .vjs-fullscreen-control .vjs-icon-placeholder::before {
    content: "";
    background-image: url("../images/player/fullscreen.svg");
  }

  &.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder::before,
  &.vjs-bm-mobile-fullscreen
    .vjs-fullscreen-control
    .vjs-icon-placeholder::before {
    content: "";
    background-image: url("../images/player/close.svg");
  }

  div.vjs-quality-selector {
    width: 40px;
    order: 2;

    & .vjs-icon-placeholder {
      padding-top: 0.85rem;
    }

    > button {
      padding: 0;
    }

    .vjs-menu-content {
      bottom: 22px;
    }

    @include media-breakpoint-down(md) {
      order: 6;
    }

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
}

// Styles for lesson player
// Lessons player on lg+ breakpoint shrinks with
// browser height to fit video above the fold
.vjs-lesson-player.vjs-bm {
  // Title
  & .vjs-lesson-title {
    flex-wrap: nowrap;
    order: 7;
    max-width: calc(100% - 395px);
    // Absolute font as parent has font set
    font-size: 12px;
    white-space: nowrap;
    transition: max-width 1s ease;

    @media only screen and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      transition-duration: 0;
    }

    &__lesson-title {
      max-width: 100%;
      @include text-ellipsis;
    }

    & .bm-bullet-light {
      flex-shrink: 0;
      margin: 0 0.8rem;
    }

    // Reveal lesson details as viewport increases
    &__maestro,
    &__maestro + .bm-bullet-light,
    &__lesson-number,
    &__lesson-number + .bm-bullet-light,
    &__lesson-title {
      display: none;
    }

    // Ensure the ellipsis is correctly applied
    // and parts of the title properly aligned
    &__announcement {
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    @media only screen and (min-width: 475px) {
      display: flex;
      // Send subsequent controls to the right of the control bar
      margin-right: auto;
    }

    @media only screen and (min-width: 670px) {
      // &__lesson-number,
      // &__lesson-number + .bm-bullet-light {
      //   display: inline-block;
      // }
      &__lesson-title {
        display: inline-block;
      }
    }

    @media only screen and (min-width: 875px) {
      &__lesson-number,
      &__lesson-number + .bm-bullet-light {
        display: inline-block;
      }
      &__maestro,
      &__maestro + .bm-bullet-light {
        display: block;
      }
    }

    // Player shrinks again on desktop sizes
    @include media-breakpoint-up(lg) {
      display: none;
      order: 0;

      &__maestro,
      &__maestro + .bm-bullet-light {
        display: none;
      }
    }

    @media only screen and (min-width: 1025px) {
      display: flex;
    }

    @media only screen and (min-width: 1120px) {
      &__lesson-title {
        display: inline-block;
      }
    }

    @media only screen and (min-width: 1330px) {
      &__maestro,
      &__maestro + .bm-bullet-light {
        display: block;
      }
    }

    // Show lesson number for tablet
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      display: flex;
    }

    // Show lesson number and title for ipad pro
    @include ipad-pro-portrait {
      display: flex;

      &__lesson-title,
      .bm-bullet-light {
        display: inline-block;
      }
    }

    &.hidden {
      display: none;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .vjs-bm {
    &.bm-hero__bg-video {
      width: 100%;
      height: 100%;
    }

    & .vjs-tech {
      height: auto;
    }
  }
}

// Remove all controls apart from play/pause, mute, captions & fullscreen
.vjs-autoplay-showreel,
.vjs-big-play-button--trailer {
  .vjs-big-play-button:not(.vjs-big-play-button--trailer .vjs-big-play-button),
  .vjs-skip-container {
    display: none;
  }

  .vjs-time-control {
    visibility: hidden;
  }

  .vjs-progress-control,
  .vjs-subs-caps-button,
  .vjs-fullscreen-control,
  .vjs-play-control {
    opacity: 1;
    transition: opacity 0.4s ease-out;
  }

  .vjs-volume-panel {
    margin-left: 0;
    transition: transform 0.4s ease-out, margin-left 0.4s ease-out, width 0.1s !important;
  }
}

// Used for transition when unmuting a video
.vjs-autoplay-showreel--hide-elements {
  .vjs-playback-rate,
  .vjs-quality-selector,
  .vjs-progress-control,
  .vjs-subs-caps-button,
  .vjs-fullscreen-control,
  .vjs-play-control {
    visibility: hidden;
    opacity: 0;
  }

  .vjs-volume-horizontal {
    display: none;
  }
}

.vjs-autoplay-showreel--volume-left-side {
  .vjs-volume-panel {
    margin-left: -0.8rem - (35rem / 16);

    > button {
      transform: scale(1.4);
    }

    @include media-breakpoint-up(sm) {
      margin-left: -1.3rem - (40rem / 16);
    }
  }
}

// Homepage hero mute toggle
.bm-homepage-hero {
  .vjs-homepage-mute,
  .bm-hero-new__content {
    transition: opacity 1s;
  }
}

.vjs-homepage-mute {
  width: (60rem / 16);
  height: (60rem /16);
  background-color: rgba($bm-bg-grey-darker, 0.6);
  background-image: url("../images/player/volume.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 55%;
  border: none;
  appearance: none;

  &.vjs-homepage-mute--muted {
    background-image: url("../images/player/volume-muted.svg");
  }
}

.vjs-mute-toggle-fade {
  .vjs-homepage-mute,
  .bm-hero-new__content {
    opacity: 0;
    transition: opacity 1s;
  }

  &:hover {
    .vjs-homepage-mute,
    .bm-hero-new__content {
      opacity: 1;
    }
  }
}

.vjs-mobile-fade {
  .vjs-homepage-mute,
  .bm-hero-new__content {
    opacity: 0;
    transition: opacity 1s;
  }
}

.vjs-homepage-mute--muted-permanent {
  .vjs-homepage-mute,
  .bm-hero-new__content {
    opacity: 1;
  }
}
// stylelint-enable selector-class-pattern, declaration-no-important

// Note: This temporarily hides the "unknown" captions setting that appears on iOS Safari only.
// This will not be needed once https://bbcmaestro.atlassian.net/browse/CO-107 is implemented as this change
// would negate the need for hiding this button altogether.
body:not([data-course-slug="magical-storytelling"])
  .vjs-subs-caps-button
  .vjs-menu-content
  li:nth-child(3) {
  display: none;
}

body[data-course-slug="magical-storytelling"]
  .vjs-subs-caps-button
  .vjs-menu-content
  li:nth-child(4) {
  display: none;
}
