// stylelint-disable selector-class-pattern
.bm-tabs {
  display: flex;
  justify-content: center;

  .nav-link {
    color: $bm-secondary;
    border: none;
    border-bottom: (2rem / 16) solid transparent;
    border-radius: 0;

    &.active {
      color: $bm-secondary;
      background: transparent;
      border-bottom-color: $bm-primary;
    }

    &:not(.active) {
      &:hover {
        color: $bm-secondary-light;
        border-bottom-color: $bm-grey-medium-dark;
      }
      &:active {
        border-bottom-color: rgba($bm-primary, 0.5);
      }
    }
  }
}

// Styling tabs as buttons require a bit of overriding as
// the .active class is used differently for both
.bm-tabs-as-buttons {
  &__button {
    background-color: $bm-primary;

    &:hover,
    &:focus {
      background-color: $bm-primary-darker;
    }

    &:not(:disabled):not(.disabled).active {
      background-color: $bm-secondary;

      &:hover,
      &:focus {
        background-color: $bm-secondary-light;
        box-shadow: none;
      }
    }
  }
}

// Toggle which icon is displayed for each state
.bm-presales-video-toggle {
  &__icon-play {
    display: block;
  }
  &__icon-pause {
    display: none;
  }

  &.active .bm-presales-video-toggle {
    &__icon-play {
      display: none;
    }
    &__icon-pause {
      display: block;
    }
  }
}

.bm-preorder-tabs {
  position: sticky;
  top: $header-height-small;
  z-index: 2;
  background-color: $bm-grey-lighter;

  @include media-breakpoint-up(md) {
    top: $header-height-large;
  }

  &__list {
    justify-content: center;
    width: 100%;
    max-width: (500rem / 16);
    margin: 0 auto;
  }

  &__item {
    flex-grow: 1;
  }

  &__link {
    padding: (13rem / 16) 0;
    text-align: center;

    @include media-breakpoint-up(xs) {
      min-width: (70rem / 16);
    }
  }
}
// stylelint-enable selector-class-pattern
