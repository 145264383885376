.bm-discount-panel {
  background-color: $bm-grey-darkest;
  background-image: radial-gradient(
    20% 100% at 100% 50%,
    rgba($bm-primary-500, 0.8),
    transparent
  );
  padding: map-get($bm_spacers, 7) map-get($bm_spacers, 4);

  @include media-breakpoint-up(md) {
    background-image: radial-gradient(
      20% 70% at 100% 30%,
      rgba($bm-primary-500, 0.8),
      transparent
    );
    padding: map-get($bm_spacers, 8) map-get($bm_spacers, 10);
  }
}

.bm-discount-panel__foreground {
  z-index: 2;

  @include media-breakpoint-up(lg) {
    margin-right: map-get($bm_spacers, 9);
  }
}

.bm-discount-panel__image {
  max-height: 400px;
  /* stylelint-disable */
  object-fit: scale-down !important;
  /* stylelint-enable */
}

.bm-discount-panel__title {
  font-size: 1.375rem;
  margin-top: map-get($bm_spacers, 7);
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.bm-discount-panel__text {
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.bm-discount-panel .vc-split-card__inner {
  align-items: center;

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
  }
}
