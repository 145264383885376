.vc-badge {
  --badge-spacing: #{map-get($bm_spacers, 2)};

  line-height: 20px;
  letter-spacing: 1.2px;
  padding: 0.075rem 0.375rem;
  position: absolute;
  top: var(--badge-spacing);
  z-index: 1;

  @include media-breakpoint-up(sm) {
    --badge-spacing: #{map-get($bm_spacers, 6)};

    padding: 0.5rem 0.75rem;
  }
}

.vc-badge--right {
  right: var(--badge-spacing);
}

.vc-badge--left {
  left: var(--badge-spacing);
}

.vc-badge--centered {
  bottom: auto;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: max-content;
  z-index: 2;
}

.vc-badge--offset-left {
  left: map-get($bm_spacers, 6);
  right: auto;
  top: -(map-get($bm_spacers, 3));

  @include media-breakpoint-up(lg) {
    left: map-get($bm_spacers, 7);
  }
}

.vc-badge--small {
  --badge-spacing: #{map-get($bm_spacers, 1)};

  padding: 0.075rem 0.325rem;

  @include media-breakpoint-up(sm) {
    --badge-spacing: #{map-get($bm_spacers, 2)};
  }
}

.vc-badge--artifact {
  --badge-spacing: #{map-get($bm_spacers, 2)};

  padding: 0;
}

.vc-badge--inline {
  display: inline-block;
  position: relative;
  top: auto;
}

.vc-badge--artifact img {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.vc-badge-rotated-right-holder {
  position: absolute;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  top: 0;
}

/* This forces .vc-badge-rotated-right to be tall enough */

.vc-badge-rotated-right-holder::after {
  content: "";
  display: block;
  height: 0;
  width: 100%;

  /* Despite it affecting the height, when the padding-top's value is percentages, it will be calculated from the width */

  padding-top: 70.7107%;
}

/*
  1. `translate(-30px, -30px)`: Take the extended area to be clipped out of the equation

  2. `rotate(-90deg) translateX(-70.7107%)`: We want to
  make a right triangle with angles of 90deg, 45deg and 45deg.
  The ribbon will be the hypotenuse of this triangle.
  We need to move the left edge of the ribbon down into place on
  this triangle. Using a little right triangle math, the ratio
  of the to other triangle sides to the hypotenuse is:
    1 / sqrt(2) = 0.707107...

  3. Rotate the ribbon to the 45deg angle.
*/

.vc-badge-rotated-right {
  background-color: $bm-primary-500;

  /* The 30px is the part that extends out to be clipped on the diagonal */

  padding: 0.125rem calc(30px + 15px);
  position: relative;
  top: 0;
  transform-origin: 0 0;
  transform: translate(-30px, -30px) rotate(-90deg) translateX(-70.7107%)
    rotate(45deg);
  z-index: 1;

  @include media-breakpoint-up(sm) {
    padding: 0.25rem calc(30px + 15px);
  }
}
