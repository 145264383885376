// stylelint-disable selector-no-qualifying-type

.bm-form-edit {
  --border-color: #{$bm-neutral-200};

  display: grid;
  gap: map-get($bm_spacers, 4);
  grid-template-rows: max-content max-content;

  @include media-breakpoint-up(md) {
    gap: map-get($bm_spacers, 6);
    grid-template-columns: repeat(2, 1fr);
  }
}

.bm-form-edit input[type="text"],
.bm-form-edit input[type="password"],
.bm-form-edit input[type="email"],
.bm-form-edit input[type="checkbox"] {
  border: 1px solid var(--border-color);
}

.bm-form-edit
  input[type="checkbox"]:not(:checked):not([disabled])
  + label::before {
  border: 1px solid var(--border-color);
}

.bm-form-edit input[type="email"]:disabled {
  cursor: not-allowed;
}

.bm-form-edit__email-password {
  @include media-breakpoint-up(md) {
    grid-column: 1 / 2;
  }
}

.bm-form-edit__preferences {
  @include media-breakpoint-up(md) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
}
