// Refactored hero styling. Once no views use any styles from
// _hero.scss, it can be deleted and we can remove the 'new'
// from this file and it's classes

$bm-hero-new__landscape-breakpoint: (568rem / 16);
$bm-hero-new__portrait-breakpoint: (568rem / 16);
$bm-hero-new__landscape-media-query: '(min-width: #{$bm-hero-new__landscape-breakpoint}) and (orientation: landscape)';
$bm-hero-new__portrait-media-query: "(min-width: #{$bm-hero-new__portrait-breakpoint}) and (orientation: portrait)";

.bm-hero-new--layout-with-gradient {
  display: block;
  overflow: hidden;
  background-color: $bm-neutral-900;

  .bm-hero-new__gradient-container {
    position: relative;
    width: 100%;
    min-height: 40%;
    margin-top: (-190rem / 16);

    // ::before acts as a thin gradient,
    // ::after is the solid background block
    &::before {
      position: absolute;
      top: (2rem / 16);
      display: block;
      width: 100%;
      height: 25%;
      content: '';
      background-image: linear-gradient(
          to top,
          $bm-neutral-900 0%,
          rgba(34, 34, 34, 0) 100%
        ),
        linear-gradient(0deg, $bm-neutral-900 14.33%, rgba(34, 34, 34, 0) 100%);
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 75%;
      content: '';
      background-color: $bm-neutral-900;
    }

    &--presales {
      margin-top: (-140rem) / 16;
    }
  }

  .bm-hero-new__content {
    position: relative;
    z-index: 2;
    max-width: 40rem;
    padding: map-get($spacers, 3);
    margin: 0 auto;
  }

  .bm-campaign-badge--as-banner-sm {
    .bm-campaign-badge {
      position: static;
    }
  }

  .bm-hero-new__course-title {
    margin-top: map-get($spacers, 1);
  }

  .bm-hero-new__icon-and-text-container {
    display: flex;
    flex-direction: column;
  }

  .bm-hero-new__icon-and-text {
    display: flex;
    align-items: center;
    margin-bottom: map-get($spacers, 3);
  }

  @include media-breakpoint-up(lg) {
    .bm-hero-new__background {
      height: 100%;
      inset: 0;
      position: absolute;
      width: 100%;
    }
  }

  // LANDSCAPE STYLE
  @media only screen and #{$bm-hero-new__landscape-media-query} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: calc(100vh - #{$header-height-small});
    height: auto;

    .bm-hero-new__background {
      position: absolute;
      left: (70rem / 16);
      height: 100%;
    }

    .bm-hero-new__gradient-container {
      align-self: stretch;
      width: 55%;
      max-width: (700rem / 16);
      margin-top: 0;
      background: transparent;

      &--presales {
        max-width: none;
      }

      &::before {
        top: 0;
        left: 0;
        width: 130%;
        height: 100%;
        background: linear-gradient(
          90deg,
          $bm-neutral-900 58.43%,
          rgba(26, 26, 26, 0) 100%
        );
      }
      &::after {
        display: none;
      }
    }

    .bm-hero-new__content {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: auto;
      min-height: auto;
      padding: map-get($spacers, 4);

      &--prelaunch {
        padding: map-get($bm_spacers, 12) map-get($bm_spacers, 6);
      }

      &--presales {
        height: 100%;
        justify-content: center;
      }
    }
  }

  @media only screen and #{$bm-hero-new__portrait-media-query} {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: auto;
      min-height: 50vh;

      .bm-hero-new__background {
        position: absolute;
        left: (130rem / 16);
        height: 100%;
      }

      .bm-hero-new__gradient-container {
        align-self: stretch;
        width: 55%;
        max-width: (700rem / 16);
        margin-top: 0;
        background: transparent;

        &::before {
          top: 0;
          left: 0;
          width: 130%;
          height: 100%;
          background: $bm-gradient-new-hero-landscape-sm;
        }

        &::after {
          display: none;
        }
      }

      .bm-hero-new__content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: auto;
        min-height: auto;
        padding: map-get($spacers, 4);

        &--prelaunch {
          padding: map-get($bm_spacers, 13) map-get($bm_spacers, 11);
        }
      }
    }
  }

  // Tweak spacing lg up
  @include media-breakpoint-up(lg) {
    .bm-hero-new__gradient-container {
      width: 50%;
    }

    .bm-hero-new__gradient-container::before {
      background-image: linear-gradient(
        90deg,
        $bm-neutral-900 37.5%,
        rgba(26, 26, 26, 0) 100%
      );
    }

    .bm-hero-new__content {
      padding: map-get($spacers, 5);

      &--prelaunch {
        padding: map-get($bm_spacers, 13) map-get($bm_spacers, 6);
      }

      &--presales {
        height: 100%;
        padding: map-get($bm_spacers, 13) map-get($bm_spacers, 11);
      }
    }

    .bm-hero-new__course-title {
      margin-top: map-get($spacers, 3);
      font-size: $font-size-sm;
    }
  }

  @include media-breakpoint-up(xl) {
    .bm-hero-new__content {
      height: 100%;
      margin-top: 0;

      &--presales {
        padding: map-get($bm_spacers, 13) map-get($bm_spacers, 11);
      }
    }

    .bm-hero-new__icon-and-text-container {
      flex-direction: row;
      margin-bottom: map-get($spacers, 3);
    }

    .bm-hero-new__icon-and-text {
      margin-bottom: 0;

      &:last-child {
        margin-left: map-get($spacers, 4);
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .bm-hero-new__content {
      padding: map-get($spacers, 6);

      &--prelaunch {
        padding: map-get($bm_spacers, 13) map-get($bm_spacers, 11);
      }

      &--presales {
        padding: calc(map-get($bm_spacers, 13) + map-get($bm_spacers, 8))
          map-get($bm_spacers, 13);
      }
    }
  }
}

.bm-hero-new__pre-launch {
  @media only screen and (max-width: (767rem / 16)) and (orientation: portrait) {
    min-height: calc(100vh - #{$header-height-small});

    .bm-hero-new__background {
      flex: none;
      height: 40vh;
    }

    .bm-hero-new__gradient-container {
      min-height: 60%;
      margin-top: 0;

      &::before {
        top: (-75rem / 16);
        height: (80rem / 16);
      }
      &::after {
        height: 100%;
      }
    }
  }

  .bm-hero-new__content {
    @include media-breakpoint-down(sm) {
      max-width: (500rem / 16);
    }
  }

  .bm-hero-new__subtitle-desktop {
    margin-top: map-get($bm_spacers, 6);
    max-width: (500rem / 16);

    > p {
      color: $bm-neutral-50;
      font-size: 0.875rem;
    }

    @media only screen and (max-width: 767px) and (orientation: landscape) {
      display: none;
      max-width: (500rem / 16);
    }

    @include media-breakpoint-up(lg) {
      margin-top: map-get($bm_spacers, 8);
    }
  }
}

.bm-campaign-badge--presales-landscape {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100%;

  .bm-campaign-badge {
    top: (30rem / 16);
    right: (30rem / 16);

    // Put the badge in bottom corner
    @include media-breakpoint-down(lg) {
      @media #{$bm-hero-new__landscape-media-query} {
        top: auto;
        right: 1rem;
        bottom: 1rem;
      }

      // Slight adjustment when the padding of the left hand side changes
      @media (min-width: #{breakpoint-min(lg)}) and (orientation: landscape) {
        right: 2.5rem;
        bottom: 2.5rem;
      }
    }
  }
}
