@import "variables/tokens";
@import "variables/colors";
@import "variables/shadows_and_gradients";
@import "variables/spacing";
@import "variables/bootstrap";
@import "variables/animations";

$header-height-small: (60rem / 16);
$header-height-large: (70rem / 16);

$focus-outline: (2rem / 16) solid $bm-bg-grey-darker;
