// Some things have to be overriden forcefully here
// stylelint-disable
.vjs-bm {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: box-shadow 0.4s;

  &:not(.bm-hero__bg-video) {
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
    box-shadow: $bm-shadow-lg;
  }

  &.bm-bg-video {
    pointer-events: none;
  }

  &.vjs-cover video {
    object-fit: cover;
  }

  // Poster styles
  &.vjs-fullscreen {
    background-size: 0%;
  }

  &.vjs-big-play--neutral-800:hover .vjs-big-play-button {
    background-color: $bm-neutral-800;
  }

  &.vjs-big-play--neutral-800 .vjs-big-play-button {
    background-color: rgba($bm-neutral-800, 0.6);
    height: 3rem;
    width: 3rem;

    &:hover {
      background-color: $bm-neutral-800;
    }
  }

  video.vjs-tech {
    background-repeat: no-repeat;
    background-size: cover !important;

    // Pointer-events: none prevents pausing/playing the video on touch
    // if the controls aren't already showing
    @media only screen and (max-device-width: 991px) {
      pointer-events: none;
    }
    @include ipad-pro {
      pointer-events: none;
    }

    &[poster] {
      background-size: cover;
    }
  }

  & .vjs-poster {
    pointer-events: none;
  }

  & .vjs-poster,
  & .vjs-poster:focus {
    background-position: center top;
    background-size: cover !important;
  }

  & .vjs-poster,
  &.bm-hero__bg-video {
    background-color: transparent !important;
    object-fit: cover !important;
  }

  // Remove blue focus ring around entire video
  & video:focus {
    outline: none;
  }

  & .vjs-v7 .vjs-tech {
    height: auto;
  }

  & .vjs-icon {
    display: block;
    width: 15px;
    height: 15px;
    margin: 7px 15px 0 5px;
    cursor: pointer;
  }

  // Loading spinner
  & .vjs-loading-spinner {
    top: 30%;
    z-index: 1;
    display: block;
    // Ensure spinner is always square
    width: 12vw;
    min-width: 50px;
    max-width: 100px;
    height: 12vw;
    min-height: 50px;
    max-height: 100px;
    margin: 0;
    border: none;
    opacity: 1;
    transform: translate(-50%, -50%);

    &::before {
      width: 100%;
      height: 100%;
      margin: 0;
      background-image: url("https://assets.bbcmaestro.com/media/player/loader.png");
      background-size: cover;
      transform-origin: 50% 50%;
      animation: spin 1.5s infinite linear;
    }

    &::after {
      display: none;
    }

    @include media-breakpoint-up(xs) {
      top: auto;
      bottom: 35%;
    }

    @media only screen and (min-width: 450px) {
      top: 50%;
      bottom: auto;
    }
  }

  // Use a larger spinner for fullscreen
  &.vjs-bm-mobile-fullscreen .vjs-loading-spinner {
    min-width: 100px;
    min-height: 100px;
  }

  // BIG PLAY BUTTON
  & .vjs-big-play-button {
    width: 3.75rem;
    height: 3.75rem;
    padding: 0;
    margin: 0;
    background:
      url("../images/icons/play-button.svg"),
      $bm-primary;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
    border: none;
    color: $bm-neutral-50;
    transform: translate(-50%, -50%);

    @include media-breakpoint-up(md) {
      width: 5rem;
      height: 5rem;
    }

    & .vjs-icon-placeholder {
      display: none;
    }
  }

  &.vjs-big-play-button--hidden .vjs-big-play-button {
    display: none;
  }

  &.vjs-big-play-button--trailer .vjs-big-play-button {
    background-position: center 40%;
    background-size: 40%;
    height: map-get($bm-spacers, 13);
    position: relative;
    width: map-get($bm-spacers, 13);
  }

  &.vjs-big-play-button--trailer .vjs-play-trailer-text {
    font-family: $font-family-roboto-bold;
    font-size: .75rem;
    line-height: 1;
    position: absolute;
    bottom: map-get($bm_spacers, 3);
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  &.vjs-big-play-button--trailer .vjs-big-play-button:focus {
    background: url("../images/icons/play-button.svg"),
      $bm-primary-dark !important;
    background-repeat: no-repeat !important;
    background-position: center 40% !important;
    background-size: 40% !important;
    outline-color: $bm-white;
  }

  & .vjs-big-play-button:hover,
  &:hover .vjs-big-play-button {
    background-color: $bm-primary-dark;
  }

  & .vjs-big-play-button:focus {
    background: url("../images/icons/play-button.svg"),
      $bm-primary-dark !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 60% !important;
    outline-color: $bm-white;
  }

  & .vjs-big-play-button:active,
  &:active .vjs-big-play-button {
    background-color: $bm-secondary !important;
  }

  // Captions track
  &:not(.vjs-has-started) .vjs-text-track-display {
    display: none;
  }

  & .vjs-text-track-display {
    height: 90%;
    transition: height 0s;
    transition-delay: 0.5s;

    // General font sizes for text tracks
    & * {
      font-size: 1rem;

      @include media-breakpoint-up(sm) {
        font-size: 1.25rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 1.375rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.vjs-fullscreen .vjs-text-track-display * {
      font-size: 1.75rem;
    }
  }

  &.vjs-seeking:not(.vjs-paused) .vjs-loading-spinner {
    animation: none;
  }

  // Shift track to top on mobile when controls are showing...
  &.vjs-user-active .vjs-text-track-display,
  &.vjs-paused .vjs-text-track-display {
    height: 15%;
    transition-delay: 0s;

    // ...or just above the control on desktop
    @include media-breakpoint-up(lg) {
      height: calc(100% - 105px);
    }
  }

  & .vjs-text-track-cue {
    top: unset !important;
  }

  // Thumbnails

  /* a wrapper element that tracks the mouse vertically */
  .vjs-thumbnail-holder {
    position: absolute;
    //left: -1000px;
  }

  /* the thumbnail image itself */
  .vjs-thumbnail {
    position: absolute;
    bottom: 1.3em;
    bottom: 1.1em;
    left: 0;
    display: none;
    border: 3px solid $bm-primary;
    opacity: 0;
    -webkit-transition: opacity 0.2s ease;
    -moz-transition: opacity 0.2s ease;
    -mz-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
  }

  /* fade in the thumbnail when hovering over the progress bar */

  /* .fake-active is needed for Android only. It's removed on touchend/touchecancel */
  .vjs-progress-control:hover .vjs-thumbnail,
  .vjs-progress-control.fake-active .vjs-thumbnail,
  .vjs-progress-control:active .vjs-thumbnail {
    opacity: 1;
  }

  /* ... but hide the thumbnail when hovering directly over it */
  .vjs-progress-control:hover .vjs-thumbnail:hover,
  .vjs-progress-control:active .vjs-thumbnail:active {
    opacity: 0;
  }

  .vjs-chapter-marker {
    position: absolute;
    top: 0;
    width: 3px;
    height: 100%;
    background-color: rgb(228, 221, 159);
  }

  &.vjs-fullscreen video {
    top: 50%;
    width: 100% !important;
    height: auto !important;
    max-height: 100vh !important;
    transform: translateY(-50%);
  }

  &:not([data-lesson-number]) {
    .vjs-control-bar .vjs-duration {
      margin-right: auto;
    }
  }
}

/* Used to grow player to fullscreen when native is not supported with custom controls. */
.vjs-bm-mobile-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 110;
  height: 100% !important;
  padding-bottom: calc(100% - 48.5%) !important;

  video {
    object-position: center;
  }
}

// For non-desktops, add a solid black underlay to prevent flashes from the page
// underneath when switching orientation
@include media-breakpoint-down(md) {
  .bm-mobile-video-underlay--show {
    position: fixed;
    top: -1000px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 109;
    display: block;
    width: 100vw;
    height: 2500px;
    content: "";
    background-color: #000;
  }
}

.vjs-lesson-player {
  top: 0;
  left: 0;
}

.vjs-lesson-player.vjs-bm:not(.vjs-fullscreen) {
  // Reduce font size for lesson player subtitles as the player is smaller
  // in the viewport than regular players
  .vjs-text-track-display * {
    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
    @media only screen and (min-width: 1080px) {
      font-size: 1.125rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1.375rem;
    }
  }

  // As the lessons player is smaller on desktop due to left sidebar,
  // move text tracks to top of player when controls are in view
  @media only screen and (min-width: 992px) and (max-height: 720px) {
    &.vjs-user-active .vjs-text-track-display,
    &.vjs-paused .vjs-text-track-display {
      height: 15%;
    }
  }
}

// Autoplay next lesson feature
.vjs-bm {
  .vjs-autoplay {
    &__container {
      @include flex-center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      font-family: $font-family-roboto-regular;
      font-size: 14px;
      cursor: auto;
      background-color: rgba(0, 0, 0, 0.85);
      opacity: 1;
      transition: opacity 0.8s ease-in-out;
    }

    &__content {
      position: relative;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0.5rem;
      text-align: center;
      opacity: 1;
      transition: opacity 0.4s ease-in-out, top 0.4s ease-in-out;

      @include media-breakpoint-up(md) {
        padding: 1rem 2rem;
      }
    }

    &__lesson-completed {
      margin-bottom: 1em;
      font-size: $font-size-xs;

      @include media-breakpoint-up(sm) {
        margin-bottom: 3em;
      }

      @include media-breakpoint-up(md) {
        font-size: $font-size-sm;
      }
    }

    &__up-next {
      font-size: 0.75em;
    }

    &__lesson-title {
      font-family: $font-family-playfair;
      font-size: 1.3em;

      @include media-breakpoint-up(md) {
        font-size: 1.625em;
      }
    }

    &__timer {
      margin-top: 20px;

      @include media-breakpoint-up(xs) {
        margin-top: 30px;
      }

      &-time {
        font-size: 1.25rem;

        @include media-breakpoint-up(md) {
          font-size: 1.75em;
        }
      }

      &-bar-container {
        position: relative;
        width: 150px;
        height: 4px;
        margin: 0.5rem auto;
        overflow: hidden;

        @include media-breakpoint-up(md) {
          margin: 1rem auto
        }
      }

      &-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        content: '';
        background-color: $bm-primary;
        transition: width 1s linear;
      }
    }

    &__like-box {
      bottom: 10px;
      left: 50%;
      pointer-events: auto;
      position: absolute;
      transform: translateX(-50%);
      z-index: 1;

      @include media-breakpoint-up(sm) {
        position: static;
        transform: none;
      }
    }

    &__btn-box {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      padding: 0 0.5rem;
      position: absolute;
      left: 0;
      bottom: 10px;
      width: 100%;

      @include media-breakpoint-up(sm) {
        position: static;
      }

      @media only screen and (min-width: 575px) and (max-width: 991px) {
        margin-top: 2em;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      padding: 0;
      font-size: 1em;
      transition: background-color 0.4s;

      &:hover .vjs-autoplay__icon {
        background-color: rgba($bm-secondary, 0.8);
      }

      &--restart,
      &--cancel {
        display: inline-block;
        width: 25px;
        height: 15px;
        pointer-events: none;
        background-repeat: no-repeat;
        border: none;
        appearance: none;
      }

      &--cancel {
        background-image: url("../images/player/close.svg");
      }
    }

    &__icon {
      background-color: $bm-neutral-900;
      height: 40px;
      width: 40px;
    }

    &__container,
    &__content {
      &.vjs-autoplay--hidden {
        pointer-events: none;
        opacity: 0;

        .vjs-autoplay__like-box {
          pointer-events: none;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      &__timer-bar-container {
        height: 2px;
      }
    }

    @include media-breakpoint-down(md) {
      &__up-next,
      &__btn-cancel {
        display: none;
      }
    }

    @include ipad-pro {
      &__up-next,
      &__btn-cancel {
        display: none;
      }
    }

    &__container {
      @include media-breakpoint-down(xs) {
        font-size: $font-size-xs;
      }

      @media only screen and (min-width: 1140px) {
        font-size: 16px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 18px;
      }
    }
  }

  &[data-playing-next-lesson] .vjs-big-play-button {
    display: none;
  }
}

.vjs-bm .vjs-cta-overlay {
  &__close {
    position: absolute;
    top: 1.3rem;
    right: 1.3rem;
    display: none;
    height: auto;
  }

  &__btns {
    flex-direction: column;
    align-items: center;

    &--btn {
      width: 100%;
      max-width: (250rem / 16);

      &:last-child {
        margin-top: map-get($spacers, 4);
      }
    }

    @include media-breakpoint-up(sm) {
      flex-direction: row;

      &--btn:last-child {
        margin: 0 0 0 map-get($spacers, 4);
      }
    }
  }

  // videojs messes with btn focus styles
  .btn-primary:focus {
    background-color: $bm-primary;
  }
}

// Create more spacing for preorder videos as there's
// only one cta so more room
@include media-breakpoint-down(sm) {
  .bm-video--preorder {
    .vjs-cta-overlay__btns--btn:last-child {
      margin-top: 0;
    }

    .vjs-autoplay__btn {
      margin-top: map-get($spacers, 4);
    }
  }
}

.vjs-bm.vjs-fullscreen .vjs-cta-overlay__close {
  display: block;
}

// stylelint-enable selector-class-pattern, declaration-no-important
.bm-poster-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
