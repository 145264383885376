.vc-payment-card {
  background-image: radial-gradient(
    169.93% 120.58% at 105.56% -26.33%,
    $bm-primary-500 0%,
    #180500 100%
  );
  border-radius: 16px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  margin-top: map-get($bm_spacers, 6);
  min-height: 14.125rem;
  padding: map-get($bm_spacers, 6) map-get($bm_spacers, 4)
    map-get($bm_spacers, 4) map-get($bm_spacers, 4);
}

.vc-payment-card__brand {
  filter: brightness(0) invert(1);
  height: 30px;
}

.vc-payment-card__infos {
  display: grid;
  gap: map-get($bm_spacers, 4);
}

.vc-payment-card__dots {
  font-size: 1.5rem;
  line-height: 2rem;
}

.vc-payment-card__last-four {
  font-size: initial;
  margin-left: map-get($bm_spacers, 2);
}

.vc-payment-card__name {
  font-size: 1.125rem;
}

.vc-payment-card__actions {
  margin-top: map-get($bm_spacers, 7);
}
