.bm-discount-hero {
  padding-top: map-get($bm_spacers, 12);

  @include media-breakpoint-up(md) {
    padding-top: map-get($bm_spacers, 13);
  }
}

.bm-discount-hero__foreground {
  padding-right: map-get($bm_spacers, 8);
  z-index: 2;
}

.bm-discount-hero__title {
  margin-top: map-get($bm_spacers, 7);
}

.bm-discount-hero__text {
  margin-top: map-get($bm_spacers, 2);

  @include media-breakpoint-up(lg) {
    margin-top: map-get($bm_spacers, 4);
  }
}

.bm-discount-hero__image {
  max-height: 500px;
  z-index: 2;

  @include media-breakpoint-up(xl) {
    margin-left: map-get($bm_spacers, 7);
  }
}

.bm-discount-hero .vc-split-card__inner {
  align-items: center;

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
  }
}

.bm-discount-hero__cta {
  margin: map-get($bm_spacers, 4) 0 map-get($bm_spacers, 7);

  @include media-breakpoint-up(lg) {
    margin-top: map-get($bm_spacers, 8);
  }
}

.bm-discount-hero__gradient {
  background: radial-gradient(
    50% 250px at 110% 200px,
    rgba($bm-primary-500, 0.8),
    transparent
  );
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
