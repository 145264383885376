@keyframes spin {
  0% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scroll-to-bounce {
  0% {
    transform: translateY(0, 0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
