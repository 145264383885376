// Need to use bootstrap classes here
// stylelint-disable
$btn-accordion-size: (20rem / 16);

.btn-accordion-icon {
  position: relative;
  width: $btn-accordion-size;
  height: $btn-accordion-size;
  padding: 0;

  // Create plus & minus to animate
  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: (2rem / 16);
    height: 100%;
    content: '';
    background-color: $bm-primary-400;
    transform: translateX(-50%);
  }

  &::before {
    top: 50%;
    max-height: $btn-accordion-size;
    transition: max-height 0.4s;
    transform: translate(-50%, -50%);
  }

  &::after {
    transform: translateX(-50%) rotate(90deg);
  }
}

.bm-accordion__question:not(.collapsed),
.vc-accordion__button:not(.collapsed) {
  // Hide vertical line when toggled
  & .btn-accordion-icon::before {
    max-height: 0;
  }
}
