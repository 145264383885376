.bm-accordion {
  &__container {
    max-width: (747rem / 16);
    margin: 0 auto;
  }

  &__item {
    position: relative;
    max-width: (660rem / 16);
    margin-right: auto;
    margin-left: auto;
    background-color: $bm-white;
    transition: box-shadow 0.4s;

    &:not(.bm-postsales-lesson-item):hover {
      box-shadow: $bm-shadow;
    }
  }

  // The entire question acts as the dropdown btn
  &__question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: map-get($spacers, 4);

    & > p:not(.bm-accordion__question-capitalize) {
      margin: 0 1rem 0 0;
      font-size: $font-size-sm;
    }

    // Remove box shadow for the button element
    &:not(:disabled):hover,
    &:not(:disabled):active,
    &:not(:disabled):focus {
      box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &__question-capitalize {
    letter-spacing: 0;
  }

  &__question-text {
    width: calc(100% - 50px);
  }

  &__answer {
    padding: 0 map-get($spacers, 4) map-get($spacers, 3);
    margin-bottom: 0;
  }

  &--shadow {
    box-shadow: $bm-shadow-md;
  }
}

.bm-accordion-lessons-list {
  .bm-accordion__answer {
    padding-left: (54rem / 16);
  }

  &--fade[aria-expanded="false"] {
    mask-image: linear-gradient(rgba(0, 0, 0, 1) 80%, transparent);

    button {
      font-size: 1.1em;
    }
  }
}
