// stylelint-disable selector-class-pattern

.vc-search {
  background-color: var(--search-container-bg-color);
  display: block;
  height: 100vh;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.5s;
  visibility: hidden;
  width: 100%;
  z-index: 1000;

  @include media-breakpoint-up(lg) {
    background-color: transparent;
    height: auto;
    pointer-events: auto;
    position: static;
    opacity: 1;
    transform: none;
    visibility: visible;
    width: auto;
  }
}

.vc-search-box {
  flex: 1;
}

.vc-search-box input {
  background-color: var(--search-bar-bg-color);
  box-shadow: none;
  border: 0;
  border-radius: map-get($bm_spacers, 9);
  caret-color: $bm-primary-500;
  color: var(--search-bar-text-color);
  margin: auto;
  padding: map-get($bm-spacers, 3) map-get($bm_spacers, 10)
    map-get($bm_spacers, 3) map-get($bm_spacers, 10);
  width: 100%;

  @include media-breakpoint-up(lg) {
    min-width: 520px;
    width: 50%;
  }

  &:focus {
    background-color: var(--search-bar-focus-bg-color);
    color: var(--search-bar-focus-text-color);

    &::placeholder {
      color: var(--search-bar-focus-placeholder-text-color);
    }
  }
}

.vc-search-box input:focus + .ais-SearchBox-submit svg {
  fill: var(--search-bar-focus-submit-icon-color);
}

.vc-search-box input::-webkit-input-placeholder {
  color: var(--search-bar-placeholder-text-color);
}

.vc-search-box input::-moz-placeholder {
  color: var(--search-bar-placeholder-text-color);
}

.vc-search-box input::-moz-placeholder {
  opacity: 1;
}

.vc-search__bar {
  padding: 0 1rem;
}

.vc-search__bar input {
  box-shadow: none;
  border: 0;
  border-radius: 40px;
  padding: map-get($bm-spacers, 3);
}

::-webkit-search-cancel-button {
  display: none;
}

.vc-search--active {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}
