// Autoprefixer doesn't work with dpr here
// stylelint-disable media-feature-name-no-vendor-prefix
@mixin iphone-x {
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }

  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }

  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin ipad-pro {
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    @content;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

@mixin ipad-pro-portrait {
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

@mixin ipad-pro-landscape {
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    @content;
  }
}
// stylelint-enable media-feature-name-no-vendor-prefix
