.bm-skip-link {
  height: 1px;
  left: -100%;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;

  &:focus {
    height: auto;
    left: map-get($bm_spacers, 1);
    top: map-get($bm_spacers, 1);
    width: auto;
    z-index: 999;
  }
}
