.bm-account {
  column-gap: map-get($bm_spacers, 4);
  display: grid;
  row-gap: map-get($bm_spacers, 7);

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: map-get($bm_spacers, 9);
  }
}

.bm-account > :nth-child(n + 3) {
  @include media-breakpoint-up(lg) {
    grid-column: 1 / 3;
  }
}
