.vc-icon-text {
  --icon-height: #{map-get($bm_spacers, 8)};
  --icon-width: #{map-get($bm_spacers, 8)};

  @include media-breakpoint-up(md) {
    --icon-height: #{map-get($bm_spacers, 9)};
    --icon-width: #{map-get($bm_spacers, 9)};
  }
}

.vc-icon-text__content {
  margin-left: map-get($bm_spacers, 4);

  @include media-breakpoint-up(lg) {
    margin-left: map-get($bm_spacers, 6);
  }
}

.vc-icon-text__content > * + * {
  margin-top: map-get($bm_spacers, 1);

  @include media-breakpoint-up(lg) {
    margin-top: map-get($bm_spacers, 2);
  }
}

.vc-icon-text__icon {
  border-radius: map-get($bm_spacers, 2);
  flex-shrink: 0;
  height: var(--icon-height);
  width: var(--icon-width);
}
