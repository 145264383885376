// Disabling verification of classname pattern as it's mostly styling bootstrap

/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-no-qualifying-type */
a.disabled {
  pointer-events: none;
  opacity: 0.65;
}
/* stylelint-enable selector-no-qualifying-type */

.link {
  color: $bm-grey-dark;
  text-decoration: underline;
  background: none;
  border: none;
  appearance: none;

  &:hover {
    color: $bm-secondary;
  }

  &:active,
  &:focus {
    color: $bm-grey-darker;
  }

  &:focus-visible {
    outline: 2px solid currentcolor;
    outline-offset: map-get($bm_spacers, 1);
  }

  &--light {
    color: $bm-grey-light;

    &:hover {
      color: $bm-tertiary;
    }

    &:focus {
      color: $bm-tertiary;
      outline-color: $bm-tertiary;
    }
  }

  &--white {
    color: $bm-white;
    @include button-text;

    &.has-underline::after {
      background-color: currentColor;
    }

    &:hover {
      color: $bm-grey-light;
    }

    &:focus {
      color: $bm-tertiary;
      outline-color: $bm-tertiary;
    }
  }
}

.link--blue {
  color: $bm-informative-600;
  text-decoration: none;
}

.cta-link {
  display: inline-block;
  @include button-text;
  color: $bm-secondary;

  &:hover {
    color: $bm-secondary;
    text-decoration: none;
  }

  &:focus {
    outline: 2px solid currentcolor;
    outline-offset: map-get($bm_spacers, 1);

    &::after {
      background-color: $bm-neutral-50;
    }
  }
}

.cta-link--active {
  border-bottom: map-get($bm_spacers, "half") solid $bm-primary;
}

.link-underline {
  border-bottom: 1px solid $bm-neutral-100;
  color: $bm-neutral-100;

  &:hover {
    color: inherit;
    border-width: 0;
    text-decoration: none;
  }

  &:focus-visible {
    outline: 2px solid $bm-neutral-100;
    outline-offset: 2px;
  }
}

.link-underline--neutral-700 {
  border-bottom: 1px solid $bm-neutral-700;
  color: $bm-neutral-700;

  &:focus-visible {
    outline: 2px solid $bm-neutral-700;
  }
}

.link-underline--primary {
  border-bottom: 1px solid $bm-primary-500;
}

// Thick underline with primary colour
.has-underline {
  display: inline-block;

  &::after {
    background-color: $bm-primary;
    border: none;
    content: "";
    display: block;
    height: map-get($bm_spacers, "half");
    pointer-events: none;
    position: relative;
    transition: transform 0.4s, opacity 0.4s;
    transform: translateY(3px);
    width: 100%;
  }

  &--dark {
    &::after {
      background-color: $bm-secondary;
    }

    &:hover::after {
      background-color: $bm-primary;
    }
  }
}

.has-underline:hover {
  &::after {
    opacity: 1;
    transform: translateY(0);
  }
}

.has-underline--permanent {
  &::after {
    top: 0;
  }

  &:hover {
    text-decoration: none;
    &::after {
      top: 0;
    }
  }
}

.has-underline--slide {
  position: relative;

  &::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: map-get($bm_spacers, "half");
    content: "";
    background: $bm-primary;
    transition: transform 0.5s;
    transform: scaleX(0);
    transform-origin: right;
  }
}

.has-underline--slide-tertiary::after {
  background: $bm-tertiary;
}

.has-underline--slide:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
/* stylelint-enable selector-class-pattern */
