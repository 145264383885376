.bm-progress-bar {
  --progress-bar-height: 0.375rem;
  --progress-bar-width: 0;

  background-color: $bm-neutral-700;
  bottom: 0;
  height: 0.375rem;
  left: 0;
  width: 100%;
  z-index: 1;

  &::before {
    background-color: $bm-primary-500;
    bottom: 0;
    content: "";
    display: block;
    height: var(--progress-bar-height);
    left: 0;
    position: absolute;
    width: var(--progress-bar-width);
  }
}
