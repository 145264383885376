.bm-cms-hero-newsletter {
  align-items: stretch;

  .bm-hero-new {
    &__content {
      max-width: 92%;
      padding-top: 70%;
      margin: 0 auto (21rem / 16);
    }

    &__title {
      font-size: (32rem / 16);
    }
  }

  // Phone landscape
  @media only screen and (max-width: (767rem / 16)) and (orientation: landscape) {
    height: 130vh;
  }

  @media only screen and (min-width: (410rem / 16)) {
    align-items: flex-end;
    flex-direction: column;

    .bm-hero-new__content {
      padding-top: 0;
    }
  }

  // Tablet
  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: auto 50%;
    grid-template-rows: auto;
    align-items: center;
    min-height: 0;
    grid-gap: map-get($bm_spacers, 9);

    .bm-hero-new__content {
      grid-row: 1;
      grid-column: 2;
      max-width: (500rem / 16);
      padding: map-get($spacers, 3);
      padding-left: 0;
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  // Tablet portrait
  @media only screen and (min-width: (768rem / 16)) and (orientation: portrait) {
    height: 50vh;
  }

  // Desktop
  @include media-breakpoint-up(lg) {
    .bm-hero-new {
      min-height: (420rem / 16);

      &__content {
        padding: map-get($spacers, 3);
        padding-left: 0;
      }

      &__title {
        font-size: (44rem / 16);
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .bm-hero-new {
      &__content {
        max-width: (600rem / 16);
      }

      &__title {
        font-size: (58rem / 16);
      }
    }
  }
}
