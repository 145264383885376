// Some things have to be overriden forcefully here
// stylelint-disable
.bm-banner {
  &__img img {
    width: 100%;
    max-width: (300rem / 16);
  }

  @include media-breakpoint-down(sm) {
    & h3 {
      max-width: (400rem / 16);
      margin: 0 auto;
    }
  }

  &--grey {
    background-color: $bm-grey-lighter;
  }
  &--dark {
    background-color: $bm-secondary;
  }

  &--valentines {
    background-image: url("../images/campaign/valentines-marco.jpg");
    background-size: cover;

    @include media-breakpoint-down(sm) {
      background-image: url("../images/campaign/valentines-marco-mobile.jpg");
    }

    label,
    p,
    a,
    a.link {
      color: #fff;
    }

    a:hover,
    a.link:hover {
      color: #fff;
      text-decoration: none;
    }

    .bm-sub-heading--sm {
      color: #cddd8f;
    }
    h2 {
      color: #efcdcd;
      em {
        font-style: normal;
        color: #cddd8f;
      }
    }
  }

  &--custom-background {
    background-size: cover;
  }

  &--custom-background-invert {
    background-size: cover;

    label,
    p,
    a,
    a.link {
      color: #fff;
    }

    a:hover,
    a.link:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}
