/* stylelint-disable selector-max-id, selector-no-qualifying-type */

.bm-header {
  --header-bg-color: #{$bm-bg-grey-light};
  // Search Bar
  --search-container-bg-color: #{$bm-neutral-900};
  --search-bar-bg-color: #{$bm-white};
  --search-bar-submit-icon-color: #{$bm-neutral-900};
  --search-bar-focus-bg-color: #{$bm-white};
  --search-bar-focus-submit-icon-color: #{$bm-neutral-800};
  --search-bar-focus-text-color: #{$bm-neutral-800};
  --search-bar-reset-icon-color: #{$bm-neutral-900};
  --search-bar-text-color: #{$bm-neutral-900};
  --search-icon-color: #{$bm-neutral-900};
  --search-bar-text-color-open: #{$bm-neutral-50};
  --search-bar-caret-color: #{$bm-neutral-900};
  --search-bar-placeholder-text-color: #{$bm-neutral-600};
  --hamburger-menu-item-text-color: #{$bm-neutral-50};
  --hamburger-menu-text-color: #{$bm-neutral-900};
  --hamburger-menu-color: #{$bm-neutral-900};
  --hamburger-menu-color-open: #{$bm-neutral-50};
  --nav-item-color: #{$bm-neutral-900};
  --logo-fill-color: #{$bm-neutral-900};
  --logo-fill-color-bbc: #{$bm-neutral-800};
  --logo-fill-color-maestro: #{$bm-primary-500};

  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: $header-height-small;
  background-color: var(--header-bg-color);
  @include flex-align-center;

  @include media-breakpoint-up(lg) {
    --search-bar-placeholder-text-color: #{$bm-neutral-600};
    --search-bar-bg-color: #{$bm-neutral-100};
    --search-bar-submit-icon-color: #{$bm-neutral-900};
    --search-bar-text-color: #{$bm-neutral-900};
    --search-bar-text-color-open: var(--search-bar-text-color);
    --hamburger-menu-color-open: #{$bm-neutral-900};
  }

  &--neutral-800 {
    --header-bg-color: #{$bm-neutral-800};
    --logo-fill-color: #{$bm-neutral-50};
    --logo-fill-color-bbc: #{$bm-white};
    --logo-fill-color-maestro: #{$bm-white};
    --search-bar-bg-color: #{$bm-neutral-750};
    --search-bar-focus-bg-color: #{$bm-neutral-100};
    --search-bar-focus-submit-icon-color: #{$bm-neutral-800};
    --search-bar-focus-placeholder-text-color: #{$bm-neutral-600};
    --search-bar-submit-icon-color: #{$bm-white};
    --search-bar-text-color: #{$bm-neutral-200};
    --search-icon-color: #{$bm-neutral-50};
    --search-bar-placeholder-text-color: #{$bm-neutral-200};
    --hamburger-menu-color: #{$bm-neutral-50};
    --hamburger-menu-color-open: #{$bm-neutral-50};
    --hamburger-menu-text-color: #{$bm-neutral-50};
    --nav-item-color: #{$bm-neutral-50};

    @include media-breakpoint-up(lg) {
      --search-bar-text-color: #{$bm-neutral-900};
      --logo-fill-color: #{$bm-neutral-50};
      --logo-fill-color-bbc: #{$bm-white};
      --logo-fill-color-maestro: #{$bm-white};
      --hamburger-menu-color: #{$bm-neutral-50};
      --hamburger-menu-color-open: #{$bm-neutral-50};
    }

    .bm-header__logo-suffix {
      color: $bm-neutral-50;
    }

    .bm-header__logo {
      svg g {
        &#bbc,
        &#maestro {
          fill: #fff;
        }
      }
    }
  }

  &--transparent {
    --header-bg-color: transparent;
    --logo-fill-color: #{$bm-neutral-50};
    --logo-fill-color-bbc: #{$bm-white};
    --logo-fill-color-maestro: #{$bm-white};
    --search-bar-bg-color: #{$bm-neutral-750};
    --search-bar-focus-bg-color: #{$bm-neutral-100};
    --search-bar-focus-submit-icon-color: #{$bm-neutral-800};
    --search-bar-focus-placeholder-text-color: #{$bm-neutral-600};
    --search-bar-submit-icon-color: #{$bm-white};
    --search-bar-text-color: #{$bm-neutral-200};
    --search-icon-color: #{$bm-neutral-50};
    --search-bar-placeholder-text-color: #{$bm-neutral-200};
    --hamburger-menu-color: #{$bm-neutral-50};
    --hamburger-menu-color-open: #{$bm-neutral-50};
    --hamburger-menu-text-color: #{$bm-neutral-50};
    --nav-item-color: #{$bm-neutral-50};

    @include media-breakpoint-up(lg) {
      --logo-fill-color: #{$bm-neutral-50};
      --logo-fill-color-bbc: #{$bm-white};
      --logo-fill-color-maestro: #{$bm-white};
      --hamburger-menu-color: #{$bm-neutral-50};
      --hamburger-menu-color-open: #{$bm-neutral-50};
    }

    background-image: linear-gradient(
      to bottom,
      rgba(26, 26, 26, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );

    .bm-header__logo-suffix {
      color: $bm-neutral-50;
    }

    .bm-header__logo {
      svg g {
        &#bbc,
        &#maestro {
          fill: #fff;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &--transparent-desktop {
      --header-bg-color: transparent;
      --logo-fill-color: #{$bm-neutral-50};
      --logo-fill-color-bbc: #{$bm-white};
      --logo-fill-color-maestro: #{$bm-white};
      --hamburger-menu-color: #{$bm-neutral-50};
      --hamburger-menu-color-open: #{$bm-neutral-50};
      --nav-item-color: #{$bm-neutral-50};
      --search-bar-bg-color: #{$bm-neutral-750};
      --search-bar-focus-bg-color: #{$bm-neutral-100};
      --search-bar-focus-submit-icon-color: #{$bm-neutral-800};
      --search-bar-focus-placeholder-text-color: #{$bm-neutral-600};
      --search-bar-submit-icon-color: #{$bm-white};
      --search-bar-text-color: #{$bm-neutral-200};
      --search-icon-color: #{$bm-neutral-50};
      --search-bar-placeholder-text-color: #{$bm-neutral-200};

      background-color: var(--header-bg-color);
      background-image: linear-gradient(
        to bottom,
        rgba(26, 26, 26, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );

      .bm-header__logo-suffix {
        color: $bm-neutral-50;
      }

      .bm-header__logo {
        svg g {
          &#bbc,
          &#maestro {
            fill: #fff;
          }
        }
      }
    }
  }

  &__container {
    @include flex-align-center;
    justify-content: space-between;
    max-width: none;
    padding: 0 (15rem / 16);
  }

  &__logo-suffix {
    color: $bm-neutral-900;
  }

  &__logo {
    position: relative;
    line-height: 1;
    z-index: 2;
    display: block;
    width: (180rem / 16);

    @include media-breakpoint-up(xl) {
      width: (265rem / 16);
    }

    &--small {
      width: (136rem / 16);
    }

    & > svg {
      width: 100%;

      & g {
        transition: fill 0.4s;

        &#bbc {
          fill: $bm-secondary;
        }

        &#maestro {
          fill: $bm-primary;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    height: $header-height-large;
  }

  // Inverted colour when mobile nav
  // is expanded
  &.js-header-dark {
    background-color: $bm-secondary;

    @include media-breakpoint-up(lg) {
      background-color: var(--header-bg-color);
    }
  }
}

.js-logo-inverted svg g {
  @include media-breakpoint-down(md) {
    &#bbc,
    &#maestro {
      fill: $bm-white;
    }
  }
}

.bm-header--new__link {
  color: var(--nav-item-color);

  &:hover {
    color: var(--nav-item-color);
    text-decoration: none;
  }
}

.bm-header--new__search {
  color: var(--search-icon-color);
}

/* stylelint-enable selector-max-id, selector-no-qualifying-type */
