// stylelint-disable selector-no-qualifying-type
// stylelint-disable selector-max-type
// stylelint-disable selector-class-pattern

details {
  --transition-duration: 0.2s;
}

details > .details-animate {
  animation-name: expand-grid;
  animation-timing-function: ease-out;
}

details > .details-collapsing {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}

details > div {
  animation-duration: var(--transition-duration);
  display: grid;
  overflow: hidden;
}

details > div > div {
  min-height: 0;
}

summary {
  border-bottom: map-get($bm_spacers, "half") solid $bm-neutral-900;
  position: relative;
  list-style: none;
}

summary::-webkit-details-marker {
  display: none;
}

summary::marker {
  content: none;
}

summary:focus-visible {
  outline: 2px solid $bm-neutral-50;
  outline-offset: map-get($bm_spacers, "half");
}

summary::after {
  background-image: url("../images/icons/chevron-up.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  height: map-get($bm_spacers, 6);
  position: absolute;
  right: 1rem;
  top: 50%;
  transition: transform var(--transition-duration) ease-in;
  transform: translateY(-50%) rotate(180deg);
  transform-origin: center;
  width: map-get($bm_spacers, 6);
}

details[open] summary::after {
  transform: rotate(0deg) translateY(-50%);
}

@keyframes expand-grid {
  0% {
    grid-template-rows: 0fr;
  }

  100% {
    grid-template-rows: 1fr;
  }
}
