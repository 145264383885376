.bm-choose-gift-type {
  @media (max-width: (992rem / 16)) {
    h4 {
      font-size: (21rem / 16);
    }
  }
}

.bm-gift-steps {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0 auto;
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: $bm-white;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-up(md) {
    justify-content: space-between;
    height: auto;
    overflow-x: hidden;
  }

  &__container {
    display: flex;
    min-width: 100%;
    max-width: 100%;
    padding: 0 1rem;
    margin: 0 auto 2rem;
    scroll-snap-align: start;

    @include media-breakpoint-up(md) {
      width: 33%;
      min-width: (330rem / 16);
      max-width: (440rem / 16);
    }

    @include media-breakpoint-up(lg) {
      width: 33%;
      min-width: 0;
    }
  }

  &__step {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    margin: 0 auto;
    background-color: $bm-grey-lightest;

    & > img {
      width: 80%;
      max-width: (100rem / 16);
    }
  }

  // Shrink for smallest tablet sizes
  // to fit inline
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    &__container {
      min-width: 0;
    }

    &__step {
      padding: 2rem;
    }

    &__heading {
      font-size: 24px;
    }

    &__paragraph {
      font-size: 14px;
    }
  }
}

.bm-choose-gift-panel {
  margin: auto;

  > div > div > div {
    min-height: 18rem;
  }

  & > :last-child {
    $choose-gift-gap: 5px;

    > div {
      @media only screen and (max-width: 767px) {
        padding-right: $choose-gift-gap * 4 !important;
      }
    }

    > div > div {
      border: 1px solid $bm-primary;
      box-shadow: $choose-gift-gap $choose-gift-gap 0 0 #fff,
        $choose-gift-gap $choose-gift-gap 0 1px $bm-primary,
        ($choose-gift-gap * 2) ($choose-gift-gap * 2) 0 0 #fff,
        ($choose-gift-gap * 2) ($choose-gift-gap * 2) 0 1px $bm-primary,
        ($choose-gift-gap * 3) ($choose-gift-gap * 3) 0 0 #fff,
        ($choose-gift-gap * 3) ($choose-gift-gap * 3) 0 1px $bm-primary,
        ($choose-gift-gap * 4) ($choose-gift-gap * 4) 0 0 #fff,
        ($choose-gift-gap * 4) ($choose-gift-gap * 4) 0 1px $bm-primary;
    }
  }
}
