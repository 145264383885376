.bm-hero-preorder {
  background-color: $bm-neutral-900;

  &__img {
    box-shadow: $bm-shadow-card;
  }

  .bm-hero-new__course-title {
    font-size: (13rem / 16);
  }

  .bm-price {
    color: $bm-tertiary;
  }

  &__inner-content {
    max-width: (600rem / 16);
    margin: 0 auto;
  }

  &__perk-box {
    max-width: (400rem / 16);
    padding: map-get($spacers, 4);
    margin-right: auto;
    margin-left: auto;
  }

  &__perk-list {
    display: inline-block;
    margin-top: map-get($spacers, 2);
    text-align: left;
  }

  @include media-breakpoint-up(md) {
    background-color: $bm-secondary;
    position: relative;
    height: calc(100vh - #{$header-height-large});
    min-height: (700rem / 16);
    overflow: hidden;

    &__img,
    &__img-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    &__img {
      padding-bottom: 0;
      box-shadow: none;
    }

    &__img-overlay {
      background-color: $bm-hero-overlay;
    }

    &__content {
      position: absolute;
      bottom: map-get($spacers, 4);
      left: 0;
      width: 100%;
    }

    .bm-hero-new__course-title {
      font-size: $font-size-md;
    }

    &__perk-box {
      display: inline-block;
      max-width: none;
    }

    &__perk-list {
      display: flex;
      @include rythm-x(3);
    }

    .bm-campaign-badge {
      top: (25rem / 16);
      right: (25rem / 16);
      z-index: 1;
      max-width: (210rem / 16);
      max-height: (210rem / 16);

      .bm-campaign-content__heading {
        font-size: 1.3em;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__perk-box {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: map-get($spacers, 2) map-get($spacers, 4);
      margin-top: map-get($spacers, 4);
      @include rythm-x(3);
    }

    &__perk-list {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    .bm-campaign-badge {
      font-size: (15rem / 16);
    }
  }
}
