.bm-cookie-consent {
  position: fixed;
  bottom: 0;
  z-index: 65000;
  width: 100%;
  padding: 0.8rem 1rem;
  font-size: 14px;
  text-align: center;
  background-color: $bm-white;

  &__accept {
    padding: 0.4em 1.4em;
    margin-left: 0.5rem;
    color: $bm-white;
    background: $bm-primary;
    border: none;
  }

  a {
    margin-left: 0.5rem;
    white-space: nowrap;
  }
}
