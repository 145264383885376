.bm-course-overview-panel {
  display: flex;
  align-items: center;

  &__img {
    width: 5em;

    & > img {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    height: 5em;
    margin-left: map-get($spacers, 3);
    overflow: hidden;
    font-weight: 700;

    .bm-sub-heading--sm {
      font-size: 0.75em;
    }

    .bm-sub-heading {
      font-size: 0.8em;

      @include media-breakpoint-up(xxl) {
        font-size: 1em;
      }
    }
  }
}

.bm-course-overview > * + * {
  margin-left: map-get($bm_spacers, 6);

  @include media-breakpoint-up(lg) {
    margin-left: map-get($bm_spacers, 8);
  }
}

.bm-course-overview__text > * {
  color: $bm-neutral-200;
}

.vc-column-list {
  @include media-breakpoint-up(md) {
    max-width: 50rem;

    > * + * {
      margin-left: map-get($bm_spacers, 11);
    }
  }
}
