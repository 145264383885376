$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);

.bm-navbar {
  position: static;
}

.bm-account-icon {
  border-radius: 50%;
  height: map-get($bm-spacers, 8);
  width: map-get($bm-spacers, 8);
}

// MOBILE NAV TOGGLE
.bm-nav-toggle {
  position: relative;
  z-index: 100;
  color: $bm-grey-darker;
  background: none;
  border: none;
  appearance: none;
  @include flex-center;

  & > * {
    pointer-events: none;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }

  & .bm-menu-text {
    transition: opacity 0.4s;
    transition-delay: 0.4s;

    &__menu {
      transition-delay: 0.5s;
    }

    &__close {
      position: absolute;
      top: 50%;
      left: 0;
      color: $bm-tertiary;
      opacity: 0;
      transform: translateY(-50%);
    }
  }

  & .bm-sub-heading--sm {
    font-size: $font-size-xs;
  }

  &__icon {
    background-color: $bm-grey-medium;
    height: 42px;
    position: relative;
    transition: background-color 0.4s;
    width: 42px;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: map-get($bm_spacers, 5);
      height: 2px;
      content: " ";
      background-color: $bm-neutral-800;
      transition: transform 0.3s;
      transform: translateX(-50%);
    }

    &::before {
      top: 16px;
    }

    &::after {
      top: 22px;
    }

    &--dark {
      background-color: $bm-neutral-800;

      &::before,
      &::after {
        background-color: $bm-neutral-100;
      }
    }
  }

  // Nav expanded
  &.js-nav-toggled {
    color: $bm-white;

    & .bm-menu-text {
      &__menu {
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0s;
      }

      &__close {
        opacity: 1;
        transition-delay: 0s;
      }
    }

    & .bm-nav-toggle__icon {
      background: rgba($bm-grey-darker, 0.2);

      &::before,
      &::after {
        background-color: $bm-tertiary;
      }

      &::before {
        transform: translate(-8px, 3px) rotate(45deg);
      }

      &::after {
        transform: translate(-8px, -3px) rotate(-45deg);
      }
    }
  }
}

// NAV LIST
.bm-navbar-content {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100vw;
  height: 0;
  overflow: hidden;
  background-color: $bm-neutral-800;
  transition: height 0.5s;
  transform: translate3d(0, 0, 0);

  @include flex-center;

  & a {
    color: $bm-white;
  }

  @include media-breakpoint-up(lg) {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    overflow: visible;
    background-color: transparent;
  }

  & .bm-nav-list {
    @include flex-align-center;
  }

  & .bm-nav-item {
    position: static;
    margin: 0;
    opacity: 0;
    backface-visibility: hidden;
    transition: transform 0.5s $cubic, opacity 0.6s $cubic;
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        transition-delay: #{0.56 - ($i * 0.6)}s;
      }
    }
    transform: scale(1.15) translateY(-30px);

    &:not(:last-child) {
      margin-bottom: (50rem / 16);
    }

    @include media-breakpoint-up(lg) {
      opacity: 1;
      transform: scale(1) translateY(0);
    }

    & a {
      font-size: $font-size-sm;
      transition: color 0.4s, background-color 0.4s, box-shadow 0.4s,
        transform 0.4s;

      @include media-breakpoint-down(md) {
        &:hover:focus {
          &::after {
            background-color: $bm-primary;
          }
        }
      }

      &:not(.bm-nav-item--cta) {
        color: $bm-tertiary;

        .bm-header--transparent &,
        .bm-header--neutral-800 & {
          color: $bm-white;
        }

        @include media-breakpoint-up(lg) {
          color: $bm-secondary;
        }

        @include media-breakpoint-up(lg) {
          .bm-header--transparent-desktop & {
            color: $bm-white;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-xs;
      }
    }

    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        margin: 0 map-get($bm_spacers, 7) 0 0;
      }
    }

    @include media-breakpoint-up(xl) {
      &:not(:last-child) {
        margin-right: map-get($bm_spacers, 10);
      }
    }
  }

  &.js-nav-expanded {
    height: 100vh;

    & .bm-nav-item {
      opacity: 1;
      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          transition-delay: #{0.07 * $i + 0.2}s;
        }
      }
      transform: scale(1) translateY(0);
    }
  }
}
