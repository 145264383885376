.bm-landing-hero__content-wrapper {
  max-width: 500px;

  @include media-breakpoint-up(xl) {
    width: fit-content;
  }
}

.bm-landing-hero__logo {
  max-width: 286px;
}
