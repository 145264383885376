.vc-image-content {
  --two-column-layout: repeat(2, minmax(0, 1fr));

  &--grid-lg {
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: var(--two-column-layout);
    }
  }

  &--grid-md {
    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: var(--two-column-layout);
    }
  }
}

.vc-image-content__promo {
  background-color: $bm-neutral-800;

  @include media-breakpoint-up(lg) {
    background-color: $bm-neutral-850;
  }
}

.vc-image-content__figure--reverse {
  order: 1;
}

.vc-image-content__title {
  line-height: map-get($bm_spacers, 7);

  @include media-breakpoint-up(lg) {
    line-height: map-get($bm_spacers, 8);
  }
}

.vc-image-content__content {
  padding: map-get($bm_spacers, 8) map-get($bm_spacers, 6);

  @include media-breakpoint-up(lg) {
    padding-left: map-get($bm_spacers, 11);
    padding-right: map-get($bm_spacers, 11);
  }
}

.vc-image-content__image {
  object-fit: cover;
}

.vc-image-content__list {
  font-size: $font-size-sm;

  @include media-breakpoint-up(md) {
    font-size: initial;
  }
}
