// TODO - Refactor to scrim-gradient mixin once
// other pages have been refactored
.bm-gradient-grey {
  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 20%, 0) 0%,
    hsla(0, 0%, 20%, 0.084) 7.6%,
    hsla(0, 0%, 20%, 0.171) 14.7%,
    hsla(0, 0%, 20%, 0.26) 21.2%,
    hsla(0, 0%, 20%, 0.35) 27.2%,
    hsla(0, 0%, 20%, 0.439) 33%,
    hsla(0, 0%, 20%, 0.526) 38.7%,
    hsla(0, 0%, 20%, 0.61) 44.2%,
    hsla(0, 0%, 20%, 0.689) 49.8%,
    hsla(0, 0%, 20%, 0.762) 55.6%,
    hsla(0, 0%, 20%, 0.827) 61.6%,
    hsla(0, 0%, 20%, 0.884) 68.1%,
    hsla(0, 0%, 20%, 0.932) 75%,
    hsla(0, 0%, 20%, 0.967) 82.6%,
    hsla(0, 0%, 20%, 0.991) 90.9%,
    hsl(0, 0%, 20%) 100%
  );
}
