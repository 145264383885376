.vc-split-card {
  &--fixed-width {
    margin: 1rem;
    max-width: 100%;
  }

  &__inner {
    display: flex;
    flex-flow: column wrap;

    @include media-breakpoint-up(md) {
      flex-flow: row wrap;
    }

    margin: auto;
    width: 100%;

    &--full-width {
      @include media-breakpoint-up(xl) {
        width: 80%;
      }
    }

    &__column {
      flex: 1;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      & > div {
        height: 100%;
      }

      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &__heading {
    text-align: center;
    margin-bottom: $spacer * 3;

    @include media-breakpoint-down(md) {
      @include font-size($h2-font-size);

      margin-bottom: $spacer;
    }
  }
}

.vc-split-card__free-trial {
  padding: map-get($bm_spacers, 7);

  @include media-breakpoint-up(lg) {
    padding: map-get($bm_spacers, 7) map-get($bm_spacers, 8);
  }
}
