.vc-radio-checkbox {
  --radio-label-color: #{$bm-neutral-600};
  --radio-input-bg-color: #{$bm-neutral-200};
  --radio-container-bg-color: #{$bm-white};
  --radio-container-bg-color-checked: #{$bm-primary};
  // eslint-disable-next-line declaration-empty-line-before
  background-color: var(--radio-container-bg-color);
  // Vertical padding in .vc-radio-checkbox__label
  padding-left: map-get($bm_spacers, 4);
  padding-right: map-get($bm_spacers, 4);
}

.vc-radio-checkbox__input {
  appearance: none;
  background-color: var(--radio-input-bg-color);
  width: 1.5em;
  height: 1.5em;
}

.vc-radio-checkbox__input:checked {
  background-color: var(--radio-container-bg-color-checked);
}

.vc-radio-checkbox__input:checked ~ .vc-radio-checkbox__state {
  .vc-radio-checkbox__state--selected {
    opacity: 1;
  }
}

.vc-radio-checkbox__state {
  top: 0;
  left: 0;
}

.vc-radio-checkbox__state--selected {
  pointer-events: none;
  opacity: 0;
  top: 0.2em;
  left: 0.2em;
  height: 1.1em;
  width: 1.1em;
}

.vc-radio-checkbox__label {
  color: var(--radio-label-color);
  flex-grow: 1;
  line-height: 1.25rem;
  margin-bottom: 0;
  // Vertical padding added to label so full element
  //  height is clickable for selecting radio option.
  padding-top: map-get($bm_spacers, 3);
  padding-bottom: map-get($bm_spacers, 3);
}
