.vc-separator {
  &::before,
  &::after {
    background-color: $bm-neutral-600;
    content: "";
    display: block;
    height: 0.0625rem;
    width: 45%;
  }
}

.vc-separator__text {
  line-height: 1.25rem;
  margin: 0 map-get($bm_spacers, 5);
}
