$font-family-playfair: 'Playfair Display', 'serif';

$font-family-roboto-light: 'Roboto Light', 'sans-serif';
$font-family-roboto-regular: 'Roboto Regular', 'sans-serif';
$font-family-roboto-medium: 'Roboto Medium', 'sans-serif';
$font-family-roboto-bold: 'Roboto Bold', 'sans-serif';

$font-size-xxs: (10rem / 16);
$font-size-xs: (12rem / 16);
$font-size-sm: (14rem / 16);
$font-size-md: 1rem;
$font-size-lrg: (18rem / 16);
$font-size-x-lrg: (20rem / 16);
$font-size-xx-lrg: 1.5rem;

.bm-font-weight-light {
  font-family: $font-family-roboto-light;
}

.bm-font-weight-regular {
  font-family: $font-family-roboto-regular;
}

.bm-font-weight-medium {
  font-family: $font-family-roboto-medium;
  font-weight: 500;
}

.bm-font-weight-bold {
  font-family: $font-family-roboto-bold;
}
