.vc-checkbox {
  --checkbox-label-color: #{$bm-neutral-100};
  --checkbox-input-bg-color: #{$bm-neutral-50};
  --checkbox-input-focus-color: #{$bm-primary};
  --checkbox-border-color: #{$bm-neutral-400};
  --checkbox-container-bg-color: #{$bm-neutral-850};
  --checkbox-container-bg-color-checked: #{$bm-primary};
  --checkbox-input-border-color: transparent;
  // eslint-disable-next-line declaration-empty-line-before
  background-color: var(--checkbox-container-bg-color);
  border: 1px solid var(--checkbox-border-color);
  padding-right: map-get($bm_spacers, 4);

  &--light {
    --checkbox-container-bg-color: transparent;
    --checkbox-label-color: #{$bm-neutral-900};
    --checkbox-border-color: #{$bm-neutral-150};
    --checkbox-input-border-color: #{$bm-neutral-200};
    --checkbox-input-bg-color: transparent;
  }
}

.vc-checkbox__input {
  height: map-get($bm_spacers, 6);
  width: map-get($bm_spacers, 6);
}

.vc-checkbox__input:focus:not(:focus-visible) ~ span {
  outline: none;
}

.vc-checkbox__input:focus ~ span {
  outline: 2px solid var(--checkbox-input-focus-color);
  outline-offset: 2px;
}

.vc-checkbox__input ~ span {
  border: 1px solid var(--checkbox-input-border-color);
  background-color: var(--checkbox-input-bg-color);
  min-height: map-get($bm_spacers, 6);
  min-width: map-get($bm_spacers, 6);
}

.vc-checkbox__input:checked ~ span {
  background-color: var(--checkbox-container-bg-color-checked);
}

.vc-checkbox__input:checked ~ span .vc-checkbox__state {
  opacity: 1;
}

.vc-checkbox__state {
  height: 1.1em;
  left: 0;
  left: 0.2em;
  opacity: 0;
  pointer-events: none;
  top: 0;
  top: 0.2em;
  width: 1.1em;
}

.vc-checkbox__label {
  color: var(--checkbox-label-color);
  flex-grow: 1;
  line-height: 1.25rem;
  padding-bottom: map-get($bm_spacers, 6);
  padding-top: map-get($bm_spacers, 6);
}

.vc-checkbox__label--with-image {
  padding-bottom: 0;
  padding-top: 0;
}
