// stylelint-disable selector-max-id, selector-class-pattern, declaration-no-important, selector-no-qualifying-type

.bm-login {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  background-color: $bm-white;

  @include media-breakpoint-up(sm) {
    background-color: $bm-neutral-100;
  }
}

.bm-login__header {
  background-color: $bm-white !important;

  @include media-breakpoint-up(sm) {
    background-color: $bm-neutral-100 !important;
  }
}

.bm-login__main {
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: -$header-height-small;

  @include media-breakpoint-up(md) {
    margin-top: -$header-height-large;
  }

  // remove vertical offset in landscape mobile view
  @media (pointer: coarse) and (orientation: landscape) {
    margin-top: 0;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
}

.bm-login__logo {
  position: relative;
  z-index: 2;
  display: block;
  width: (180rem / 16);

  @include media-breakpoint-up(lg) {
    width: (265rem / 16);
  }

  & > svg {
    width: 100%;

    & path {
      transition: fill 0.4s;

      &#bbc {
        fill: $bm-secondary;
      }

      &#maestro {
        fill: $bm-primary;
      }
    }
  }
}

.bm-login__container {
  background-color: $bm-white;
  margin: 0 auto;
  padding: 24px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: 407px;
    padding: 40px;
  }

  &__heading {
    color: $bm-neutral-900;

    @include media-breakpoint-down(lg) {
      font-size: 1.625rem;
    }
  }

  &__subheading {
    color: $bm-neutral-800;
  }
}

.bm-login__container__form {
  &__label {
    font-weight: 500;
    color: $bm-neutral-700;
  }
}

.bm-login__container__back-button {
  display: inline-flex;
  align-items: center;
  color: $bm-neutral-700;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.075rem;

  &:hover {
    text-decoration: none;
    color: $bm-primary;
    transition: margin-right 2s;
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    color: $bm-primary;
  }
}

.bm-login__container__link {
  color: $bm-informative-600;
  text-decoration: none;
  font-weight: 500;
}

.bm-login__text-field {
  background-color: $bm-white;
  border: 1px solid $bm-neutral-250 !important;
  caret-color: $bm-primary;
}

.bm-login__check-box {
  + label {
    &::before {
      border: 1px solid $bm-neutral-250;
    }
  }

  &:checked {
    + label {
      &::before {
        border: none;
      }
    }
  }
}

.bm-login__container__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
