.bm-promo {
  &__container {
    max-width: (400rem / 16);
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: (1136rem / 16);
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      max-width: (1288rem / 16);
      padding: 0 (30rem / 16);
    }
  }

  &__img-container {
    position: relative;
    display: block;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      flex-shrink: 0;
      width: 40%;
      max-width: (450rem / 16);
      padding-bottom: 40%;
      margin-bottom: -1rem;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      width: 90%;
      height: 90%;
    }

    & img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
    }

    &--bg-light {
      position: absolute;
      top: 5%;
      right: 15%;
      display: block;
      width: 80%;
      height: 80%;
      content: '';
      background-color: $bm-primary;
    }

    &--bg-dark {
      position: absolute;
      top: 10%;
      right: 29%;
      display: block;
      width: 70%;
      height: 70%;
      content: '';
      background-color: $bm-primary-darker;
    }
  }

  &__content {
    // IE fix to force text to wrap
    & > p {
      width: 100%;
    }
  }

  &__description {
    max-width: (420rem / 16);
  }

  @include media-breakpoint-up(sm) {
    &__content .bm-btn-box {
      justify-content: space-between;
    }
  }

  @include media-breakpoint-up(md) {
    &__img {
      top: 51%;
      transform: translateY(-50%);

      &--bg-light {
        top: 11%;
      }

      &--bg-dark {
        top: 16%;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      max-width: (530rem / 16);
      padding-left: 3rem;

      & .bm-btn-box {
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;

        & > a {
          &:first-child {
            margin-bottom: 0;
          }

          &:last-child {
            margin-left: map-get($spacers, 5);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      padding-left: 6rem;
    }
  }
}

.bm-promo__redeem {
  & .bm-btn-box > a:first-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    & form {
      width: 100%;
    }

    & .bm-btn-box {
      display: flex;
      align-items: stretch;

      a:first-child {
        margin-right: 1.5rem;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    /* stylelint-disable selector-no-qualifying-type */
    & .bm-btn-box > input[type='submit'] {
      width: 50%;
    }
    /* stylelint-enable selector-no-qualifying-type */
  }
}
