.vc-table tr:not(:nth-last-of-type(-n + 2)) {
  border-bottom: 1px solid $bm-neutral-700;
}

.vc-table tr th:first-child {
  font-weight: 400;
  max-width: 7rem;
}

.vc-table thead th {
  font-weight: 400;
  vertical-align: top;
}

.vc-table th,
.vc-table td {
  max-width: 10rem;
  min-width: 5rem;
}

thead .vc-table__th {
  padding: map-get($bm_spacers, 6) map-get($bm_spacers, 2);

  @include media-breakpoint-up(md) {
    padding: map-get($bm_spacers, 6) map-get($bm_spacers, 4)
      map-get($bm_spacers, 4);
  }
}

tbody .vc-table__cell {
  padding: map-get($bm_spacers, 6) map-get($bm_spacers, 2);

  @include media-breakpoint-up(md) {
    padding: map-get($bm_spacers, 6) map-get($bm_spacers, 4);
  }
}

.vc-table__cell--small {
  padding: map-get($bm_spacers, 4) map-get($bm_spacers, 3)
    map-get($bm_spacers, 4);
}

.vc-table td:first-child {
  padding-left: 0;
}

/* Table badge */
.vc-table__badge {
  top: -(map-get($bm_spacers, 3));
}
