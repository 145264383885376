.vc-huge {
  font-size: 2.25rem;

  @include media-breakpoint-up(lg) {
    font-size: 2.75rem;
  }
}

.vc-h1 {
  font-size: 1.625rem;

  @include media-breakpoint-up(lg) {
    font-size: 2.25rem;
  }
}

.vc-h2 {
  font-size: 1.375rem;

  @include media-breakpoint-up(lg) {
    font-size: 2.25rem;
  }
}

.vc-h3 {
  font-size: 1.375rem;
  line-height: 2rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.625rem;
  }
}

.vc-h4 {
  font-size: 1.125rem;
  line-height: 1.5rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.375rem;
  }
}

.vc-subtitle {
  font-size: 0.875rem;
  letter-spacing: 0;

  @include media-breakpoint-up(lg) {
    font-size: 1.125rem;
    font-family: $font-family-roboto-light;
    font-weight: 300;
  }
}

.vc-subtitle-lg {
  font-size: 1rem;
  font-family: $font-family-roboto-light;
  font-weight: 300;
  letter-spacing: 0;

  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.5rem;
    line-height: map-get($bm_spacers, 7);
  }
}

.vc-h-small {
  font-size: 1.375rem;
}

.vc-subtitle-default {
  font-family: $font-family-roboto-medium;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.vc-subtitle-section {
  color: $bm-neutral-200;
  font-size: $font-size-sm;

  @include media-breakpoint-up(lg) {
    color: $bm-neutral-50;
    font-family: $font-family-roboto-light;
    font-size: $font-size-lrg;
  }
}
