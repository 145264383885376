.bm-steps {
  display: inline-block;
  font-size: $font-size-md;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-sm;
  }

  &__container {
    display: flex;
  }

  &__step {
    height: 2px;
    margin-top: map-get($spacers, 1);
    background-color: $bm-grey-light;

    @include media-breakpoint-up(lg) {
      margin-top: map-get($spacers, 2);
    }

    &:not(:last-child) {
      margin-right: map-get($spacers, 1);
    }
  }
}
