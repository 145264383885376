.vc-tabs__panel {
  color-scheme: dark;
  margin-top: map-get($bm_spacers, 2);
}

.vc-tabs__list {
  color-scheme: dark;
  margin-top: map-get($bm_spacers, 3);
  overflow-x: auto;
  padding-bottom: map-get($bm_spacers, 2);
}

.vc-tabs__button {
  appearance: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  font-family: $font-family-playfair;
  font-weight: bold;
  line-height: 2rem;
  padding-bottom: map-get($bm_spacers, 1);

  &::after {
    background-color: transparent;
    content: "";
    display: block;
    height: calc(map-get($bm_spacers, 1) / 2);
    margin-top: map-get($bm_spacers, 2);
    width: 100%;
  }

  &:hover {
    text-decoration: none;
  }

  &:hover::after {
    background-color: $bm-neutral-50;
  }

  &:focus {
    outline-offset: 2px;
    outline: 2px solid $bm-neutral-50;
  }
}

.vc-tabs__button[aria-selected="false"] {
  color: $bm-neutral-250;

  &:hover {
    color: $bm-neutral-50;
  }
}

.vc-tabs__button[aria-selected="true"] {
  color: $bm-neutral-50;

  &::after {
    background-color: $bm-neutral-50;
  }
}

.vc-tabs__button--primary[aria-selected="true"] {
  &::after {
    background-color: $bm-primary-500;
  }
}

.vc-tabs .vc-overflow-shadow__container {
  @include media-breakpoint-up(xl) {
    height: 27rem;
  }
}
