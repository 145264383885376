.vc-courses {
  padding-top: map-get($bm_spacers, 12);

  @include media-breakpoint-up(md) {
    padding-top: calc(
      map-get($bm_spacers, 13) + map-get($bm_spacers, 7)
    ); // A calculation between two tokens is used here in order to spacing the first section an appropriate amount away from the sticky header section
  }
}

.vc-courses__filters {
  padding-top: map-get($bm_spacers, 4);

  @include media-breakpoint-up(md) {
    margin-left: 0;
    padding: map-get($bm_spacers, 6) 0 map-get($bm_spacers, 4);
  }
}

.vc-courses__button {
  margin-left: -(map-get($bm_spacers, 4));

  @include media-breakpoint-up(md) {
    margin-left: 0;
  }
}

.vc-courses__gallery {
  display: grid;
  column-gap: map-get($bm_spacers, 2);
  row-gap: map-get($bm_spacers, 6);
  grid-template-columns: 1fr;
  margin-top: map-get($bm_spacers, 4);

  @include media-breakpoint-up(xxs) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-gap: map-get($bm_spacers, 6);
    grid-template-columns: 1fr 1fr 1fr;
  }
}
