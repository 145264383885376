$bm-shadow: 0 15px 23px -14px rgba(0, 0, 0, 0.4);
$bm-shadow-dark: 0 13px 10px -10px rgba(0, 0, 0, 0.6);
$bm-shadow-md: 0 50px 34px -40px rgba(0, 0, 0, 0.12);
$bm-shadow-lg: 0 50px 34px -40px rgba(0, 0, 0, 0.32);
$bm-shadow-card: 0 50px 34px -40px rgba(0, 0, 0, 0.6);
$bm-shadow-modal: 0 0 23px 8px rgba(0, 0, 0, 0.2);
$bm-shadow-modal-light: 0 14px 23px 8px rgba(0, 0, 0, 0.2);

$bm-gradient-hero-mobile: linear-gradient(
  to right,
  #1c1c1c 0%,
  rgba(28, 28, 28, 1) 27%,
  rgba(28, 28, 28, 0.961) 30%,
  rgba(28, 28, 28, 0.938) 34%,
  rgba(28, 28, 28, 0.741) 49%,
  rgba(28, 28, 28, 0.682) 53%,
  rgba(28, 28, 28, 0.478) 59.5%,
  rgba(28, 28, 28, 0.194) 67%,
  rgba(28, 28, 28, 0.126) 75%,
  rgba(28, 28, 28, 0.075) 80.2%,
  rgba(28, 28, 28, 0.042) 86.1%,
  rgba(28, 28, 28, 0.021) 91%,
  rgba(28, 28, 28, 0.008) 95.2%,
  rgba(28, 28, 28, 0.002) 98.2%,
  rgba(28, 28, 28, 0) 100%
);

$bm-gradient-hero-tablet-up: linear-gradient(
  to right,
  #1c1c1c 18%,
  rgba(28, 28, 28, 0.961) 20%,
  rgba(28, 28, 28, 0.938) 21%,
  rgba(28, 28, 28, 0.841) 24%,
  rgba(28, 28, 28, 0.782) 25%,
  rgba(28, 28, 28, 0.782) 25%,
  rgba(28, 28, 28, 0.782) 25%,
  rgba(28, 28, 28, 0.782) 25%,
  rgba(28, 28, 28, 0.782) 25%,
  rgba(28, 28, 28, 0.678) 28%,
  rgba(28, 28, 28, 0.594) 30%,
  rgba(28, 28, 28, 0.426) 35%,
  rgba(28, 28, 28, 0.075) 46%,
  rgba(28, 28, 28, 0.042) 47%,
  rgba(28, 28, 28, 0.021) 48%,
  rgba(28, 28, 28, 0.008) 49%,
  rgba(28, 28, 28, 0.002) 50%,
  rgba(28, 28, 28, 0) 51%
);

$bm-gradient-new-hero-portrait: linear-gradient(
  to top,
  hsl(0, 0%, 11%) 0%,
  hsla(0, 0%, 11%, 0.987) 8.6%,
  hsla(0, 0%, 11%, 0.951) 17.5%,
  hsla(0, 0%, 11%, 0.896) 26.5%,
  hsla(0, 0%, 11%, 0.825) 35.6%,
  hsla(0, 0%, 11%, 0.741) 44.6%,
  hsla(0, 0%, 11%, 0.648) 53.3%,
  hsla(0, 0%, 11%, 0.55) 61.7%,
  hsla(0, 0%, 11%, 0.45) 69.6%,
  hsla(0, 0%, 11%, 0.352) 76.9%,
  hsla(0, 0%, 11%, 0.259) 83.4%,
  hsla(0, 0%, 11%, 0.175) 89%,
  hsla(0, 0%, 11%, 0.104) 93.6%,
  hsla(0, 0%, 11%, 0.049) 97.1%,
  hsla(0, 0%, 11%, 0.013) 99.2%,
  hsla(0, 0%, 11%, 0) 100%
);

$bm-gradient-new-hero-landscape-sm: linear-gradient(
  to right,
  hsl(0, 0%, 0%) 0%,
  hsla(0, 0%, 0%, 0.987) 18.6%,
  hsla(0, 0%, 0%, 0.951) 34.4%,
  hsla(0, 0%, 0%, 0.896) 47.7%,
  hsla(0, 0%, 0%, 0.825) 58.8%,
  hsla(0, 0%, 0%, 0.741) 67.9%,
  hsla(0, 0%, 0%, 0.648) 75.2%,
  hsla(0, 0%, 0%, 0.55) 81%,
  hsla(0, 0%, 0%, 0.45) 85.5%,
  hsla(0, 0%, 0%, 0.352) 88.8%,
  hsla(0, 0%, 0%, 0.259) 91.4%,
  hsla(0, 0%, 0%, 0.175) 93.4%,
  hsla(0, 0%, 0%, 0.104) 95%,
  hsla(0, 0%, 0%, 0.049) 96.5%,
  hsla(0, 0%, 0%, 0.013) 98.1%,
  hsla(0, 0%, 0%, 0) 100%
);

$bm-gradient-new-hero-landscape: linear-gradient(
  to right,
  hsl(0, 0%, 0%) 0%,
  hsla(0, 0%, 0%, 0.987) 12.4%,
  hsla(0, 0%, 0%, 0.951) 23%,
  hsla(0, 0%, 0%, 0.896) 32.1%,
  hsla(0, 0%, 0%, 0.825) 39.8%,
  hsla(0, 0%, 0%, 0.741) 46.4%,
  hsla(0, 0%, 0%, 0.648) 52%,
  hsla(0, 0%, 0%, 0.55) 57.1%,
  hsla(0, 0%, 0%, 0.45) 61.6%,
  hsla(0, 0%, 0%, 0.352) 66%,
  hsla(0, 0%, 0%, 0.259) 70.3%,
  hsla(0, 0%, 0%, 0.175) 74.9%,
  hsla(0, 0%, 0%, 0.104) 79.9%,
  hsla(0, 0%, 0%, 0.049) 85.6%,
  hsla(0, 0%, 0%, 0.013) 92.2%,
  hsla(0, 0%, 0%, 0) 100%
);
