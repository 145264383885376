@font-face {
  font-display: swap;
  font-family: "Playfair Display";
  font-weight: 700;
  src: url("../fonts/webfonts/PlayfairDisplay/PlayfairDisplay-Bold.woff2")
      format("woff2"),
    url("../fonts/webfonts/PlayfairDisplay/PlayfairDisplay-Bold.woff")
      format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Roboto Light";
  font-weight: 300;
  src: url("../fonts/webfonts/RobotoLight/Roboto-Light.woff2") format("woff2"),
    url("../fonts/webfonts/RobotoLight/Roboto-Light.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Roboto Regular";
  src: url("../fonts/webfonts/RobotoRegular/Roboto-Regular.woff2")
      format("woff2"),
    url("../fonts/webfonts/RobotoRegular/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Roboto Medium";
  font-weight: 500;
  src: url("../fonts/webfonts/RobotoMedium/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/webfonts/RobotoMedium/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Roboto Bold";
  font-weight: 700;
  src: url("../fonts/webfonts/RobotoBold/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/webfonts/RobotoBold/Roboto-Bold.woff") format("woff");
}
