// GENERIC

.bm-container-lg,
.bm-container-md,
.bm-container-xs,
.bm-container-devise {
  margin: 0 auto;
}

.bm-container-lg {
  width: 100%;
  max-width: (1600rem / 16);
  padding: 0 (15rem / 16);

  @include media-breakpoint-up(md) {
    padding: 0 (30rem / 16);
  }
}

.bm-container-md {
  max-width: (1136rem / 16);
  padding: 0 (15rem / 16);

  @include media-breakpoint-up(md) {
    padding: 0 (56rem / 16);
  }

  @include media-breakpoint-up(lg) {
    max-width: (1288rem / 16);
    padding: 0 (76rem / 16);
  }
}

.bm-container-sm {
  max-width: (775rem / 16);
  padding: 0 (15rem / 16);
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    padding: 0 (56rem / 16);
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
    margin: 0 auto;
  }
}

.bm-container-xs {
  max-width: (850rem / 16);
  padding: 0 (60rem / 16);

  @include media-breakpoint-up(md) {
    padding: 0 (200rem / 16);
  }

  @include media-breakpoint-up(lg) {
    padding: 0 (76rem / 16);
  }
}

.bm-container--margin-bottom-md {
  margin-bottom: 6rem;
}

.bm-container--full-width {
  width: 100vw;
}

.bm-container-mw-660 {
  max-width: (660rem / 16);
}

.bm-container-mw-450--md-down {
  @include media-breakpoint-down(sm) {
    max-width: (450rem / 16);
    margin: 0 auto;
  }
}

.bm-container--no-padding-right {
  @include media-breakpoint-down(xs) {
    padding-right: 0;
  }
}

// LAYOUT SPECIFIC CONTAINERS
.bm-container--static-page {
  max-width: (420rem / 16);
  padding: 0 (15rem / 16);
  margin: 0 auto;
}

.bm-container-coming-soon {
  @extend .padding-y-xxl;

  @include media-breakpoint-down(sm) {
    padding-bottom: 0;
  }
}

.bm-container-coming-soon--marketing {
  margin-top: 3rem;

  @include media-breakpoint-up(md) {
    padding: 0 (56rem / 16);
    margin: (130rem / 16) auto 0;
  }

  @include media-breakpoint-up(lg) {
    max-width: (1288rem / 16);
    padding: 0 (76rem / 16);
  }
}

.bm-container--with-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.bm-container-video {
  max-width: (945rem / 16);
  margin: 0 auto;

  &:not(.bm-bg-video) {
    position: relative;
  }

  &--full-width {
    max-width: none;
  }
}

.bm-container-video--large {
  margin: 0 auto;
  max-width: 90rem;
}

.bm-container-devise {
  max-width: (450rem / 16);
  background-color: $bm-bg-grey-light;

  @include media-breakpoint-up(lg) {
    width: (450rem / 16);
    margin: 0 auto;
  }
}

.bm-container-account {
  max-width: (750rem / 16);
  padding: (30rem / 16);
  background-color: $bm-bg-grey;

  @include media-breakpoint-up(md) {
    padding: (65rem / 16) (75rem / 16);
  }

  a {
    text-decoration: underline;
  }

  &--scrollable {
    scroll-margin-top: 10rem; // The header is 3.75rem in height therefore 10 will be enough to clear this
  }
}

.bm-form-container {
  max-width: (440rem / 16);
  margin: 0 auto;

  &--narrow {
    max-width: (355rem / 16);
    margin: 0 auto;
  }

  & .bm-account-form__actions,
  &--narrow .bm-account-form__actions {
    & > input,
    & > a {
      min-width: (150rem / 16);
    }
  }
}

.bm-container-cms-sm {
  max-width: (800rem / 16);
}

// A specific container for scaling up with the viewport
// Used by lessons player section & lessons slider
.bm-container-lesson {
  @include media-breakpoint-up(lg) {
    width: calc(100vw - 30px);
    min-width: (965rem / 16);
    max-width: 159.5vh;
    padding: 0 3.5rem;
  }
  @media only screen and (min-width: 1025px) {
    min-width: (1020rem / 16);
    max-width: (1138rem / 16);
  }
  @include ipad-pro-portrait {
    padding: 0 3.5rem;
  }
}

// A container for fullscreen hero sections, allowing for some space at
// the bottom on mobile to account for browser bookmarks bar
.bm-hero__container {
  position: relative;
  // 100% height minus height of header and average browser header
  height: calc(100vh - 60px - 85px);
  //min-height: (340rem / 16);
  overflow: hidden;
  @include flex-center;

  // Ensure hero height is above the fold on all devices
  @include iphone-x {
    height: calc(100vh - 60px - 115px);
  }

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    height: calc(100vh - 75px);
  }

  @include media-breakpoint-up(lg) {
    height: 100%;
    max-height: calc(100vh - 85px);
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 991px) and (orientation: landscape) {
    height: calc(100vh - 70px - 90px);
  }

  @include ipad-pro {
    height: calc(100vh - 145px);
  }

  @media only screen and (max-width: 744px) and (orientation: landscape) {
    height: calc(100vh - 75px);
  }
}
