// stylelint-disable
.bm-custom-flash-banner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: (15rem / 16);
  background-color: $bm-grey-lighter;

  .alert {
    width: 100%;
    padding: (6rem / 16) 1rem;
    margin: 0;
    font-size: $font-size-md;

    .close {
      margin-right: 1rem;
    }

    @media only screen and (max-width: 440px) {
      padding: (6rem / 16) (12rem / 16);
      font-size: 12px;

      .close {
        margin-right: (12rem / 16);
      }
    }
  }
}
// stylelint-enable
