// stylelint-disable selector-no-qualifying-type

.vc-radio {
  --radio-label-color: #{$bm-neutral-100};
  --radio-input-bg-color: #{$bm-neutral-50};
  --radio-input-focus-color: #{$bm-primary};
  --radio-border-color: #{$bm-neutral-400};
  --radio-bg-color: #{$bm-neutral-850};
  --radio-bg-color-checked: #{$bm-primary};

  cursor: pointer;
  padding-right: map-get($bm_spacers, 4);

  &--light {
    border: 1px solid var(--radio-border-color);

    --radio-bg-color: transparent;
    --radio-input-bg-color: transparent;
    --radio-label-color: #{$bm-neutral-900};
    --radio-border-color: #{$bm-neutral-150};
    --radio-button-border-color: #{$bm-neutral-200};
  }
}

.vc-radio--bordered {
  background-color: var(--radio-bg-color);
  border: 1px solid var(--radio-border-color);
}

.vc-radio__label {
  color: var(--radio-label-color);
}

.vc-radio__rounded {
  border: 1px solid var(--radio-button-border-color);
  border-radius: 50%;
}

.vc-radio__button {
  height: map-get($bm_spacers, 6);
  width: map-get($bm_spacers, 6);
}

.vc-radio input[type="radio"]:checked + .vc-radio__button::after {
  background-color: var(--radio-bg-color-checked);
  border-radius: 50%;
  content: "";
  display: block;
  height: map-get($bm_spacers, 4);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: map-get($bm_spacers, 4);
}

.vc-radio input[type="radio"]:focus + .vc-radio__button::before {
  outline: 2px solid var(--radio-input-focus-color);
  outline-offset: 2px;
}

.vc-radio--transparent input[type="radio"]:focus + .vc-radio__button::before {
  outline-offset: 4px;
}

.vc-radio__button::before {
  background-color: var(--radio-input-bg-color);
  border-radius: 50%;
  content: "";
  height: map-get($bm_spacers, 6);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: map-get($bm_spacers, 6);
}
