$bm-primary: #e45f00;
$bm-primary-dark: #d55a00;
$bm-primary-darker: #d25a02;
$bm-secondary: #333;
$bm-secondary-light: #424242;
$bm-secondary-dark: #272727;
$bm-secondary-darker: #1c1c1c;
$bm-tertiary: #eee;
$bm-red: #b00020;
$bm-white: #fff;

$bm-black: #000;
$bm-black-light: #0b0b0b;
$bm-black-lighter: #141414;

$bm-neutral-50: #f2f2f2;
$bm-neutral-100: #e6e6e6;
$bm-neutral-150: #d9d9d9;
$bm-neutral-200: #ccc;
$bm-neutral-250: #bfbfbf;
$bm-neutral-300: #b3b3b3;
$bm-neutral-350: #a6a6a6;
$bm-neutral-400: #999;
$bm-neutral-450: #8c8c8c;
$bm-neutral-500: #808080;
$bm-neutral-550: #737373;
$bm-neutral-600: #666;
$bm-neutral-650: #595959;
$bm-neutral-700: #4d4d4d;
$bm-neutral-750: #404040;
$bm-neutral-800: #333;
$bm-neutral-850: #262626;
$bm-neutral-900: #1a1a1a;
$bm-neutral-950: #0d0d0d;

/* Primary */

$bm-primary-50: #fff3eb;
$bm-primary-100: #ffeadb;
$bm-primary-200: #ffd2b3;
$bm-primary-300: #ffb27a;
$bm-primary-400: #ff8f3e;
$bm-primary-500: #e45f00;
$bm-primary-600: #d15700;
$bm-primary-700: #b84d00;
$bm-primary-800: #943e00;
$bm-primary-900: #6b2d00;

/* Success */
$bm-success-50: #f0fdf4;
$bm-success-100: #dcfce7;
$bm-success-200: #bbf7d0;
$bm-success-300: #86efac;
$bm-success-400: #4ade80;
$bm-success-500: #22c55e;
$bm-success-600: #16a34a;
$bm-success-700: #15803d;
$bm-success-800: #166534;
$bm-success-900: #14532d;

$bm-mint-400: #c2e2dc;

/* Danger */
$bm-danger-50: #fef2f2;
$bm-danger-100: #fee2e2;
$bm-danger-200: #fecaca;
$bm-danger-300: #fca5a5;
$bm-danger-400: #f87171;
$bm-danger-500: #ef4444;
$bm-danger-600: #dc2626;
$bm-danger-700: #b91c1c;
$bm-danger-800: #991b1b;
$bm-danger-900: #7f1d1d;

/* Warning */
$bm-warning-50: #fffbeb;
$bm-warning-100: #fef3c7;
$bm-warning-200: #fde68a;
$bm-warning-300: #fcd34d;
$bm-warning-400: #fbbf24;
$bm-warning-500: #f59e0b;
$bm-warning-600: #d97706;
$bm-warning-700: #b45309;
$bm-warning-800: #92400e;
$bm-warning-900: #78350f;

/* Informative */
$bm-informative-50: #eff6ff;
$bm-informative-600: #2563eb;

/* Social */
$bm-facebook: #3b5999;
$bm-twitter: #28a8e7;

$bm-grey-lighter: #ddd;
$bm-grey-lightest: rgba($bm-grey-lighter, 0.7);
$bm-grey-light: #bbb;
$bm-grey-medium: #d8d8d8;
$bm-grey-medium-dark: #666;
$bm-grey-dark: #555;
$bm-grey-darker: #222;
$bm-grey-darkest: #111;

$bm-accessible-grey: #757575;

$bm-bg-white: #fff;
$bm-bg-grey-light: #eee;
$bm-bg-grey: #eaeaea;
$bm-bg-grey-dark: #454545;
$bm-bg-grey-darker: #333;
$bm-bg-modal: #f5f5f5;

$bm-hero-overlay: rgba($bm-secondary, 0.4);
