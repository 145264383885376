// Disabling verification of classname pattern as it's mostly styling bootstrap
// stylelint-disable selector-class-pattern, declaration-no-important
.bm-testimonial {
  box-shadow: $bm-shadow;

  &__username {
    font-size: 0.9rem;
    color: $bm-grey-medium-dark;
  }
}

.bm-testimonials {
  @include media-breakpoint-down(xs) {
    @include scrollable-columns(
      '.bm-testimonials__wrapper',
      '.bm-testimonials__item',
      $column-width: 70%
    );
  }

  [data-macy-complete] {
    // At 768px exactly, macy's style compete with Bootstrap's `col...` class
    // max-width. Given macy deals with the whole layout, we can safely clear that
    max-width: none;
    margin-bottom: 0 !important; // margin is dealt with by macy
  }

  &__item {
    padding: 0 map-get($bm_spacers, 2) map-get($bm_spacers, 4)
      map-get($bm_spacers, 2);

    @include media-breakpoint-up(lg) {
      padding: 0 map-get($bm_spacers, 4) map-get($bm_spacers, 7)
        map-get($bm_spacers, 4);
    }
  }
}
