@include media-breakpoint-down(md) {
  /* Add a pseudo element with the
     text from attribute 'data-abbr' */
  [data-mobile-text]::after {
    content: attr(data-mobile-text);
  }

  /* Hide the original label */
  [data-mobile-text] > span {
    display: none;
  }
}
