@import "./colors";

$theme-colors: (
  primary: $bm-primary,
  secondary: $bm-secondary,
  secondary-light: $bm-secondary-light,
  tertiary: $bm-tertiary,
  danger: $bm-red,
  light: $bm-grey-light,
  grey: $bm-grey-lighter,
  grey-medium-dark: $bm-grey-medium-dark,
  custom-grey: $bm-bg-grey,
  custom-grey-light: $bm-grey-dark,
  custom-grey-dark: $bm-grey-darker,
  custom-grey-darkest: $bm-grey-darkest,
  accessible-grey: $bm-accessible-grey,
  primary-50: $bm-primary-50,
  primary-400: $bm-primary-400,
  primary-500: $bm-primary-500,
  success-500: $bm-success-500,
  informative-50: $bm-informative-50,
  danger-500: $bm-danger-500,
  danger-600: $bm-danger-600,
  danger-900: $bm-danger-900,
  neutral-50: $bm-neutral-50,
  neutral-100: $bm-neutral-100,
  neutral-200: $bm-neutral-200,
  neutral-250: $bm-neutral-250,
  neutral-300: $bm-neutral-300,
  neutral-400: $bm-neutral-400,
  neutral-500: $bm-neutral-500,
  neutral-600: $bm-neutral-600,
  neutral-650: $bm-neutral-650,
  neutral-700: $bm-neutral-700,
  neutral-750: $bm-neutral-750,
  neutral-800: $bm-neutral-800,
  neutral-850: $bm-neutral-850,
  neutral-900: $bm-neutral-900,
  mint-400: $bm-mint-400,
);

$grid-breakpoints: (
  z: 0,
  xxs: 320px,
  xs: 370px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1441px,
) !default;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
  6: (
    $spacer * 4,
  ),
  7: (
    $spacer * 5,
  ),
  8: (
    $spacer * 6.5,
  ),
);

$border-width: (2rem / 16);
