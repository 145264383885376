.bm-logo {
  --bm-logo-height: #{map-get($bm_spacers, 7)};
  --bm-logo-width: #{map-get($bm_spacers, 11)};

  height: var(--bm-logo-height);
  width: var(--bm-logo-width);
  z-index: 1;

  @include media-breakpoint-up(lg) {
    --bm-logo-height: #{map-get($bm_spacers, 8)};
    --bm-logo-width: #{map-get($bm_spacers, 12)};
  }
}

.bm-logo:not(.js-logo-inverted) svg g#bbc {
  fill: var(--logo-fill-color-bbc);
}

.bm-logo:not(.js-logo-inverted) svg g#maestro {
  fill: var(--logo-fill-color-maestro);
}
