// stylelint-disable
.alert-grey-dark {
  font-size: 0.9em;
  color: #333;
  background-color: #d5d5d5;
}

.flashes {
  & .alert {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem 2.9rem 0.75rem 2.25rem;
    margin: -1rem 0 1rem;
    font-family: $font-family-roboto-bold;
    font-size: $font-size-sm;
    color: $bm-grey-dark;
    background-color: transparent;
    border-radius: 0;

    & button {
      margin-right: map-get($spacers, 2);
      background-color: transparent;
      border: none;
      opacity: 1;
      appearance: none;
    }
  }

  & .alert-warning,
  & .alert-danger {
    border: 2px solid rgba($bm-red, 0.6);

    & button {
      color: $bm-red;
    }
  }

  & .alert-success,
  & .alert-info {
    border: 2px solid rgba($bm-primary, 0.6);
    color: $bm-primary;

    & svg polygon {
      fill: $bm-primary;
    }
  }

  & .alert-info {
    white-space: initial;
  }
}

.bm-application-layout {
  & .alert-info .close {
    margin-right: map-get($spacers, 3);
  }
}

.bm-container-devise {
  & .flashes .alert {
    display: flex;
    width: 100%;
    padding: 0.75rem 1.5rem;

    &:not(.alert-success) {
      justify-content: center;

      & button {
        margin-right: 1.4rem;
      }
    }
  }
}

.bm-login {
  & .flashes .alert {
    display: flex;
    width: 100%;
    padding: 0.75rem 1.5rem;

    &:not(.alert-success) {
      justify-content: center;

      & button {
        margin-right: 1.4rem;
      }
    }
  }
}
