// stylelint-disable selector-class-pattern
.smartbanner {
  align-items: center;
  background: $bm-neutral-800;
  display: flex;
  left: 0;
  overflow-x: hidden;
  padding: map-get($bm_spacers, 3);
  position: absolute;
  top: 0;
  width: 100%;
}

.smartbanner__exit {
  border: 0;
  display: block;
  height: 16px;
  margin: 0;
  position: relative;
  text-align: center;
  width: 16px;
}

.smartbanner__exit::before,
.smartbanner__exit::after {
  background: $bm-neutral-50;
  content: " ";
  height: 16px;
  position: absolute;
  width: 1px;
}

.smartbanner__exit::before {
  transform: rotate(45deg);
}

.smartbanner__exit::after {
  transform: rotate(-45deg);
}

.smartbanner__icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 64px;
  margin-left: 1rem;
  width: 64px;
}

.smartbanner__info {
  align-items: center;
  color: $bm-neutral-50;
  display: flex;
  font-weight: $font-family-roboto-medium;
  padding-left: 1rem;
}

.smartbanner__info__title {
  font-size: 14px;
}

.smartbanner__info__author,
.smartbanner__info__price {
  font-size: 12px;
}

.smartbanner__button {
  color: $bm-primary-500;
  display: block;
  font-size: 0.75rem;
  font-family: $font-family-roboto-bold;
  letter-spacing: 0.075rem;
  margin-left: auto;
  text-decoration: none;
  text-transform: uppercase;
  z-index: 1;
}
