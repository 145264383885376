// Disabling verification of classname pattern as it's mostly styling bootstrap
// stylelint-disable selector-class-pattern, declaration-no-important, selector-no-qualifying-type

// FORM GROUPS
.form-group {
  margin-bottom: map-get($spacers, 3);

  & label {
    color: $bm-grey-dark;
  }

  &__inline {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > .form-group {
      width: 30%;
    }

    @include media-breakpoint-up(lg) {
      & > .form-group {
        width: 30%;
      }
    }

    &--name {
      & > .form-group {
        width: 47%;
      }
    }

    &--address {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & > .form-group {
        width: 47%;
      }
    }
  }

  &__cvc {
    position: relative;

    &-tooltip {
      position: absolute;
      top: -1px;
      right: 0;
      text-decoration: underline;
      background-color: transparent;
      border: none;
      appearance: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

// INPUTS
.form-control {
  height: auto;
  padding: (13rem / 16) (15rem / 16);
  border: none;
  border-radius: 0;
  transition: box-shadow 0.4s;

  // Remove text-shadow that FF adds
  &:-moz-focusring {
    text-shadow: none;
  }

  &::placeholder {
    color: rgba($bm-secondary, 0.3);
  }

  &:valid,
  &.is-valid {
    background-position: center right 1rem;
  }

  &.is-invalid {
    background-image: url("../images/icons/form-error.svg");
    background-repeat: no-repeat;
    background-position: center right 1rem;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

    &:focus {
      box-shadow: inset 0 -9px 0 -7px $bm-red;
    }
  }

  &:focus-visible {
    text-shadow: none;
    color: currentcolor;
  }

  &:focus,
  &:valid:focus,
  &.is-valid:focus {
    box-shadow: none;
    outline: 2px solid $bm-primary;
    outline-offset: 3px;
  }

  &--no-focus {
    &:focus,
    &:valid:focus,
    &.is-valid:focus {
      box-shadow: none;
      outline: none;
    }
  }

  &--remove-valid-validation.is-valid {
    background-image: none;
  }
}

select.form-control {
  background-position: center right 1rem;
}

.form-control--dark {
  background-color: $bm-neutral-750;
  color: $bm-neutral-100;

  &:focus,
  &:valid:focus,
  &.is-valid:focus {
    background-color: $bm-neutral-700;
    box-shadow: none;
    color: $bm-neutral-100;
    outline: 2px solid $bm-white;
    outline-offset: 3px;
  }

  &::placeholder {
    color: $bm-neutral-400;
    font-size: 0.875rem;
  }
}

.form-control--border {
  box-shadow: inset 0 -1px 0 $bm-neutral-600;
}

.form-control--bordered {
  border-bottom: 1px solid $bm-neutral-250;
  border-left: 1px solid $bm-neutral-250;
  border-top: 1px solid $bm-neutral-250;
  color: $bm-neutral-900;

  &::placeholder {
    color: $bm-neutral-600;
  }

  &:focus {
    border-color: $bm-primary-500;
    color: $bm-neutral-900;
    outline-offset: 0 !important;
    outline: none !important;
  }

  @include media-breakpoint-up(sm) {
    min-width: 250px;
  }
}

// SMALL LABEL/HINTS
.form-text {
  font-size: $font-size-xs;
  color: $bm-grey-medium-dark !important;
}
// optional payment warning/message
.form-optional-warning {
  padding: 1em;
  margin: 1.8rem 0;
  font-weight: 700;
  color: $bm-primary !important;
  border: 2px solid $bm-primary;
}
// Hints on a darker background
.bm-form-container--narrow {
  & small {
    color: $bm-grey-dark !important;
  }
}

// ERRORS
.form-error-field,
.invalid-feedback,
.terms-invalid-feedback {
  font-family: $font-family-roboto-regular;
  font-size: $font-size-xs;
  color: $bm-red;
  &.card-error-message {
    padding: 1em;
    font-size: 1em;
    border: 2px solid $bm-red;
  }
}

.invalid-feedback--dark-bg {
  color: $bm-danger-400;
}

.form-check-label {
  font-family: $font-family-roboto-regular;
  abbr {
    @include sr-only;
  }
}

// LABEL & TOOLTIP
.form-control-label,
.form-group__cvc-tooltip {
  margin-bottom: map-get($spacers, 1);
  font-family: $font-family-roboto-regular;
  font-size: $font-size-sm;
  line-height: 1.7;
  color: $bm-grey-dark;

  & abbr {
    display: none;
  }
}

// MAILING LIST
.form-mailing-list {
  &__terms {
    margin: map-get($spacers, 2) 0 -5px;

    &,
    & a {
      font-size: $font-size-xs;
      color: $bm-grey-dark;
    }

    & a:hover {
      color: rgba($bm-grey-dark, 0.7);
    }
  }
}

// DARK VARIATION
.form-dark {
  color: $bm-tertiary;

  & .bm-input-and-button--dark input {
    color: $bm-tertiary !important;
    background-color: rgba($bm-grey-dark, 0.5) !important;

    &::placeholder {
      color: $bm-tertiary;
    }

    &:valid:focus {
      box-shadow: inset 0 -9px 0 -7px $bm-tertiary;
    }
  }

  & .invalid-feedback,
  .terms-invalid-feedback {
    color: $bm-tertiary;
  }

  & .bm-form-success-message {
    color: $bm-tertiary;
  }

  & .form-mailing-list__terms {
    &,
    & a {
      color: $bm-tertiary;
    }

    & a {
      &:hover {
        color: rgba($bm-tertiary, 0.7);
      }

      &:focus {
        color: currentColor;
        outline: 2px solid $bm-tertiary;
        outline-offset: 3px;
        box-shadow: none;
      }

      &:focus:not(.focus-visible) {
        outline: none;
      }
    }
  }
}

.form-newsletter {
  .form-mailing-list__terms {
    color: $bm-neutral-250;

    & a {
      color: $bm-neutral-250;
    }
  }
}

// FORM & SUBMIT BTN INLINE
.bm-input-and-button {
  display: flex;
  flex-direction: column;
  max-width: (440rem / 16);

  & > input {
    margin-bottom: map-get($spacers, 4);
  }

  & ~ .form-mailing-list__terms {
    max-width: (440rem / 16);
  }

  @media only screen and (min-width: 350px) {
    flex-direction: row;

    & > input {
      margin-bottom: 0;
    }
  }
}

.bm-input-and-button--chevron-xs {
  .bm-input-and-button__chevron {
    display: none;
  }

  @media only screen and (max-width: 350px) {
    flex-direction: row;

    input {
      margin-bottom: 0;
    }

    button {
      padding: 1rem 1.5rem;
    }

    .bm-input-and-button__subscribe {
      display: none;
    }

    .bm-input-and-button__chevron {
      display: inline;
      width: 14px;
      height: 12px;
    }
  }
}

// PASSWORD TOGGLE
.bm-password-toggle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1.5rem;
  padding: 0 2rem 0 3rem;
  background-image: url("../images/icons/password-show.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 43%;
  border: none;
  outline: none;

  @include media-breakpoint-up(lg) {
    right: 1rem;
    padding: 0 1rem;
    background-size: contain;
  }

  &[role="button"] {
    cursor: pointer;
  }

  &:focus {
    outline: 2px solid $bm-primary;
    outline-offset: 3px;
    box-shadow: none;
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
}

.form-control.is-invalid ~ .bm-password-toggle {
  // move it over so it doesn't overaly the invalid input image
  right: calc(0.375em + 1.375rem);

  @include media-breakpoint-up(lg) {
    right: calc(0.375em + 2.4rem);
  }
}

[type="text"] ~ .bm-password-toggle {
  background-image: url("../images/icons/password-hide.svg");
}

// Stop icon overlapping text
.form-control.password {
  padding-right: 3.6rem;
}

textarea.form-control {
  border: 1px solid $bm-neutral-200;
}

// SELECT
.custom-select {
  display: block;
  padding-right: 2rem;
  background: #fff url("../images/icons/chevron-down.svg") no-repeat center
    right/0.75rem;
  text-overflow: ellipsis;

  &::-ms-expand {
    display: block;
  }

  // A fix for the simple form error icon and
  // the select arrow both being displayed as
  // bg images
  &.is-valid {
    background: url("../images/icons/tick-green.svg") no-repeat center
        right/1.1rem,
      #fff url("../images/icons/chevron-down.svg") no-repeat 0.75rem;
  }

  &.is-invalid {
    background: url("../images/icons/form-error.svg") no-repeat center
        right/1.1rem,
      #fff url("../images/icons/chevron-down.svg") no-repeat 0.75rem;
  }

  &.is-valid,
  &.is-invalid {
    background-position-x: calc(100% - 16px), calc(100% - 48px);
    background-position-y: 50%;
  }
}

// TEXT AREA
.form-control.text.is-invalid,
.form-control.text.is-valid {
  background-position: center right 16px;
}

// CHECKBOX
input[type="checkbox"] {
  @include bm-checkbox;
  @include bm-checkbox-appearance;
}

// Stop green validation colors
.form-check-input.is-valid ~ .form-check-label {
  color: currentColor;
}

// Make link colours within labels inherit invalid colours
.form-check-input.is-invalid ~ .form-check-label .link {
  color: currentColor;
}

.form-check {
  small {
    padding-left: 0.5rem;
    margin-left: 0.25rem;
  }
}

// SSL icon & text
.bm-form-ssl {
  @include flex-center;
  margin-top: map-get($spacers, 4);

  & > img {
    width: 60px;
    margin-right: 1rem;
  }
}

.bm-form-money-back-guarantee {
  margin-top: map-get($spacers, 4);
}

// STRIPE STYLES
.StripeElement {
  &--focus {
    box-shadow: inset 0 -9px 0 -7px $bm-primary;

    &.StripeElement--invalid {
      box-shadow: inset 0 -9px 0 -7px $bm-red;
    }
  }
}

.StripeElement--invalid {
  background-image: url("../images/icons/form-error.svg");
  background-repeat: no-repeat;
  background-position: center right 1rem;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

  &:focus {
    box-shadow: inset 0 -9px 0 -7px $bm-red;
  }
}

.radio_buttons {
  > * + * {
    margin-top: map-get($bm_spacers, 4);
  }

  .form-check {
    input[type="radio"] {
      appearance: none;
      border-radius: 50%;
      border: 1px solid $bm-neutral-200;
      height: map-get($bm_spacers, 6);
      margin-top: 0;
      width: map-get($bm_spacers, 6);
    }

    input[type="radio"]:checked,
    input[type="radio"]:focus {
      background-color: $bm-primary-500;
      box-shadow: inset 0 0 0 4px $bm-white;
    }

    input[type="radio"]:focus {
      outline: map-get($bm_spacers, "half") solid $bm-primary-500;
      outline-offset: map-get($bm_spacers, "half");
    }

    label {
      padding-left: map-get($bm_spacers, 3);
    }
  }
}

.e-card-summary {
  padding: 12px 16px;
  background-color: $bm-white;
  box-shadow: $bm-shadow;

  &__heading {
    font-family: $font-family-roboto-medium;
    font-size: $font-size-lrg;
    text-align: center;
  }
}

/* stylelint-disable selector-max-id */
#password-field {
  .invalid-feedback {
    display: block;
  }
}
/* stylelint-enable selector-max-id */

.bm-form-hide {
  display: none;
}

// This hides a form field that if filled in by a bot should be prevented.
.nohoney {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

// stylelint-enable selector-class-pattern, declaration-no-important, selector-no-qualifying-type
