.vc-expiry-banner {
  color: $bm-grey-light;
  position: relative;
  z-index: 100;

  &__content {
    max-width: 80%;
  }

  p {
    color: $bm-white;
  }

  &__dismiss {
    appearance: none;
    background: none;
    border: none;
    display: inline-flex;

    @include media-breakpoint-up(lg) {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    span {
      pointer-events: none;
    }

    svg {
      height: 12px;
      pointer-events: none;
      width: 12px;
    }

    &:focus {
      outline: 2px solid $bm-neutral-50;
    }
  }
}

.vc-expiry-banner__maybe-sticky-container--sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

// Note: if the expiry banner is present and is sticky then we need the header
// to be fixed instead of sticky, which is now the default
// stylelint-disable selector-class-pattern
.vc-expiry-banner__maybe-sticky-container--sticky ~ .bm-header {
  position: fixed;
  top: auto;
}

// Note: this reinstates the spacing between the <main> element and the header
// is the header is explicitly sticky and there is an expiry banner present
.vc-expiry-banner__maybe-sticky-container--sticky
  ~ .bm-header
  ~ .bm-application-layout {
  padding-top: (60rem / 16);

  @include media-breakpoint-up(md) {
    padding-top: (70rem / 16);
  }
}
