.bm-table {
  width: 100%;
  color: $bm-grey-dark;
  table-layout: fixed;

  &__container {
    transition: height 0.4s ease-in-out;
  }

  & thead {
    font-family: $font-family-roboto-regular;

    & th {
      padding-bottom: map-get($bm_spacers, 3);
      padding-left: map-get($bm_spacers, 5);
      padding-top: map-get($bm_spacers, 3);

      &:not(:last-child) {
        width: 25%;
      }
    }
  }

  /* stylelint-disable selector-max-type */

  & tbody {
    border: 2px solid $bm-grey-lighter;
    transition: height 0.4s ease;

    & tr {
      &:not(:last-child) {
        border-bottom: 2px solid $bm-grey-lighter;
      }
    }

    & td {
      padding-bottom: map-get($bm_spacers, 3);
      padding-left: map-get($bm_spacers, 5);
      padding-top: map-get($bm_spacers, 3);

      @include media-breakpoint-up(md) {
        padding-bottom: map-get($bm_spacers, 6);
        padding-top: map-get($bm_spacers, 6);
      }

      &:not(:last-child) {
        width: 25%;
      }

      & a {
        display: block;
        white-space: nowrap;

        &:nth-of-type(2) {
          margin-top: map-get($spacers, 2);
        }

        @include media-breakpoint-up(sm) {
          display: inline-block;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    & thead th:not(:last-child),
    & tbody tr td:not(:last-child) {
      width: 15%;
    }
  }

  @include media-breakpoint-up(md) {
    & thead th:not(:last-child),
    & tbody tr td:not(:last-child) {
      width: 18%;
    }

    & thead th:last-child,
    & tbody tr td:last-child {
      padding-left: map-get($spacers, 4);
    }
  }

  /* stylelint-enable selector-max-type */

  & .bm-table__description {
    @include text-ellipsis;
  }

  &__toggle {
    // stylelint-disable-next-line
    &.collapsed::before {
      display: block;
      content: 'Show more';
    }

    &::before {
      display: block;
      content: 'Show less';
    }
  }
}

.no-js .bm-table__collapse {
  display: table-row-group;
}
