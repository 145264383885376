.bm-insight-new {
  max-width: (400rem / 16);
  margin-right: auto;
  margin-left: auto;
  background-color: $bm-grey-lighter;
  scroll-margin-top: map-get($bm_spacers, 12);

  @include media-breakpoint-up(md) {
    max-width: none;
    background-color: $bm-white;
  }

  &:not(:first-child) {
    margin-top: map-get($spacers, 3);

    @include media-breakpoint-up(md) {
      margin-top: map-get($spacers, 5);
    }
  }

  &__content {
    border-left: 1px solid $bm-neutral-800;
    border-right: 1px solid $bm-neutral-800;
    border-bottom: 1px solid $bm-neutral-800;
    padding: map-get($bm_spacers, 6) map-get($bm_spacers, 7);

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      border-top: 1px solid $bm-neutral-800;
      border-left: 0;
      background-color: $bm-neutral-800;
      padding: map-get($spacers, 4) map-get($spacers, 5);
    }

    @include media-breakpoint-up(lg) {
      padding: 0 map-get($bm_spacers, 12);
    }
  }

  &__inner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin: 0 auto;
      text-align: left;
    }
  }

  &__subtext * {
    font-family: $font-family-roboto-light;
    font-size: $font-size-md;
    color: $bm-neutral-200;

    a {
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $bm-primary;
      }
    }
  }

  // Client request to have fullscreen buttons for landscape ipad
  &__cta {
    @include ipad-pro-landscape {
      width: 100% !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  // Below is to be refactored
  &__img-container {
    position: relative;
    height: 0;
    // Defines height of img container
    padding-bottom: 100%;
    overflow: hidden;

    &--16-9 {
      padding-bottom: 56.25%;
    }

    &--4-3 {
      padding-bottom: 80%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .bm-scroll-zoom-sizing {
    @include abs-center;
    height: 112%;
    transition: height 0.3s linear;
    transform: translate3d(-50%, -50%, 0);
    // Prefix required for chrome
    // stylelint-disable-next-line
    -webkit-perspective: 1000;
    perspective: 1000;
    backface-visibility: hidden;
    will-change: height;
  }
}
