// stylelint-disable selector-class-pattern

.searchbox {
  flex: 1;
  position: relative;
}

.hits {
  color-scheme: dark;
  height: 95dvh;
  overflow: auto;
  padding-bottom: map-get($bm_spacers, 13);

  @include media-breakpoint-up(lg) {
    background-color: $bm-neutral-900;
    position: absolute;
    left: 0;
    top: $header-height-large;
    width: 100%;
  }
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  appearance: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @include media-breakpoint-up(lg) {
    fill: $bm-neutral-50;
  }

  &:focus {
    outline: 2px solid $bm-neutral-50;
    outline-offset: 2px;
  }
}

.ais-SearchBox-submit {
  left: map-get($bm_spacers, 4);
}

.ais-SearchBox-reset {
  --search-bar-reset-button-size: #{map-get($bm_spacers, 4)};

  align-items: center;
  background-color: $bm-neutral-250;
  border-radius: 50% !important; // stylelint-disable-line declaration-no-important
  display: flex;
  height: var(--search-bar-reset-button-size);
  justify-content: center;
  right: map-get($bm_spacers, 4);
  width: var(--search-bar-reset-button-size);

  @include media-breakpoint-up(lg) {
    --search-bar-reset-button-size: #{map-get($bm_spacers, 6)};
  }
}

.ais-SearchBox-submit svg {
  fill: var(--search-bar-submit-icon-color);
  height: map-get($bm_spacers, 6);
  width: map-get($bm_spacers, 6);
}

.ais-SearchBox-reset svg {
  --search-bar-reset-icon-size: #{map-get($bm_spacers, 2)};
  fill: $bm-neutral-600;
  height: var(--search-bar-reset-icon-size);
  width: var(--search-bar-reset-icon-size);

  @include media-breakpoint-up(lg) {
    --search-bar-reset-icon-size: #{map-get($bm_spacers, 3)};
  }
}

.ais-SearchBox-loadingIndicator {
  left: map-get($bm_spacers, 4);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ais-SearchBox-loadingIndicator svg {
  stroke: $bm-white;
}
