.vc-info-callout {
  &::before {
    background-color: $bm-informative-50;
    border-left: 1px solid $bm-informative-50;
    border-top: 1px solid $bm-informative-50;
    content: "";
    height: map-get($bm_spacers, 3);
    left: 1px;
    position: absolute;
    top: -2px;
    transform: rotate(45deg);
    width: map-get($bm_spacers, 6);
  }
}

.vc-info-callout__icon {
  flex-shrink: 0;
}
