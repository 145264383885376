.vc-clipboard__box {
  box-shadow: inset 0 -1px 0 $bm-neutral-600;
}

.vc-clipboard__container {
  padding: map-get($bm_spacers, 4) map-get($bm_spacers, 4);

  @include media-breakpoint-up(sm) {
    padding: map-get($bm_spacers, 3) map-get($bm_spacers, 4);
  }
}
