.u-display-xxs-none {
  display: block;

  @media (max-width: 450px) {
    display: none;
  }
}

.u-display-xxs-block {
  display: none;

  @media (max-width: 450px) {
    display: block;
  }
}
