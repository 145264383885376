.bm-marketing--coming-soon {
  .bm-marketing__heading {
    line-height: 32px;

    @include media-breakpoint-up(md) {
      line-height: 40px;
    }
  }

  .bm-marketing__subtitle {
    @include media-breakpoint-up(md) {
      max-width: (535rem / 16);
    }
  }

  .bm-marketing__form {
    max-width: (535rem / 16);
  }

  .bm-form-success-message {
    margin-bottom: map-get($spacers, 5);
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    background-color: $bm-neutral-850;
  }
}

// CURRENTLY BEING REFACTORED
// Main marketing banner
.bm-marketing {
  padding-top: map-get($spacers, 5);
  background-color: $bm-grey-lighter;

  @include media-breakpoint-up(md) {
    padding: $padding-sm 0;
  }

  &__container {
    max-width: (500rem / 16);
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row-reverse;
      max-width: none;
    }
  }

  &__img {
    & > .js-bg-lazy-img {
      width: 75%;
      min-height: (300rem / 16);
      max-height: (300rem / 16);
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      @include media-breakpoint-up(md) {
        width: 100%;
        min-height: (220rem / 16);
      }

      @include media-breakpoint-up(lg) {
        min-height: (300rem / 16);
      }
    }

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 40%;
      min-height: 0;
    }
  }

  &__content {
    @include media-breakpoint-up(md) {
      width: 60%;
      padding: 1rem 0;
      margin-right: map-get($spacers, 5);
    }

    @include media-breakpoint-up(lg) {
      padding: 2rem 0;
    }
  }

  @include media-breakpoint-down(sm) {
    &__bg-image {
      background-image: none !important; // stylelint-disable-line declaration-no-important
    }
  }
}

.bm-bg-lazy {
  & > *,
  & > noscript img {
    width: 100%;
    background-position: center top;
    background-size: cover;
  }
}
