.bm-campaign-badge {
  position: absolute;
  width: 15em;
  height: 15em;
  overflow: hidden;
  font-size: (8rem / 16);
  background-color: $bm-secondary;
  background-position: center;
  border: 2px solid $bm-white;
  border-radius: 50%;
  transition: opacity 0.3s, transform 0.3s;
  transform: scale(1);

  &--hide {
    opacity: 0;
    transform: scale(0.95);
  }

  .bm-campaign-content {
    width: 80%;
    height: 70%;
    @include abs-center;

    &__subtitle {
      font-size: 1.2em;

      @include media-breakpoint-up(sm) {
        font-size: 1em;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.1em;
      }
    }

    &__heading {
      width: 119%;
      margin: 0.5em 0;
      font-size: 1.25em;
      letter-spacing: 2px;
    }

    &__description {
      font-size: 0.8em;
      line-height: 1.5;
    }
  }

  @include media-breakpoint-up(sm) {
    font-size: (10rem / 16);
  }

  @include media-breakpoint-up(md) {
    font-size: (14rem / 16);
  }

  @include media-breakpoint-up(xl) {
    width: 18vw;
    max-width: 270px;
    height: 18vw;
    max-height: 270px;
    font-size: (15rem / 16);
  }

  @include media-breakpoint-up(xxl) {
    font-size: (18rem / 16);
  }

  %bm-background-quadrant-sizing {
    width: 40%;
    max-width: none;
    height: 40%;
    padding-top: 0;
  }
}

// For mobile screens display the badge as a banner instead
.bm-campaign-badge--as-banner-sm .bm-campaign-badge {
  * {
    color: inherit;
  }

  @media only screen and (max-width: 575px) and (orientation: portrait) {
    width: 100%;
    height: auto;
    padding: 0.8rem 0.5rem;
    border-radius: 0;

    .bm-campaign-content {
      position: static;
      width: auto;
      height: auto;
      transform: none;

      .bm-heading-with-lines {
        justify-content: center;

        &__line {
          flex: none;
          width: 30px;
        }

        &__heading {
          font-size: 1.5em;
        }
      }

      &__heading {
        margin: 0.3em 0;
        font-size: 1.6em;
      }

      &__description {
        font-size: 1em;
      }
    }
  }
}

// There is separate markup for the badge on sm screens, as
// it needs to sit relative to the content. So switching visibility
// here
.bm-campaign-badge--presales {
  &-md {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    &-sm {
      display: none;
    }
    &-md {
      display: block;
    }
  }
}

.bm-campaign-badge--as-banner-sm-with-background .bm-campaign-badge {
  //background-image: url("../images/campaign/badge-as-banner.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bm-campaign-badge--with-background {
  background-image: url("../images/campaign/badge-sm.jpg");
  background-size: cover;

  @include media-breakpoint-up(md) {
    background-image: url("../images/campaign/badge-lg.jpg");
  }
}

.bm-campaign-badge-styles {
  &--bg-white,
  &--bg-black {
    background-image: none;
  }

  &--bg-white {
    background-color: $bm-white;
  }

  &--bg-black {
    background-color: $bm-black;
  }
}
