.bm-progress-section {
  display: flex;
  align-items: center;

  &__mins {
    font-size: 12px;
    color: $bm-grey-dark;
    white-space: nowrap;
  }

  & .bm-progress-bar {
    // Slight adjustment to vertically align with text
    position: relative;
    top: 1px;
    width: 100%;
  }
}
