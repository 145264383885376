// Refactored hero styling. Once no views use any styles from
// _hero.scss, it can be deleted and we can rename this file
// and it's classes to _hero.scss

// Full height minus header & an arbitrary amount for average
// mobile browser menu bars
$mobile-browser-url-spacing: (40rem / 16);

.bm-hero-new {
  position: relative;
  display: flex;
  align-items: flex-end;

  &__auto_height {
    height: auto;
  }

  > * {
    position: relative;
  }

  &__intro {
    max-width: 400px;
  }

  /* stylelint-disable selector-no-qualifying-type */
  a.bm-hero__more-courses {
    width: 100%;
    max-width: 441px;
  }
  /* stylelint-enable selector-no-qualifying-type */

  @include media-breakpoint-down(md) {
    .bm-hero-new--layout-with-gradient .bm-hero-new__content {
      margin-top: -5rem;
    }
  }

  &__background--overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &--overlay {
      height: 100%;
      background-color: $bm-hero-overlay;
    }
  }

  &__background-absolute {
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
  }

  &__stats {
    margin-top: map-get($bm_spacers, 6);
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;

    > div {
      max-width: 105px;
      padding: 0 8px;
    }

    p {
      font-size: 0.875rem;
    }

    > * + * {
      margin-left: map-get($bm_spacers, 7);
    }

    @include media-breakpoint-up(lg) {
      margin-top: map-get($bm_spacers, 7);

      > * + * {
        margin-left: map-get($bm_spacers, 11);
      }
    }
  }

  &__btn-box {
    margin-top: map-get($bm_spacers, 7);

    &--presales {
      margin-top: map-get($bm_spacers, 6);

      @include media-breakpoint-up(lg) {
        margin-top: map-get($bm_spacers, 8);
      }
    }
  }

  &__gifting {
    margin-bottom: map-get($bm_spacers, 6);
    margin-top: map-get($bm_spacers, 7);
  }

  &__content {
    max-width: 84%;
    margin: 0 auto map-get($spacers, 5);

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: (510rem / 16);
      height: 100%;
      padding: map-get($spacers, 5) 0;
      margin-bottom: 0;
      margin-left: 0;

      .bm-hero-new__content-logo {
        margin-top: auto;
      }
    }
  }

  &__content-logo {
    width: 75%;
    max-width: (255rem / 16);

    & > svg {
      // Height fix for IE stretching svg to height of parent
      max-height: (30rem / 16);
    }
  }

  &__course-meta {
    gap: map-get($bm_spacers, 2);
    width: 80%;
  }

  .bm-campaign-badge {
    top: (20rem / 16);
    right: (20rem / 16);

    @include media-breakpoint-up(md) {
      top: (20rem / 16);
      right: (20rem / 16);
    }
  }

  &__mute-toggle {
    position: absolute;
    top: 1rem;
    left: 1rem;

    @include media-breakpoint-up(lg) {
      top: auto;
      right: 1rem;
      bottom: 1rem;
      left: auto;
    }
  }
}

.bm-hero--reduced-height {
  .bm-hero__container {
    max-height: 100%;
  }

  @include media-breakpoint-up(md) {
    height: 70vh;

    .bm-hero__img-img {
      object-position: center top;
    }
  }
}

.bm-hero--with-icon-bar {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    height: 80vh;
  }

  .bm-hero__container {
    flex-grow: 1;
    width: 100%;
    min-height: (400rem / 16);
  }
}
