@mixin scrollable-columns(
  $selector-wrapper,
  $selector-item,
  $columns: 4,
  $column-width: 60%,
) {
  display: flex;

  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;

  > *,
  &::before,
  &::after {
    flex: none;
  }

  #{$selector-wrapper} {
    width: calc(#{$columns * $column-width});
  }

  #{$selector-item} {
    flex: none;
    width: 100% / $columns;
    scroll-snap-align: center;
  }
}
