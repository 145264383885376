.vc-text-testimonial {
  width: 100%;
  height: 220px;

  @include media-breakpoint-up(xs) {
    height: 248px;
  }

  @include media-breakpoint-up(sm) {
    height: 232px;
  }

  @include media-breakpoint-up(md) {
    height: 251px;
  }

  @include media-breakpoint-up(lg) {
    height: 290px;
  }

  &__content {
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;

    @include media-breakpoint-up(md) {
      padding-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &__review {
    color: $bm-neutral-100;
    font-size: $font-size-xs;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-sm;
    }
  }

  &__rating {
    &__icon {
      color: $bm-primary-400;
      width: 17%;
      height: auto;
    }
  }

  &__label {
    position: absolute;
    bottom: 16px;
    left: 12px;
    color: $bm-neutral-250;
    font-size: $font-size-xs;

    @include media-breakpoint-up(md) {
      bottom: 22px;
      left: 24px;
      font-size: $font-size-md;
    }
  }
}
