.vc-end-card {
  background-color: rgba($bm-black, 0.9);
  left: 0;
  opacity: 0;
  pointer-events: none;
  top: 0;
  transition: opacity 0.5s ease-in;
  z-index: 1;
}

.vc-end-card[data-visible="true"] {
  opacity: 1;
  pointer-events: auto;
}
