.vc-content-icon__icon {
  height: map-get($bm_spacers, 7);
  margin-right: map-get($bm_spacers, 3);
  width: map-get($bm_spacers, 7);

  @include media-breakpoint-up(lg) {
    height: map-get($bm_spacers, 8);
    margin-right: map-get($bm_spacers, 4);
    width: map-get($bm_spacers, 8);
  }
}
