.vc-accordion__button {
  appearance: none;
  background-color: $bm-neutral-800;
  border: 0;
  box-shadow: none;
  font-size: $font-size-xs;
  letter-spacing: 1.2px;
  padding: map-get($bm_spacers, 5) map-get($bm_spacers, 6);
  transition: all 0.15s ease-in-out;

  @include media-breakpoint-up(md) {
    font-size: $font-size-sm;
    padding: map-get($bm_spacers, 6);
  }

  &:hover {
    background-color: $bm-neutral-700;
  }

  &:focus {
    outline: 2px solid $bm-white;
    outline-offset: 2px;
  }
}

.vc-accordion__button[aria-expanded="true"] {
  background-color: $bm-neutral-700;
}

.vc-accordion__text {
  margin-right: map-get($bm_spacers, 4);
}

.vc-accordion__item {
  &:not(:last-child) {
    border-bottom: 1px solid $bm-neutral-900;
  }
}

.vc-accordion__answer {
  background-color: $bm-neutral-700;
  padding: map-get($bm_spacers, 2) map-get($bm_spacers, 6)
    map-get($bm_spacers, 6) map-get($bm_spacers, 6);

  a {
    color: $bm-primary;
  }
}
