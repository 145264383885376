.bm-hero {
  // 404 & REDEEM
  &__error,
  &__redeem {
    & .bm-hero__img > img,
    & .bm-hero__img > picture img {
      @include abs-center;
      // Min sizes act like bg-size: cover
      // whilst retaining aspect ratio
      width: auto;
      min-width: 100%;
      height: auto;
      min-height: 100%;
    }

    @include media-breakpoint-up(md) {
      & .bm-hero__container {
        justify-content: flex-start;
        min-height: (400rem / 16);
        padding: 0 3rem;
      }
    }
  }

  // REDEEM
  &__redeem {
    & .bm-hero__container {
      align-items: flex-end;
      min-height: none;
      margin: 0 auto;

      &.bm-hero__gifting-header {
        height: 65vh;

        @include media-breakpoint-up(xs) {
          height: 60vh;
        }

        @include media-breakpoint-up(md) {
          height: 75vh;
        }
      }
    }

    & .bm-hero__content {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        height: 100%;
        margin-bottom: 0;
      }

      @media only screen and (min-width: 1380px) {
        margin-left: 3%;
      }

      @include media-breakpoint-up(xxl) {
        margin-left: 12%;
      }

      @include media-breakpoint-down(z) {
        & h1 {
          font-size: (28rem / 16);
        }
      }

      h1 {
        width: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      & .bm-hero__subtitle {
        display: none;
      }

      & h1 {
        font-size: (30rem / 16);
      }
    }

    .bm-hero__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      & > img,
      & > picture img {
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        transform: none;

        @media only screen and (-ms-high-contrast: none),
          (-ms-high-contrast: active) {
          height: auto;
        }

        @include media-breakpoint-up(md) {
          height: 90%;
          top: 0;
        }
      }

      &--mobile {
        object-position: center top;
      }

      &--desktop {
        display: none;
      }
    }

    & .bm-redeem-link {
      margin-top: 1rem;
    }

    @media only screen and (min-width: 320px) and (orientation: landscape) {
      .bm-hero__container {
        align-items: center;
        justify-content: flex-start;
      }

      .bm-hero__img {
        &--desktop {
          display: block;
        }
        &--mobile {
          display: none;
        }
      }

      .bm-hero__content {
        margin: 0 0 0 15px;
        text-align: left;
      }
    }

    @include media-breakpoint-up(md) {
      & .bm-hero__container {
        align-items: center;
        height: calc(100vh - 60px - 85px);
        min-height: 30rem;
        max-height: 520px;
        padding: 0 3rem;
      }

      & .bm-hero__img {
        &--desktop {
          display: block;
        }
        &--mobile {
          display: none;
        }
      }

      & .bm-hero__content {
        align-items: flex-start;
        justify-content: center;
        width: 44%;
        text-align: left;
      }

      & .bm-redeem-link {
        margin-top: 3rem;
      }

      & .bm-hero__gifting-header .bm-redeem-link {
        margin-top: 4rem;
      }
    }

    @media only screen and (min-width: 992px) and (max-height: 500px) {
      & .bm-hero__container h1 {
        font-size: (40rem / 16);
      }
    }

    @include media-breakpoint-up(lg) {
      & .bm-hero__container {
        height: 100%;
        max-height: calc(100vh - 84px);
      }

      & .bm-hero__gifting-header {
        max-height: 50vh;
      }

      & .bm-hero__content {
        max-height: (560rem / 16);
      }
    }

    @include ipad-pro {
      & .bm-hero__container {
        max-height: (670rem / 16);
      }
    }

    @include media-breakpoint-up(xxl) {
      & .bm-hero__content {
        margin-left: 86px;
      }
    }
  }

  &__discount-applied {
    display: flex;
  }

  &__error {
    & .bm-hero__content {
      @include media-breakpoint-up(md) {
        max-width: 24rem;
        padding-left: 8%;
        text-align: left;
      }

      @include media-breakpoint-up(lg) {
        max-width: 33rem;
      }

      @include media-breakpoint-up(xxl) {
        padding-left: 10%;
      }
    }
  }

  &__discount-terms {
    margin-left: map-get($bm_spacers, 2);

    a {
      color: $bm-white;
      text-decoration: underline;
    }
  }

  // PRESALES & PRELAUNCH
  &__presales .bm-hero,
  &__pre-launch .bm-hero {
    &__bullets {
      justify-content: center;
      margin-bottom: map-get($spacers, 4);
    }

    &__coming-soon {
      margin-bottom: 1rem;
      font-size: $font-size-sm;
    }

    & .bm-price {
      margin-top: map-get($spacers, 4);
    }

    &__subtitle,
    &__scroll-to {
      display: none;
    }

    // ***** Landscape mobiles & xs desktops ****** \\
    @media only screen and (max-width: 744px) and (orientation: landscape),
      (min-width: 768px) and (orientation: landscape) {
      &__bullets {
        justify-content: flex-start;
        margin-bottom: auto;
      }

      &__coming-soon {
        margin-bottom: auto;
      }

      &__btn-box {
        align-items: center;
        width: 100%;
        padding-top: 1rem;
      }
    }

    @include media-breakpoint-up(md) {
      &__subtitle {
        display: block;
      }
    }

    // ***** Left & right layout for desktop ****** \\
    @media only screen and (min-width: 992px) and (orientation: landscape) {
      &__bullets {
        justify-content: flex-start;
        margin-bottom: auto;
      }

      &__course-title {
        margin-top: map-get($spacers, 3);
      }

      &__maestro {
        margin-bottom: map-get($spacers, 4);
        font-size: (48rem / 16);
      }

      & .bm-price {
        margin-top: map-get($spacers, 1);
      }

      // Adjustments for short & wide screens
      @media only screen and (min-width: 1235px) {
        &__scroll-to {
          display: block;
        }
      }

      @media only screen and (max-height: 630px) {
        &__maestro {
          font-size: (42rem / 16);
        }

        &__subtitle {
          display: none;
          font-size: (14rem / 16);
        }
      }
    }

    @include media-breakpoint-up(xl) {
      &__maestro {
        margin-bottom: 30px;
        font-size: (58rem / 16);
      }
    }

    // ***** Button box ****** \\
    // Button box is sticky for mobile, static above 575px
    &__btn-box {
      z-index: 90;
      display: flex;

      @media only screen and (min-width: 575px) and (orientation: portrait) {
        align-items: center;
        width: 100%;
        padding: 1rem 0;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        max-width: 100%;
        margin-top: map-get($spacers, 3);
      }

      & .bm-btn-box-sticky__content {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      & a {
        width: 49%;
      }
    }

    // IE fix for content children. Where the first child is using margin-bottom: auto
    // to push the rest of the content down, the content container needs it's justify property
    // to be reset
    @media only screen and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      &__content {
        justify-content: flex-start;
      }
    }
  }
}

// Constrain width of content for desktop, apart
// from maestro name. Exclude ipad-pro from this
.bm-hero__presales .bm-hero__content {
  & > *:not(.bm-hero__maestro) {
    @include media-breakpoint-up(lg) {
      max-width: 80%;
    }

    @include ipad-pro-portrait {
      max-width: none;
    }
  }
}

// Pre-launch hero page
.bm-hero__pre-launch {
  padding: 0 0 (15rem / 16);

  .bm-hero__content {
    width: auto;

    @include media-breakpoint-down(md) {
      padding: 0 (15rem / 16);
    }

    @media only screen and (min-width: 320px) and (orientation: landscape) {
      justify-content: flex-end;
    }
  }

  @media only screen and (max-width: 350px) {
    .bm-hero__course-title {
      display: none;
    }

    .bm-hero__maestro {
      font-size: 25px;
    }

    form label {
      font-size: $font-size-xs;
    }
  }

  .bm-hero__more-courses {
    display: none;
  }

  @media only screen and (max-height: 514px) {
    .bm-hero__subtitle-desktop {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    .bm-hero__more-courses {
      display: block;
      margin-top: map-get($spacers, 3);
      background: transparent;
    }
  }

  @include media-breakpoint-up(lg) {
    .bm-hero__container {
      min-height: (400rem / 16);
    }

    .bm-hero__content > *:not(.bm-hero__maestro):not(.bm-hero__more-courses) {
      max-width: 100%;
    }

    .bm-hero__more-courses {
      max-width: 27.5rem;
    }
  }

  @include ipad-pro {
    .bm-hero__container {
      height: calc(100vh - 145px);
      max-height: calc(100vh - 85px);
    }
  }
}
