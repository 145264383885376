.bm-ratio {
  position: relative;
  height: 0;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  &--1-1 {
    padding-bottom: 100%;
  }

  &--16-9 {
    padding-bottom: 56.25%;
  }
}

.bm-ratio-video--16-9 {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    padding-top: 56.25%;
    content: '';
  }
}

.bm-ratio-video--9-16 {
  aspect-ratio: 9 / 16;
  height: auto;
}

.bm-ratio-video--9-16 .vjs-tech {
  object-fit: cover;
}

.bm-ratio-video--9-16 .vjs-bm.vjs-fullscreen video {
  top: 0%;
  height: 100% !important; // stylelint-disable-line declaration-no-important
  transform: none;
}
