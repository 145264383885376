.vc-gift-cta {
  margin-top: map-get($bm_spacers, 7);

  @include media-breakpoint-up(lg) {
    margin-top: map-get($bm_spacers, 11);
  }
}

.vc-gift-cta__text {
  line-height: 1.25;
}

.vc-gift-cta__link {
  margin-top: map-get($bm_spacers, 3);

  @include media-breakpoint-up(xs) {
    margin-left: map-get($bm_spacers, 3);
  }
}
