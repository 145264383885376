.bm-tooltip {
  left: 50%;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -15px);
  transition: opacity, transform, 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  white-space: nowrap;
}

.bm-tooltip[data-success="true"] {
  opacity: 1;
  transform: translate(-50%, -120%);
}
