@import "../../fonts/WebfontsKit";
@import "./variables/typography";

// stylelint-disable
html {
  font-family: $font-family-roboto-regular;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-family: $font-family-playfair;
  color: $bm-secondary;
  letter-spacing: -0.5px;
  font-feature-settings: "liga" on;
}

h1,
.h1 {
  font-size: (32rem / 16);
  line-height: 1.4;

  @media (min-width: (992rem / 16)) {
    font-size: (58rem / 16);
    line-height: 1.14;
  }
}

h2,
.h2,
.content-page h1,
.content-page .h1 {
  font-size: (28rem / 16);
  line-height: 1.4;

  @media (min-width: (992rem / 16)) {
    font-size: (48rem / 16);
    line-height: 1.17;
  }
}

h3,
.h3,
.content-page h2,
.content-page .h2 {
  font-size: (22rem / 16);
  line-height: 1.21;

  @media (min-width: (992rem / 16)) {
    font-size: (38rem / 16);
  }
}

h4,
.h4,
.content-page h3,
.content-page .h3 {
  font-size: (26rem / 16);
  line-height: 1.29;
}

h5,
.h5,
.content-page h4,
.content-page .h4 {
  font-size: (22rem / 16);

  @media (min-width: (992rem / 16)) {
    font-size: (28rem / 16);
  }
}

.paragraph {
  font-family: $font-family-roboto-regular;
  font-size: $font-size-md;
  font-weight: 400;
  color: $bm-grey-dark;
}

.bm-price {
  font-family: $font-family-roboto-light;
  font-size: (26rem / 16);
  line-height: 1.31;
  color: $bm-grey-dark;

  &--strikethrough {
    position: relative;

    &::after {
      position: absolute;
      top: 57%;
      left: 50%;
      width: 110%;
      height: 2px;
      content: "";
      background-color: currentColor;
      transform: translate(-50%, -50%);
    }
  }
}

.bm-sub-heading {
  &,
  & > p {
    // TODO: Review why `p` has its own font-family rather than just inheriting :(
    font-family: $font-family-roboto-light;
  }
  font-size: $font-size-lrg;
  line-height: 1.4;
  color: $bm-secondary;

  @media (min-width: 992px) {
    font-size: (20rem / 16);
  }

  // Default underline is too thick so creating a custom one
  &--with-underline {
    position: relative;

    &::after {
      position: absolute;
      bottom: 1px;
      display: block;
      width: 100%;
      height: 1px;
      content: "";
      background-color: $bm-secondary;
    }

    &:hover {
      color: $bm-primary;
      text-decoration: none;

      &::after {
        background-color: $bm-primary;
      }
    }
  }

  &--sm,
  &--lg {
    &,
    & > p {
      font-family: $font-family-roboto-medium;
    }
    color: $bm-grey-dark;
    text-transform: uppercase;

    .bm-header--transparent &,
    .bm-header--neutral-800 & {
      color: $bm-white;
    }
  }

  &--sm {
    font-size: $font-size-xs;
    line-height: 1.7;
    letter-spacing: 1.2px;
  }

  &--lg {
    font-family: $font-family-roboto-regular;
    font-size: (32rem / 16);
    line-height: 1.2;
    letter-spacing: 5px;
  }
}

p,
.bm-paragraph--small {
  font-family: $font-family-roboto-regular;
  color: $bm-grey-dark;
}

.bm-paragraph {
  &--small {
    font-size: $font-size-xs;
    line-height: 1.7;
  }

  &--error {
    color: $bm-red;
  }
}

.bm-text-within-tertiary * {
  &:not(path) {
    color: $bm-tertiary;
  }
}

.bm-text-within-tertiary-darker * {
  color: $bm-bg-grey-darker;
}

.font-xxs {
  font-size: $font-size-xxs;
}
.font-xs {
  font-size: $font-size-xs !important;
}
.font-sm {
  font-size: $font-size-sm;
}
.font-md {
  font-size: $font-size-md;
}
.font-lrg {
  font-size: $font-size-lrg;
}
.font-x-lrg {
  font-size: $font-size-x-lrg;
}
.font-xx-lrg {
  font-size: $font-size-xx-lrg;
}

.or {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  &::before,
  &::after {
    content: " ";
    flex: 1;
    border-bottom: rgba($bm-grey-light, 0.5) solid 1px;
    display: block;
  }

  span {
    display: inline-block;
    padding: 0 10px;
    font-style: italic;
    background-color: inherit;
  }
}
// stylelint-enable
