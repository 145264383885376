.bm-theme-light {
  background-color: $bm-bg-white;
  color: $bm-secondary;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  span {
    color: $bm-secondary;
  }
}

.bm-theme-dark {
  background-color: $bm-bg-grey-darker;
  color: $bm-tertiary;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  span {
    color: $bm-tertiary;
  }
}
