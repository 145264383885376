.bm-pages-subtitle {
  font-size: $font-size-x-lrg;
}

.bm-pages-last-revision {
  font-size: $font-size-sm;
}

.bm-pages-box {
  padding: 30px;
  background-color: $bm-grey-lightest;

  @include media-breakpoint-up(sm) {
    padding: $padding-sm;
  }

  .bm-category {
    &__q {
      font-size: $font-size-x-lrg;

      &:not(:last-child) {
        margin-bottom: map-get($spacers, 3);
      }

      a {
        text-decoration: underline;
        @extend .bm-sub-heading;

        @include media-breakpoint-up(lg) {
          font-size: (19rem / 16);
        }
      }
    }

    &__title {
      a {
        color: $bm-grey-dark;
      }
    }
  }
}

.bm-anchor {
  position: relative;
  top: -10vh;
}

.bm-static-page {
  &__chapter {
    &:not(:last-child) {
      margin-bottom: map-get($spacers, 5);
    }

    &-item {
      a {
        @extend .link;
      }

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  &__q {
    margin-bottom: map-get($spacers, 2);
    font-size: $font-size-x-lrg;
  }

  &__a {
    margin-bottom: map-get($spacers, 4);
  }

  &__table {
    font-size: $font-size-sm;

    thead th {
      width: 33.3%;
      vertical-align: middle;
    }

    tbody li:not(:last-child) {
      margin-bottom: map-get($spacers, 1);
    }
  }
}

.bm-contact-name,
.bm-contact-detail {
  color: $bm-grey-dark;
}
