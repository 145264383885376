.vc-overflow-shadow::before,
.vc-overflow-shadow::after {
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  width: 10%;
  z-index: 2;
}

.vc-overflow-shadow--850::before {
  background-image: linear-gradient(
      270deg,
      rgba($bm-neutral-850, 0) 0%,
      rgba($bm-neutral-850, 0.8) 100%
    ),
    linear-gradient(270deg, rgba($bm-neutral-850, 0) 0%, $bm-neutral-850 100%);
}

.vc-overflow-shadow--850::after {
  background-image: linear-gradient(
      90deg,
      rgba($bm-neutral-850, 0) 0%,
      rgba($bm-neutral-850, 0.8) 100%
    ),
    linear-gradient(90deg, rgba($bm-neutral-850, 0) 0%, $bm-neutral-850 100%);
  right: 0;
}

.vc-overflow-shadow-left::before,
.vc-overflow-shadow-right::after {
  height: 100%;
  top: 0;
  width: 10%;
}

.vc-overflow-shadow-top::before,
.vc-overflow-shadow-bottom::after {
  height: 7.5rem;
  left: 0;
  width: 100%;
}

.vc-overflow-shadow-left::before,
.vc-overflow-shadow-top::before,
.vc-overflow-shadow-right::after,
.vc-overflow-shadow-bottom::after {
  opacity: 1;
  visibility: visible;
}

.vc-overflow-shadow-top::before {
  background: linear-gradient(
      0deg,
      rgba(26, 26, 26, 0) 0%,
      $bm-neutral-900 100%
    ),
    linear-gradient(0deg, rgba(26, 26, 26, 0) 0%, $bm-neutral-900 100%);
  top: 0;
}

.vc-overflow-shadow-right::after {
  background-image: linear-gradient(
      90deg,
      rgba($bm-neutral-900, 0) 0%,
      rgba($bm-neutral-900, 0.8) 100%
    ),
    linear-gradient(90deg, rgba(38, 38, 38, 0) 0%, $bm-neutral-900 100%);
  right: 0;
}

.vc-overflow-shadow-left::before {
  background-image: linear-gradient(
      270deg,
      rgba($bm-neutral-900, 0) 0%,
      rgba($bm-neutral-900, 0.8) 100%
    ),
    linear-gradient(270deg, rgba(26, 26, 26, 0) 0%, $bm-neutral-900 100%);
  left: 0;
}

.vc-overflow-shadow--850.vc-overflow-shadow-right::after {
  background-image: linear-gradient(
      90deg,
      rgba($bm-neutral-850, 0) 0%,
      rgba($bm-neutral-850, 0.8) 100%
    ),
    linear-gradient(90deg, rgba(38, 38, 38, 0) 0%, $bm-neutral-850 100%);
  right: 0;
}

.vc-overflow-shadow--850.vc-overflow-shadow-left::before {
  background-image: linear-gradient(
      270deg,
      rgba($bm-neutral-850, 0) 0%,
      rgba($bm-neutral-850, 0.8) 100%
    ),
    linear-gradient(270deg, rgba(26, 26, 26, 0) 0%, $bm-neutral-850 100%);
  left: 0;
}

.vc-overflow-shadow-bottom::after {
  background: linear-gradient(
      180deg,
      rgba(26, 26, 26, 0) 0%,
      $bm-neutral-900 100%
    ),
    linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, $bm-neutral-900 100%);
  bottom: 0;
  height: 7.5rem;
  width: 100%;
}

.vc-overflow-shadow__container {
  color-scheme: dark;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;

  &:focus-visible,
  &:focus {
    outline: 2px solid $bm-neutral-50;
    outline-offset: 2px;
  }
}

.vc-overflow-shadow--padded .vc-overflow-shadow__container {
  padding: map-get($bm_spacers, 2) 0 map-get($bm_spacers, 2)
    map-get($bm_spacers, 4);

  @include media-breakpoint-up(md) {
    padding-left: 0;
  }
}
