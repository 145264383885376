@import "~video.js/dist/video-js";

@import "generic/bootstrap";
@import "generic/custom_spacing";
@import "generic/rythm";
@import "generic/bm-columns";
@import "generic/gradients";
@import "generic/typography";
@import "generic/themes";
@import "generic/skip_link";
// @import 'generic/shadows';
@import "generic/cookiebot";

@import "../fonts/WebfontsKit";
@import "abstract/variables/typography";

* {
  -webkit-font-smoothing: antialiased;
}

:last-child {
  margin-bottom: 0;
}

html {
  overflow-x: hidden;
  font-size: 16px;
  background-color: $bm-bg-grey-light;
}

body {
  min-height: 100vh;
  font-family: $font-family-roboto-regular;
  -moz-osx-font-smoothing: grayscale;

  // Override overflow-class that bootstrap adds when opening a modal.
  // This causes the below-the-fold content to be hidden.
  // overflow:hidden is instead applied to the html element via modal js.
  // stylelint-disable-next-line selector-class-pattern, selector-no-qualifying-type
  &.modal-open {
    overflow: visible;
  }
}

main {
  min-height: calc(100vh - (200rem / 16));
}

// Bootstrap overrides here
// stylelint-disable
button,
a {
  &:active {
    outline: none !important;
  }

  &:focus {
    outline: 2px solid $bm-secondary;
    outline-offset: 3px;
    box-shadow: none;
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
}

button {
  border-radius: 0 !important;
}

ul {
  padding: 0;
  list-style: none;
}

.no-js {
  .hide-no-js {
    display: none !important;
  }
}

.bm-scroll-margin {
  scroll-margin-top: $header-height-small;

  @include media-breakpoint-up(md) {
    scroll-margin-top: $header-height-large;
  }
}

.turbolinks-progress-bar {
  background-color: $bm-primary;
}

.flashes {
  font-family: $font-family-roboto-regular;
}

.tooltip {
  font-size: $font-size-xs;
  border-radius: 0;

  & .arrow::before {
    border-top-color: $bm-primary !important;
  }

  & > .tooltip-inner {
    background-color: $bm-primary;
    border-radius: 0;
  }
}

.card {
  border: none;
  border-radius: 0;
}

.has-shadow {
  box-shadow: $bm-shadow;
}

.overflow-visible {
  overflow: visible !important;
}

.w-sm-auto {
  @include media-breakpoint-up(sm) {
    width: auto !important;
  }
}

.bm-wrap-children > * {
  display: block;
}

.bg-md-white {
  @include media-breakpoint-up(md) {
    background-color: $bm-white;
  }
}

.font-xl-32 {
  @include media-breakpoint-up(lg) {
    font-size: (32rem / 16);
  }
}

.font-xxl-38 {
  @include media-breakpoint-up(xl) {
    font-size: (38rem / 16);
  }
}

html {
  @media (pointer: coarse) {
    overflow: auto;
  }
}

.pe-none {
  pointer-events: none;
}
