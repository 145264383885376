.bm-business-hero {
  gap: map-get($bm_spacers, 6);

  @include media-breakpoint-up(lg) {
    gap: map-get($bm_spacers, 10);
  }
}

.bm-business-hero__content {
  @include media-breakpoint-up(md) {
    flex: 0 1 55%;
    padding-top: calc(map-get($bm_spacers, 11) + map-get($bm_spacers, 2));
  }
}

.bm-business-hero__wrapper {
  @include media-breakpoint-up(xl) {
    width: fit-content;
  }
}

.bm-business-hero__image {
  @include media-breakpoint-up(md) {
    flex: 0 1 45%;
  }
}

.bm-business-hero img {
  max-width: 100%;
}
