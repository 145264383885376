.bm-showreel {
  &__video {
    max-width: (945rem / 16);
    margin: 0 auto;

    & > button {
      @include abs-center;
    }
  }

  &__heading-container {
    margin-bottom: map-get($spacers, 3);

    & > * {
      text-align: center;
    }
  }

  &__maestro {
    font-size: (28rem / 16);
  }

  &__bullets {
    margin-top: map-get($spacers, 4);

    // Wrap container and remove bullets
    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    & .bm-bullets__item::before {
      display: none;
      background-color: $bm-primary;
    }

    &--title {
      justify-content: center;
      width: 100%;
      text-align: center;
    }
  }

  @include media-breakpoint-up(sm) {
    &__heading-container {
      display: flex;
      flex-direction: column;
      margin-bottom: map-get($spacers, 1);

      & > * {
        text-align: left;
      }

      & .bm-sub-heading {
        margin-right: map-get($spacers, 2);
      }
    }

    &__bullets {
      margin-top: map-get($spacers, 1);
      overflow: hidden;

      // Right align list and shift right to hide
      // overflowing bullets
      &-container {
        position: relative;
        right: -28px;
        justify-content: flex-end;
      }

      & .bm-bullets__item {
        &::before {
          display: none;
        }

        &::after {
          display: inline-block;
          width: 6px;
          height: 6px;
          margin: 0 0.6rem;
          margin-right: 0.8rem;
          content: '';
          background-color: $bm-primary;
        }
      }

      &--title {
        justify-content: center;
        width: auto;
      }
    }
  }

  // Fit content above the fold for short screens
  @media only screen and (min-width: 568px) and (max-width: 767px) and (orientation: landscape),
    (min-width: 768px) and (max-height: 900px) {
    &__video.bm-lessons-player {
      width: 100vh;
      min-width: 430px;
      max-width: 100%;
    }

    &__bullets * {
      font-size: (10rem / 16);
    }
  }

  @media only screen and (min-width: 768px) {
    @media only screen and (min-height: 430px) {
      &__heading-container {
        margin-bottom: map-get($spacers, 3);
      }

      &__bullets {
        margin-top: map-get($spacers, 3);

        & * {
          font-size: (12rem / 16);
        }

        &-container {
          right: -30px;
        }
      }

      &__maestro {
        font-size: (32rem / 16);
      }
    }

    @media only screen and (min-height: 580px) {
      &__video.bm-lessons-player {
        width: 116vh;
        //max-width: (945rem / 16);
        max-width: 100%;
      }
    }
  }
}
