html:not(.no-js) {
  @include media-breakpoint-down(xs) {
    .bm-btn-box--fixed {
      bottom: 0;
      z-index: 90;
      width: 100%;
      background-color: $bm-tertiary;
    }
  }

  @include iphone-x {
    .bm-btn-box--fixed {
      padding-bottom: 34px;
    }
  }

  .bm-lesson-overview .bm-btn-box-sticky__content {
    > a {
      @include media-breakpoint-up(sm) {
        max-width: (190rem / 16);
      }
    }
  }
}
.bm-btn-box-sticky {
  &__content {
    display: flex;

    & > a {
      flex: 1 1 auto;

      & + * {
        margin-left: 10px;
      }
    }
  }
}

.js-mobile-sticky-trigger {
  position: absolute;
  top: 110vh;
  left: 0;
}

.bm-btn-box-sticky__preorder-trigger {
  top: 0;
}
