.bm-modal {
  &__container {
    top: 1.5rem;
    left: 50%;
    display: inline-block;
    width: calc(100vw - 20px);
    max-width: (400rem / 16);
    margin: 0 auto 6rem;
    box-shadow: $bm-shadow-modal;
    transition: width 0.4s;

    @include media-breakpoint-up(lg) {
      top: 2rem;
      width: auto;
      max-width: (460rem / 16);
    }

    @include media-breakpoint-up(xl) {
      top: 3rem;
    }

    // Remove safari outline when interacting with modal form
    &:focus {
      outline: none;
    }
  }

  &__content {
    min-height: 0;
    background-color: $bm-tertiary;
    border: none;
    border-radius: 0;
  }

  &__header {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0;
    border: none;

    & .bm-modal__close {
      width: (40rem / 16);
      height: (40rem / 16);

      & > svg {
        width: 20px;
      }
    }
  }

  &__body {
    padding: 0;
    transition: width 0.4s;

    & .bm-container-devise {
      margin-top: 0;
    }

    & .bm-steps {
      margin: 0 0 map-get($spacers, 3);
    }
  }

  &--close-btn-inverted {
    .bm-modal__close {
      background-color: $bm-secondary;

      &:hover {
        background-color: $bm-secondary-darker;
      }

      & polygon {
        fill: $bm-tertiary !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }
}

// stylelint-disable selector-class-pattern
.modal.fade .modal-dialog {
  transform: translate(-50%, -5%);
}

.modal.show .modal-dialog {
  transform: translate(-50%, 0%);
}

.modal-backdrop {
  background-color: $bm-bg-modal;
  backdrop-filter: blur(1rem);
  transition: background-color 0.4s, backdrop-filter 0.4s, opacity 0.4s;

  &.show {
    opacity: 0.85;
  }
}
// stylelint-enable
