.bm-notice {
  background: $bm-primary;
  color: $bm-white;
  font-weight: 500;
  padding: 10px;
  text-align: center;

  a {
    display: inline-block;
    background: $bm-primary;
    color: $bm-white;
    padding: 5px 10px;
    border: 1px solid $bm-white;
    font-weight: 500;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: $bm-white;
      transition: background-color 0.4s;
      color: $bm-primary;
    }
  }

  &--fixed {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
