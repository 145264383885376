.bm-icon-and-text--dark {
  * {
    color: $bm-neutral-50;
    fill: $bm-neutral-50;
    stroke: $bm-neutral-50;
  }
}

.bm-icon-and-text--light {
  * {
    color: $bm-secondary;
    fill: $bm-secondary;
    // stroke: $bm-secondary;
  }

  &:not(:last-child) {
    border-bottom-color: $bm-grey-light;
  }
}
