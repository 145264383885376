// stylelint-disable selector-class-pattern

.bm-bank-card {
  font-size: $font-size-md;
  color: $bm-grey-dark;

  &__account {
    margin-top: map-get($bm_spacers, 4);

    @include media-breakpoint-up(lg) {
      margin-top: map-get($bm_spacers, 6);
    }
  }

  &__payments {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @include media-breakpoint-up(lg) {
      gap: map-get($bm_spacers, 6);
      grid-template-columns: 1fr 1fr;
    }
  }

  &__number {
    display: flex;
    align-items: center;
  }

  &__bullets {
    font-size: (8rem / 16);
  }

  &__logo {
    img {
      height: (40rem / 16);
    }
  }

  &__logos {
    gap: map-get($bm_spacers, 1);
  }

  &__update-logo {
    border: 1px solid $bm-neutral-200;
    border-radius: map-get($bm_spacers, 1);
    height: 40px;
    width: 60px;

    &--sm {
      height: 22px;
      width: 32px;
    }
  }
}

.bm-bank-card__account .vc-payment-card {
  max-width: 330px;
  width: 100%;
}
