.bm-trust {
  column-gap: map-get($bm_spacers, "half");
  display: grid;
  grid-template-columns: 1fr 1fr;

  > div:last-child {
    grid-column: span 2;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr 1fr;

    > div:last-child {
      grid-column: auto;
    }
  }
}
