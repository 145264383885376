.bm-footer {
  // LAYOUT
  padding: 2rem 0;
  background-color: $bm-secondary;

  @include media-breakpoint-up(md) {
    padding-top: 4rem;
  }

  &__left {
    max-width: (440rem / 16);

    & > p {
      margin-bottom: 1.4rem;
    }

    @include media-breakpoint-up(xl) {
      width: 35%;
    }
  }

  &__right {
    @include media-breakpoint-up(xl) {
      padding-left: 5rem;
      width: 65%;
    }
  }

  &__container {
    @include media-breakpoint-up(xl) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  // LEFT
  &__logo {
    width: (177rem / 16);
    // Constrain height to stop IE expanding image
    // within flexbox
    max-height: (20rem / 16);
  }

  &__paragraph,
  &__nav-link {
    font-size: $font-size-xs;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-sm;
    }

    @include media-breakpoint-up(md) {
      font-size: $font-size-md;
    }
  }

  // RIGHT
  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-list {
      margin-bottom: 2em;
      width: 50%;

      @include media-breakpoint-up(md) {
        width: 25%;
      }
    }

    &-heading {
      font-family: $font-family-playfair;
      font-size: $font-size-sm;
      color: $bm-tertiary;

      @include media-breakpoint-up(sm) {
        font-size: (24rem / 16);
      }

      @include media-breakpoint-up(md) {
        font-size: (28rem / 16);
      }
    }

    &-item {
      @include media-breakpoint-up(sm) {
        margin-bottom: map-get($spacers, 2);
      }
    }

    &-link {
      display: inline-block;
      overflow: hidden;
      font-family: $font-family-roboto-regular;
      color: $bm-grey-light;

      &:focus {
        outline: 2px solid $bm-tertiary;
        outline-offset: 3px;
        box-shadow: none;
      }
      // stylelint-disable-next-line
      &:focus:not(.focus-visible) {
        outline: none;
      }
    }
  }

  // BOTTOM
  &__bottom {
    max-width: (500rem / 16);
    font-family: $font-family-roboto-regular;
    font-size: $font-size-xs;

    & * {
      color: $bm-grey-lightest;
    }

    & ul {
      margin: 1.4rem 0;

      & li {
        &:not(:last-child) {
          margin-bottom: map-get($spacers, 1);
        }

        // stylelint-disable selector-max-type
        & a:focus {
          color: currentColor;
          text-decoration: underline;
        }
        // stylelint-enable
      }
    }

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: map-get($spacers, 5);

      & ul {
        display: flex;
        margin: 0;

        & li:not(:last-child) {
          margin-right: map-get($spacers, 3);
        }
      }
    }
  }

  &__nav-link,
  &__bottom li a {
    text-decoration: none;

    &:hover {
      color: $bm-grey-light;
      text-decoration: none;
    }

    &::after {
      display: block;
      width: 100%;
      height: 3px;
      content: '';
      background-color: $bm-grey-light;
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;
      transform: translate3d(0, 100%, 0);
    }

    &:hover::after {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.bm-mobile-footer {
  & a {
    width: 49%;
  }
}

.bm-sticky-footer:focus-within {
  opacity: 1;
  position: auto;
}
