.vc-poster {
  position: relative;
  display: block;
  overflow: hidden;

  &:focus-visible,
  &.focus-visible {
    outline: 2px solid $bm-neutral-50;
    outline-offset: map-get($bm_spacers, 1);
  }

  video {
    object-fit: cover;
  }

  &__copy {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: map-get($bm_spacers, 3);
    text-align: center;
    transition: transform 0.15s ease-in-out;

    @include media-breakpoint-up(lg) {
      padding: map-get($bm_spacers, 6);
    }

    a {
      color: $bm-tertiary;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__title {
    font-family: $font-family-roboto-medium;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      font-family: $font-family-playfair;
      font-size: 1.625rem;
    }
  }

  &__subtitle {
    font-family: $font-family-roboto-medium;
    color: $bm-grey-light;

    @include media-breakpoint-up(md) {
      font-family: $font-family-roboto-bold;
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }
  }

  &__cta {
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%) translateY(20px);
    transition: transform 0.14s, opacity 0.2s linear;
    z-index: 10;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__meta {
    letter-spacing: 1.2px;
  }

  &__link {
    clip: rect(0, 0, 0, 0);
    display: inherit;
    height: 0;
    width: 0;

    &:hover ~ .vc-poster__background {
      transform: scale(1.1);
    }
  }

  &__pill svg {
    display: none;

    @include media-breakpoint-up(sm) {
      display: inline-block;
    }
  }

  &__pill p {
    margin-inline-start: 0;

    @include media-breakpoint-up(sm) {
      letter-spacing: 1.2px;
      margin-inline-start: map-get($bm-spacers, 2);
      text-transform: uppercase;
    }
  }

  &__background {
    height: 248px;
    transition: transform 0.5s ease-in-out;

    picture {
      width: 100%;
      height: 100%;
      display: flex;

      img {
        aspect-ratio: 3 / 4;
        object-fit: cover;
        height: auto;
        width: 100%;
      }
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 56.45%,
          rgba(34, 34, 34, 0.8) 79.57%
        ),
        linear-gradient(180deg, rgba(98, 98, 98, 0) 45.7%, $bm-grey-darker 100%);
    }

    @include media-breakpoint-up(md) {
      height: 480px;
    }
  }

  &--hover {
    @media (hover: hover) {
      &:hover,
      &:focus,
      &:active {
        .vc-poster__copy {
          transform: translateY(-30px);
        }

        .vc-poster__copy--with-cta {
          transform: translateY(-70px);

          .vc-poster__cta {
            opacity: 1;
          }
        }
      }
    }
  }

  &--small {
    .vc-poster__background {
      @include media-breakpoint-up(md) {
        height: inherit;
      }
    }
  }

  &--medium {
    .vc-poster__background {
      @include media-breakpoint-up(lg) {
        height: 315px;
      }

      @include media-breakpoint-up(xl) {
        height: 391px;
      }
    }
  }
}
