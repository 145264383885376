.bm-social-btn-box__container {
  .bm-social-icon:not(:last-child) {
    margin-right: map-get($spacers, 4);
  }

  & button {
    box-sizing: border-box;
    min-width: (150rem / 16);
    padding: calc(1rem - 2px) calc(2rem - 2px);
    font-family: $font-family-roboto-medium;
    font-size: $font-size-xs;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    white-space: nowrap;
    background-color: $bm-tertiary;
    border: 2px solid $bm-secondary;
    transition: background-color 0.4s, box-shadow 0.4s;
    transition: transform 0.4s, background-color 0.4s, box-shadow 0.4s;
    transform: translateY(0);
    appearance: none;

    &:hover {
      box-shadow: $bm-shadow;
      transform: translateY(-0.25rem);
    }

    &:focus {
      outline: 2px solid $bm-secondary;
    }
    &:active {
      color: $bm-secondary;
      box-shadow: $bm-shadow-dark !important; /* stylelint-disable-line declaration-no-important */
    }

    &:focus:not(:focus-visible) {
      outline: none;
      box-shadow: $bm-shadow-dark;
    }
  }
}

.bm-social-btn-box__title {
  letter-spacing: 0.075rem;
}

.bm-social-btn-box--preorder {
  .bm-social-icon {
    width: (40rem / 16);
    height: (40rem / 16);
    border: none;

    &:not(:last-child) {
      margin-right: map-get($spacers, 5);
    }

    @include media-breakpoint-up(lg) {
      width: (30rem / 16);
      height: (30rem / 16);

      &:not(:last-child) {
        margin-right: map-get($spacers, 4);
      }
    }

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  button {
    display: flex;
    align-items: center;
    padding: 0;
    background: transparent;
    border: none;

    &:hover {
      box-shadow: none;
      transform: none;
    }

    &::before {
      width: (20rem / 16);
      height: (20rem / 16);
      margin-right: map-get($spacers, 2);
      content: "";
      background-image: url("../images/icons/share.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}
