// stylelint-disable selector-class-pattern

.bm-lesson {
  border-bottom: 1px solid $bm-neutral-800;
  padding-top: map-get($bm_spacers, 7);
  padding-bottom: map-get($bm_spacers, 7);

  &__description {
    @include media-breakpoint-up(lg) {
      grid-column: span 2;
    }
  }

  &__container {
    @include media-breakpoint-up(xl) {
      display: grid;
      grid-gap: 0 map-get($bm_spacers, 6);
      grid-template-columns: 1fr 28.5rem;
      grid-template-rows: min-content 1fr;
    }
  }

  &__title {
    line-height: 1.5rem;
  }

  &__grid {
    display: grid;
    grid-gap: map-get($bm_spacers, 4);

    @include media-breakpoint-up(xl) {
      grid-gap: map-get($bm_spacers, 5);
      grid-template-rows: 1fr max-content;
    }
  }

  &__actions {
    gap: map-get($bm_spacers, 4) 0;

    @include media-breakpoint-up(md) {
      grid-column: 2;
      grid-row: 1;
    }
  }

  &__tabs {
    margin-left: -(map-get($bm_spacers, 4));
    margin-right: -(map-get($bm_spacers, 4));
    margin-top: map-get($bm_spacers, 2);

    @include media-breakpoint-up(xl) {
      grid-column: 2;
      grid-row: 2;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__player {
    margin: 0 -1rem;

    @include media-breakpoint-up(xl) {
      grid-column: 1;
      grid-row: 1 / 3;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__button {
    margin: 0 map-get($bm_spacers, 4);
  }

  &__stats {
    @include media-breakpoint-up(xl) {
      grid-column: 2;
    }
  }

  @include media-breakpoint-up(xl) {
    padding-top: calc(map-get($bm_spacers, 12));
    padding-bottom: map-get($bm_spacers, 8);
  }
}

// -------------------------------- //
// Start Reviews IO override styles //
// -------------------------------- //
// The following styles are responsible for overriding certain styles of the
// ReviewsIO carousel when present in the lessons#show sidebar. The styles below
// are deliberately specific due to us needing to tweak a small set of properties
// in order to fit this specific use case. These styles don't align with our normal
// approach to writing CSS therefore the following should be treated as an exception
// in order to get things looking the way we want for this specific use case.

.bm-lesson__tabs
  .CarouselWidget-prefix
  .CarouselWidget
  .CarouselWidget__header {
  display: none;
}

.bm-lesson__tabs .container.bm-container-lg {
  padding: 0;
}

.bm-lesson__tabs .CarouselWidget-prefix .CarouselWidget .R-ReviewsList {
  padding-top: 0;
}

.bm-lesson__tabs
  .CarouselWidget-prefix
  .CarouselWidget
  .R-ReviewsList-container
  .R-ReviewsList__controlButton--left {
  left: 0;
  width: auto;
}

.bm-lesson__tabs
  .CarouselWidget-prefix
  .CarouselWidget
  .R-ReviewsList-container
  .R-ReviewsList__controlButton--right {
  right: 0;
  width: auto;
}

.bm-lesson__tabs .CarouselWidget-prefix .R-MD .R-ReviewsList-container {
  padding-left: 20px;
  padding-right: 20px;
}

.bm-lesson__tabs .CarouselWidget-prefix .CarouselWidget .CarouselWidget__list {
  width: 100%;
}

// ------------------------------ //
// End Reviews IO override styles //
// ------------------------------ //

.bm-lesson__stats:has(.bm-survey:not(.d-none))
  ~ .bm-lesson__tabs
  .vc-tabs
  .vc-overflow-shadow__container {
  @include media-breakpoint-up(xl) {
    height: 17rem;
  }
}
