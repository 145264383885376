.vc-stepper__container {
  margin-top: map-get($bm_spacers, 3);
  max-width: calc(map-get($bm_spacers, 10) * var(--total-steps));
}

.vc-stepper__step {
  height: map-get($bm_spacers, "half");
}

.vc-stepper__size {
  height: map-get($bm_spacers, 6);
  width: map-get($bm_spacers, 6);
}
