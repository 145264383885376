.vc-toggle {
  --toggle-height: 32px;
  --toggle-width: 56px;
  --toggle-on-color: #{$bm-success-500};
  --toggle-off-color: #{$bm-danger-600};
}

.vc-toggle__input {
  height: var(--toggle-height);
  position: absolute;
  opacity: 0;
  right: 0;
  width: var(--toggle-width);
}

.vc-toggle__state {
  background-color: transparent;
  border: 2px solid $bm-danger-600;
  border-radius: 2rem;
  color: $bm-white;
  height: var(--toggle-height);
  width: var(--toggle-width);

  &::after {
    background-color: var(--toggle-off-color);
    border-radius: 40px;
    content: "";
    height: 1.5rem;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: all 0.15s ease-in-out;
    width: 1.5rem;
  }
}

.vc-toggle__input:checked ~ .vc-toggle__state {
  background-color: var(--toggle-on-color);
  border-color: transparent;
  color: var(--toggle-on-color);

  .vc-toggle__state__on {
    opacity: 1;
  }

  &::after {
    background-color: $bm-white;
    transform: translateX(100%);
  }
}

.vc-toggle__state__off {
  left: 6px;
}

.vc-toggle__state__on {
  opacity: 0;
  right: 6px;
}

.vc-toggle__state__on,
.vc-toggle__state__off {
  height: 1rem;
  top: 6px;
  width: 1rem;
  z-index: 1;
}

/* Focus states */

.vc-toggle__input:focus ~ .vc-toggle__state {
  outline: 2px solid var(--toggle-off-color);
  outline-offset: 1px;
}

.vc-toggle__input:checked:focus ~ .vc-toggle__state {
  outline: 2px solid var(--toggle-on-color);
  outline-offset: 1px;
}

/* End Focus states */
