.bm-maestro-card-link {
  @include media-breakpoint-up(lg) {
    &:hover .bm-maestro-card,
    &:focus .bm-maestro-card {
      box-shadow: $bm-shadow-card;
      transform: scale(1.05) translate3d(0, 0, 0);
    }

    &:focus .bm-maestro-card {
      outline: $focus-outline;
      outline-offset: (4rem / 16);
    }
    &:focus:not(.focus-visible) .bm-maestro-card {
      outline: none;
    }
  }
}

.bm-maestro-card {
  box-shadow: $bm-shadow-dark;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &__content {
    font-size: 1rem;

    * {
      color: $bm-white;
    }
  }

  &__course-title,
  &__description {
    font-size: (12rem / 16);
  }

  &__description {
    @include media-breakpoint-up(sm) {
      font-size: (10rem / 16);
    }

    @include media-breakpoint-up(md) {
      font-size: (12rem / 16);
    }
  }

  @include media-breakpoint-up(lg) {
    &__maestro {
      font-size: (32rem / 16);
    }

    &__description,
    &__course-title {
      font-size: (14rem / 16);
    }
  }
}

// **** Small variation **** //
.bm-maestri-card-sm {
  max-width: (700rem / 16);
  height: (140rem / 16);
  padding: 0.5rem 1rem;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    max-width: (440rem / 16);
  }

  @include media-breakpoint-up(lg) {
    height: (200rem / 16);
    padding: 1.5rem 2rem;
  }

  & * {
    color: $bm-tertiary;
  }

  &__link:hover {
    text-decoration: none;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    picture {
      width: 100%;
      height: 100%;
      display: flex;

      img {
        object-fit: cover;
        height: auto;
        width: 100%;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    max-width: 65%;
    height: 100%;

    // Give the maestro name as much room as possible
    h3 {
      @include media-breakpoint-down(xs) {
        font-size: (22rem / 16);
      }

      @include media-breakpoint-up(md) {
        font-size: (24rem / 16);
      }

      @include media-breakpoint-up(lg) {
        font-size: (28rem / 16);
      }
    }

    p {
      margin-bottom: 0;
      font-size: $font-size-sm;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-md;
      }

      @media only screen and (max-width: (849rem / 16)) {
        display: none;
      }
    }
  }

  &__collapse {
    position: absolute;
    bottom: 0;
    left: 0;

    @include media-breakpoint-up(lg) {
      max-height: 0;
    }
  }
}

/* stylelint-disable no-duplicate-selectors */
.bm-maestri-card-sm__collapse {
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
  transform: translate3d(0, 0, 0);
  will-change: height;
}
/* stylelint-enable no-duplicate-selectors */

// **** Animations **** //
.bm-maestri-card-sm__link:not(.bm-maestri-card-sm--disabled) {
  display: block;
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  transform: translate3d(0, 0, 0);

  &:hover,
  &:focus {
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      z-index: 1;
      box-shadow: $bm-shadow-card;
      transform: scale(1.05) translate3d(0, 0, 0);

      .bm-maestri-card__collapse {
        max-height: 70px;
      }

      .bm-maestri-card__link::after {
        @include has-underline-slide-trigger;
      }
    }
  }
}

.bm-maestri-card-sm__link:not(.bm-maestri-card-sm--disabled) .bm-maestri-card-sm:hover, /* stylelint-disable-line prettier/prettier */
.bm-maestri-card-sm__link:focus {
  outline-color: $bm-primary;

  & .bm-maestri-card-sm__collapse {
    max-height: (20rem / 16);
  }
}
