.vc-card {
  position: relative;
  display: block;
  overflow: hidden;

  &:focus-visible,
  &.focus-visible {
    outline: 2px solid $bm-neutral-50;
    outline-offset: map-get($bm_spacers, 1);
  }
}

.vc-card__background {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(
        180deg,
        rgba($bm-black, 0) 56.45%,
        rgba($bm-grey-darker, 0.8) 79.57%
      ),
      linear-gradient(180deg, rgba(98, 98, 98, 0) 45.7%, $bm-grey-darker 100%);
  }
}

.vc-card__background img {
  aspect-ratio: 16 / 9;
  display: flex;
  height: auto;
  object-fit: cover;
  width: 100%;
}

.vc-card__copy {
  padding-top: map-get($bm_spacers, 4);
  position: relative;
}

.vc-card__copy--text-on-top {
  bottom: map-get($bm_spacers, 3);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.vc-card__title {
  font-size: 1.125rem;

  @include media-breakpoint-up(md) {
    font-family: $font-family-roboto-medium;
    font-weight: 500;
  }
}

.vc-card__subtitle {
  font-size: 0.75rem;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}

.vc-card__overlay::after {
  background-color: rgba($bm-neutral-800, 0.6);
  content: "";
  display: block;
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
  z-index: 20;
}

.vc-card__icon {
  height: map-get($bm_spacers, 8);
  position: absolute;
  right: map-get($bm_spacers, 4);
  top: map-get($bm_spacers, 4);
  width: map-get($bm_spacers, 8);
  z-index: 30;

  @include media-breakpoint-up(lg) {
    height: map-get($bm_spacers, 10);
    right: map-get($bm_spacers, 6);
    top: map-get($bm_spacers, 6);
    width: map-get($bm_spacers, 10);
  }
}
