.bm-lessons-list {
  &__group {
    transform: translate3d(0, 0, 0);

    &--hidden {
      overflow: hidden;
      visibility: hidden;
    }
  }

  &__btn {
    display: block;
    padding-right: map-get($spacers, 5);
    padding-left: map-get($spacers, 5);
    margin: 0 auto map-get($spacers, 5);
  }
}

.bm-lessons-list--presales {
  height: 25rem;

  @include media-breakpoint-up(md) {
    height: 30rem;
  }
}

.bm-lessons-list--presales .vc-overflow-shadow__container {
  padding-right: map-get($bm_spacers, 2);
  scrollbar-color: $bm-neutral-400 $bm-neutral-850;

  @include media-breakpoint-up(md) {
    padding-right: 0;
  }
}

.bm-lesson-list__item {
  @include media-breakpoint-up(md) {
    background-color: $bm-neutral-850 !important; // stylelint-disable
  }
}

.bm-postsales-lesson-item .bm-accordion__answer {
  padding-left: (75rem / 16);
}
