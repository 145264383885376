.vc-carousel-wrapper {
  margin-left: 0.9375rem;
  overflow: auto;
  padding-bottom: map-get($bm_spacers, 2);
  scrollbar-width: thin;

  @include media-breakpoint-up(md) {
    margin-left: 0;
    overflow: initial;
  }
}

.vc-carousel {

  &--pages-1 {
    --vc-carousel-items: 1;
  }

  &--pages-2 {
    --vc-carousel-items: 1;

    @include media-breakpoint-up(lg) {
      --vc-carousel-items: 2;
    }
  }

  &--pages-3 {
    --vc-carousel-items: 1;

    @include media-breakpoint-up(xxs) {
      --vc-carousel-items: 2;
    }

    @include media-breakpoint-up(lg) {
      --vc-carousel-items: 3;
    }
  }

  &--pages-4 {
    --vc-carousel-items: 2;

    @include media-breakpoint-up(md) {
      --vc-carousel-items: 3;
    }

    @include media-breakpoint-up(lg) {
      --vc-carousel-items: 4;
    }
  }

  &--pages-5 {
    --vc-carousel-items: 2;

    @media (min-width: 492px) {
      --vc-carousel-items: 3;
    }

    @include media-breakpoint-up(lg) {
      --vc-carousel-items: 4;
    }

    @include media-breakpoint-up(xl) {
      --vc-carousel-items: 5;
    }
  }

  @include media-breakpoint-down(md) {
    width: 95%;
  }

  &--centered.vc-carousel--disabled .vc-carousel__list {
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
  }

  &--cards {
    &.vc-carousel--pages-4 {
      --vc-carousel-items: 1;

      @include media-breakpoint-up(sm) {
        --vc-carousel-items: 2;
      }

      @include media-breakpoint-up(md) {
        --vc-carousel-items: 3;
      }

      @include media-breakpoint-up(lg) {
        --vc-carousel-items: 4;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 70%;
    }
  }

  &--default,
  &--video-tiles {
    &.vc-carousel--pages-4 {
      --vc-carousel-items: 1;

      @include media-breakpoint-up(sm) {
        --vc-carousel-items: 2;
      }

      @include media-breakpoint-up(md) {
        --vc-carousel-items: 3;
      }

      @include media-breakpoint-up(xl) {
        --vc-carousel-items: 4;
      }
    }

    .vc-carousel__list__item {
      @include media-breakpoint-down(xs) {
        width: calc(100% / var(--vc-carousel-items) - 25%);
      }
    }
  }

  &:hover {
    --vc-carousel-controls-opacity: 1;
  }

  &__list {
    display: flex;
    flex-flow: row nowrap;
    transition: transform 0.5s ease-in-out;

    @include media-breakpoint-down(md) {
      &::after {
        content: "";
        flex: 0 0 0.375rem;
      }
    }

    &--dragging {
      transition: none;
    }

    &__item {
      flex: 0 0 auto;
      margin: 0 map-get($bm_spacers, 2) 0 0;
      width: calc(100% / var(--vc-carousel-items) + 0.5rem);
      filter: blur(1.5px);
      opacity: 0.3;
      transition: opacity 0.5s ease-in-out;

      .vc-carousel__list--dragging & {
        filter: none;
        opacity: 1;
      }

      &--active {
        filter: blur(0);
        opacity: 1;
      }

      @include media-breakpoint-up(xs) {
        width: calc(100% / var(--vc-carousel-items) - 0.25rem);
      }

      @include media-breakpoint-up(md) {
        margin: 0 map-get($bm_spacers, 6) 0 0;
        width: calc(100% / var(--vc-carousel-items) - 1rem);
      }

      @include media-breakpoint-up(lg) {
        width: calc(100% / var(--vc-carousel-items) - 1.1rem);
      }

      @include media-breakpoint-up(xl) {
        margin: 0 map-get($bm_spacers, 6) 0 0;
      }

      @media screen and (max-width: 768px) {
        filter: none;
        opacity: 1;
      }

      &__stacked {
        &--bottom {
          margin: map-get($bm_spacers, 2) 0 0 0;

          @include media-breakpoint-up(md) {
            margin: map-get($bm_spacers, 4) 0 0 0;
          }
        }
      }
    }
  }

  &__controls {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline-block;

      .vc-carousel--disabled & {
        display: none;
      }

      button {
        position: absolute;
        z-index: 99;
        top: 50%;
        transition: opacity 0.2s ease-in-out;
        opacity: var(--vc-carousel-controls-opacity, 0);

        &:disabled {
          display: none;
        }

        @media (pointer: coarse) {
          opacity: 1;
          display: none;
        }
      }

      &--previous {
        left: 2.5%;
      }

      &--next {
        right: 2.5%;
      }
    }
  }

  .vc-carousel__list__item--active {
    .vc-poster__background:hover {
      transform: scale(1.1);
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 71rem;
    padding: 0 3.5rem;
  }

  @include media-breakpoint-up(lg) {
    margin-left: auto;
    margin-right: auto;
    max-width: 80.5rem;
    padding: 0 4.75rem;
  }
}

.vc-carousel__header {
  --carousel-icon-fill-color: #{$bm-neutral-50};
  --carousel-icon-size: #{map-get($bm_spacers, 7)};

  @include media-breakpoint-down(md) {
    width: 95%;
  }

  @include media-breakpoint-up(lg) {
    --carousel-icon-size: #{map-get($bm_spacers, 9)};
  }
}

.vc-carousel__icon {
  height: var(--carousel-icon-size);
  fill: var(--carousel-icon-fill-color);
  width: var(--carousel-icon-size);
}
