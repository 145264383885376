.bm-lessons-slider {
  background-color: $bm-grey-lighter;
  padding-top: map-get($bm_spacers, 6);

  // ***** LAYOUT ****** \\
  &__container {
    max-width: (550rem / 16);
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      display: flex;
      max-width: none;
    }

    &:hover .bm-lessons-slider__carousel-button {
      opacity: 1 !important; // stylelint-disable-line declaration-no-important
    }
  }

  &__title-and-btn {
    @include media-breakpoint-up(md) {
      min-width: (128rem / 16);
      margin-right: (40rem / 16);
    }

    @include media-breakpoint-up(lg) {
      margin-right: (80rem / 16);
    }
  }

  &__title {
    font-size: $font-size-lrg;

    @include media-breakpoint-up(lg) {
      font-size: 28px;
    }
  }

  &__content {
    width: 100%;
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
      background: transparent;
    }

    @include media-breakpoint-down(md) {
      margin-top: map-get($spacers, 4);
      overflow-y: hidden;
    }

    @include media-breakpoint-up(md) {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      scroll-snap-type: x mandatory;
    }

    &:focus-within
      ~ .bm-lessons-slider__controls
      .bm-lessons-slider__carousel-button {
      opacity: 1 !important; // stylelint-disable-line declaration-no-important
    }
  }

  // ***** LIST ****** \\
  &__list {
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: map-get($spacers, 4);
    }

    @include media-breakpoint-up(md) {
      width: 16%;
      min-width: 16%;
      scroll-snap-align: start;
      margin-right: (20rem / 16);
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      width: 11.8%;
      min-width: 11.8%;
      margin-right: (20rem / 16);
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      flex-wrap: wrap;
    }

    &:hover {
      color: $bm-grey-dark;
    }
  }

  // ***** LIST CONTENT ****** \\
  &__img {
    @include media-breakpoint-up(md) {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      overflow: hidden;

      // stylelint-disable-next-line
      .bm-lessons-slider__img--faded {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0);
        opacity: 0.2;
      }

      & img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }
  }

  &__btn {
    width: (40rem / 16);
    height: (40rem / 16);
    margin-right: map-get($spacers, 3);

    & > svg {
      width: 15px;
      height: 15px;
    }

    @include media-breakpoint-up(md) {
      order: 1;
      width: (20rem / 16);
      height: (20rem / 16);
      margin-right: map-get($spacers, 2);
      @include flex-center;

      & > svg {
        width: 7px;
        height: 6px;
      }
    }
  }

  &__carousel-button {
    opacity: 0 !important; // stylelint-disable-line declaration-no-important
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.2s ease-in-out;

    &:focus {
      opacity: 1 !important; // stylelint-disable-line declaration-no-important
    }
  }

  &--previous {
    left: 0;
  }

  &--next {
    right: 0;
  }

  &__lesson-num,
  &__lesson-title {
    font-family: $font-family-roboto-medium;
    color: $bm-grey-dark;
  }

  &__lesson-num {
    @include media-breakpoint-up(md) {
      order: 2;
      width: 100%;
      margin-bottom: map-get($spacers, 1);
    }
  }

  &__lesson-title {
    max-width: calc(100% - 56px);
    @include text-ellipsis;

    @include media-breakpoint-up(md) {
      order: 3;
      max-width: 100%;
      font-size: $font-size-xs;
    }
  }

  .bm-lessons-slider-btn-hidden > svg {
    width: (40rem / 16);
    height: (40rem / 16);
  }

  .bm-progress-bar {
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(100% - 56px);
    height: 2px;

    &::before,
    &__bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
    }

    &::before {
      display: block;
      width: 100%;
      content: '';
      background-color: $bm-grey-light;
    }

    &__bar {
      width: 0;
      background-color: $bm-primary;
      transition: width 1s;
    }

    @include media-breakpoint-up(md) {
      position: relative;
      order: 2;
      width: 100%;
      max-width: calc(100% - (28rem / 16));
      margin: map-get($spacers, 3) 0;
    }
  }

  &--with-document {
    .bm-lessons-slider__title-and-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .bm-lessons-slider__document {
      // Align with the bottom of the cards,
      // which are out of the flow
      @include media-breakpoint-up(md) {
        margin-top: 2rem;
      }

      @include media-breakpoint-up(lg) {
        margin-top: (52rem / 16);
      }
    }
  }
}

.bm-lessons-slider--lg {
  position: relative;
  max-width: (1300rem / 16);
  padding: 0 (64rem / 16);
  margin: 0 auto;

  .bm-lessons-slider__container {
    position: relative;
    z-index: 2;
  }

  .bm-lessons-slider__title-and-btn {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
  }

  .bm-lessons-slider__item {
    flex-shrink: 0;
    width: calc(33% - #{map-get($spacers, 2)});
    padding: 0 map-get($spacers, 2);
    margin-right: map-get($spacers, 3);
    margin-left: 0;
  }
}

// *** NO JS *** \\
.no-js {
  @include media-breakpoint-up(md) {
    .bm-lessons-slider__container {
      flex-direction: column;
      padding-right: 8%;
    }

    .bm-lessons-slider__content {
      scrollbar-width: auto;
      -ms-overflow-style: auto;
      padding-bottom: map-get($spacers, 4);
      scrollbar-color: auto;

      &::-webkit-scrollbar {
        display: block;
        height: 6px;

        &-thumb {
          background: $bm-primary;
          border-radius: 10px;
        }

        &-track-piece {
          background-color: $bm-grey-light;
        }
      }
    }

    .bm-lessons-slider--lg {
      .bm-lessons-slider__container {
        padding-right: 0;
      }

      .bm-lessons-slider__content {
        padding-bottom: 0;
        margin-bottom: map-get($spacers, 3);
      }

      .bm-lessons-slider__list {
        padding-bottom: map-get($spacers, 4) !important; /* stylelint-disable-line declaration-no-important, prettier/prettier */
      }
    }
  }
}
