// Some common column behavious across breakpoints
// in order of (z)-sm-md-lg

.bm-cols-1-2-2-2 {
  @extend .row;
  .bm-col {
    @extend .col-12;
    @extend .col-sm-6;

    &:nth-child(1) {
      @extend .mt-0;
    }

    &:nth-child(2) {
      @extend .mt-sm-0;
    }
  }
}

.bm-cols-1-1-2-2 {
  @extend .row;
  .bm-col {
    @extend .col-12;
    @extend .col-md-6;

    &:nth-child(1) {
      @extend .mt-0;
    }

    &:nth-child(2) {
      @extend .mt-md-0;
    }
  }
}

.bm-cols-1-1-2 {
  @extend .row;
  .bm-col {
    @extend .col-12;
    @extend .col-lg-6;

    &:nth-child(1) {
      @extend .mt-0;
    }

    &:nth-child(2) {
      @extend .mt-lg-0;
    }
  }
}

.bm-cols-1-3-3 {
  @extend .row;
  .bm-col {
    @extend .col-12;
    @extend .col-sm-4;

    &:nth-child(1) {
      @extend .mt-0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      @extend .mt-sm-0;
    }
  }
}

.bm-cols-1-2-3 {
  @extend .row;
  .bm-col {
    @extend .col-12;
    @extend .col-sm-6;
    @extend .col-md-4;

    &:nth-child(1) {
      @extend .mt-0;
    }

    &:nth-child(2) {
      @extend .mt-sm-0;
    }
    &:nth-child(3) {
      @extend .mt-md-0;
    }
  }
}

.bm-cols-1-3-3,
.bm-cols-1-2-2-2 {
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: (30rem / 16);
  }
}

.bm-col {
  @include media-breakpoint-up(sm) {
    // stylelint-disable-next-line
    margin-top: 30px !important;
  }
  @extend .mt-3;
}

.bm-col--vertical-gutter-lg {
  @extend .mt-4;
}

.bm-cols--gutter-sm {
  margin-right: -5px;
  margin-left: -5px;
  > .bm-col {
    @extend .mt-0;
    padding-right: 5px;
    padding-bottom: 10px;
    padding-left: 5px;
    margin-bottom: 0;
  }
}
