.vc-video-testimonial {
  overflow: hidden;
  width: 100%;
  height: 220px;

  @include media-breakpoint-up(xs) {
    height: 248px;
  }

  @include media-breakpoint-up(sm) {
    height: 232px;
  }

  @include media-breakpoint-up(md) {
    height: 251px;
  }

  @include media-breakpoint-up(lg) {
    height: 290px;
  }

  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;

    &__icon {
      opacity: 0.8;
      background-color: $bm-primary;
      padding: 5px 3px 5px 4px; // SVG itself does not have uniform padding
    }
  }

  &__label {
    position: absolute;
    bottom: 16px;
    left: 12px;
    color: $bm-neutral-250;
    font-size: $font-size-xs;
    z-index: 3;

    @include media-breakpoint-up(md) {
      bottom: 22px;
      left: 24px;
      font-size: $font-size-md;
    }
  }

  video {
    position: absolute;
    z-index: 1;
    aspect-ratio: 3 / 4;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    content: "";
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.0001) 56.45%,
        rgba(34, 34, 34, 0.8) 79.57%
      ),
      linear-gradient(
        180deg,
        rgba(98, 98, 98, 0.0001) 45.7%,
        $bm-grey-darker 106.45%
      );
  }
}
