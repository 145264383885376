// stylelint-disable-next-line selector-class-pattern
html:not(.no-js) {
  & *[class*='bm-lazy--'] {
    transition: opacity 0.6s;
  }

  & .bm-lazy {
    &-fade-in {
      opacity: 0;
    }

    &--loaded {
      opacity: 1;
    }
  }
}
