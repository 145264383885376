.bm-section-with-share-sidebar {
  position: relative;
}

.bm-share-sidebar {
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 100px;
    left: 0;
    z-index: 2;
    width: (75rem / 16);
    height: 0;
  }
}
