.bm-landing-grid {
  gap: map-get($bm_spacers, 10);
  grid-template-columns: minmax(0, 1fr);
  display: grid;

  @include media-breakpoint-up(md) {
    gap: map-get($bm_spacers, 4);
    grid-template-columns: 1fr 1fr;
  }
}
