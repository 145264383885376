.vc-product-placard {
  padding: map-get($bm_spacers, 6);

  @include media-breakpoint-up(lg) {
    padding: map-get($bm_spacers, 7);
  }

  li:not(:first-child) {
    margin-top: map-get($bm_spacers, 3);
  }

  a,
  button {
    margin-top: map-get($bm_spacers, 7);
  }

  /* stylelint-disable selector-class-pattern */
  a:not(.btn) {
    color: $bm-primary-400;
  }

  &__title {
    font-feature-settings: "lnum";
  }

  &__price {
    font-family: "Roboto Medium", sans-serif;
    font-size: 1.125rem;
  }

  &__breakdown {
    font-family: "Roboto Light", sans-serif;
    font-weight: 300;
  }

  &__hr {
    border: 0;
    height: 1px;
    width: 40px;
  }
}

.vc-product-placard__breakdown span {
  font-family: "Roboto Medium", sans-serif;
  font-weight: 500;
}
