/*
  Token based spacers
  This will be the basis for spacing in the future based on the units used in Figma which are defined
  in the "measurement" section of the tokens file.
*/

$bm_spacer: 1rem;

$bm_spacers: (
  0: 0,
  "half": $bm_spacer * 0.125,
  1: $bm_spacer * 0.25,
  2: $bm_spacer * 0.5,
  3: $bm_spacer * 0.75,
  4: $bm_spacer,
  5: $bm_spacer * 1.25,
  6: $bm_spacer * 1.5,
  7: $bm_spacer * 2,
  8: $bm_spacer * 2.5,
  9: $bm_spacer * 3,
  10: $bm_spacer * 3.25,
  11: $bm_spacer * 4,
  12: $bm_spacer * 5,
  13: $bm_spacer * 7.5,
);
