.bm-bullet-primary,
.bm-bullet-light {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 0.6rem;
  margin-right: 0.8rem;
}
.bm-bullet-primary {
  background-color: $bm-primary;
}
.bm-bullet-light {
  background-color: $bm-tertiary;
}

.bm-bullets-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

// A container to hide trailing bullet separators
// when the list wrap onto several lines
.bm-bullets {
  overflow: hidden;

  &__container {
    margin-left: -28px;
  }

  &__item {
    display: inline-flex;
    align-items: center;

    // stylelint-disable-next-line
    &:not(.bm-showreel__bullets--title) {
      white-space: nowrap;
    }

    &::before {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: 0 0.6rem;
      margin-right: 0.8rem;
      content: '';
    }
  }

  // For centered lists, change flex-direction &
  // hide elements
  &__container--custom {
    flex-direction: column;

    & .bm-bullets__item::before {
      display: none;
    }

    @media only screen and (min-width: 355px) {
      flex-direction: row;

      & .bm-bullets__item::before {
        display: inline-block;
      }
    }
  }

  &--light {
    color: $bm-tertiary;

    & .bm-bullets__item::before {
      background-color: $bm-tertiary;
    }
  }

  &--primary .bm-bullets__item::before {
    background-color: $bm-primary;
  }
}
