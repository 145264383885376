.bm-giftcards-pricing-options {
  padding-bottom: map-get($bm_spacers, 8);
  padding-top: map-get($bm_spacers, 11);

  @include media-breakpoint-up(md) {
    padding-bottom: map-get($bm_spacers, 12);
    padding-top: calc(map-get($bm_spacers, 13) + map-get($bm_spacers, 7));
  }
}

.bm-giftcards-pricing-options--background-image {
  @include media-breakpoint-up(md) {
    background-repeat: no-repeat;
    background-image: url("../images/backgrounds/giftcards/desktop.webp");
    background-position: right 72px;
    background-size: 50% auto;
  }
}

.bm-giftcards-pricing-options__heading {
  font-size: 2.25rem;

  @include media-breakpoint-up(md) {
    font-size: 1.625rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 2.25rem;
  }
}

.bm-giftcards-pricing-options__subtitle {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 60%;
  }
}

.bm-giftcards-courses-gallery {
  display: grid;
  column-gap: map-get($bm_spacers, 2);
  row-gap: map-get($bm_spacers, 6);
  grid-template-columns: 1fr;
  margin-top: map-get($bm_spacers, 4);

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-gap: map-get($bm_spacers, 6);
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.bm-giftcards-how-it-works__reasons > * + * {
  margin-top: map-get($bm_spacers, 7);

  @include media-breakpoint-up(lg) {
    margin-top: map-get($bm_spacers, 8);
  }
}

.bm-giftcards-how-it-works__reasons a {
  color: $bm-white;
}
