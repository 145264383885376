// stylelint-disable selector-max-id, selector-class-pattern, declaration-no-important, selector-no-qualifying-type

.bm-checkout {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  background-color: #e6e6e6;
  padding: 1.5rem 0;
  justify-content: center;

  @include media-breakpoint-up(md) {
    flex-flow: row-reverse;
    gap: 2rem;
  }
}

.bm-checkout__form {
  background-color: #fff;
  padding: 2rem;
  margin: 1rem;
  border-radius: 15px;

  @include media-breakpoint-up(md) {
    max-width: 500px;
    margin: 1rem 0 1rem 1rem;
  }

  &__submit {
    margin-top: 1rem !important;
  }
}

.bm-checkout__info {
  padding: 0 1rem 2rem;

  @include media-breakpoint-up(md) {
    min-width: 330px;
    max-width: 330px;
    padding: 2rem 2rem 2rem 0;
  }
}

.bm-checkout__info__logo {
  color: $bm-neutral-50;
  width: (180rem / 16);

  @include media-breakpoint-up(sm) {
    width: (265rem / 16) !important;
  }
}

.bm-checkout__info__discount-input {
  max-width: 400px;
}

.bm-checkout__form__text-field {
  background-color: $bm-white;
  border: 1px solid $bm-neutral-250 !important;
  caret-color: $bm-primary;
}

.bm-checkout__form__check-box {
  + label {
    &::before {
      border: 1px solid $bm-neutral-250;
    }
  }

  &:checked {
    + label {
      &::before {
        border: none;
      }
    }
  }
}

.bm-checkout__current-user-email {
  overflow-wrap: break-word;
  font-size: $font-size-sm;

  @include media-breakpoint-up(md) {
    font-size: $font-size-md;
  }
}

.bm-checkout__success {
  background-color: #fff;
  padding: 2rem;
  margin: 1rem;
  border-radius: 15px;

  @include media-breakpoint-up(md) {
    margin: 1rem 0 1rem 1rem;
  }

  &__submit {
    margin-top: 1rem !important;
  }
}
