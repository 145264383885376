// Defines styles for <main>
.bm-application-layout,
.bm-devise-layout {
  background-color: $bm-bg-grey-light;
}

.bm-header--transparent + .bm-application-layout {
  margin-top: -($header-height-small);
  padding-top: 0;

  @include media-breakpoint-up(md) {
    margin-top: -($header-height-large);
  }
}

.bm-header--transparent-desktop + .bm-application-layout {
  @include media-breakpoint-up(lg) {
    margin-top: -($header-height-large);
  }
}

.no-js {
  .bm-devise-layout__header {
    background-color: $bm-tertiary;
  }
}

.bm-container-devise {
  &__content-header,
  &__content-main {
    padding: 2rem map-get($spacers, 4);

    @include media-breakpoint-up(lg) {
      padding: 2rem map-get($spacers, 5);
    }
  }

  &__content-header {
    background-color: $bm-white;
  }

  &__content-main {
    padding-bottom: map-get($spacers, 5);
  }

  &__maestro-name {
    font-size: (30rem / 16);
  }

  & .bm-price {
    font-size: (30rem / 16);
  }
}

// This layout is used only for non lightbox
// devise & payment pages
.bm-devise-layout {
  min-height: 100vh;

  &__header {
    padding: map-get($spacers, 4);

    @include media-breakpoint-up(lg) {
      position: fixed;
      z-index: 100;
      width: 100%;
      height: (70rem / 16);
      padding: 0 map-get($spacers, 4);
    }
  }

  &__logo {
    display: block;
    width: (175rem / 16);
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      width: (265rem / 16);
      margin-left: 0;
    }

    & > svg {
      position: relative;
      width: 100%;

      /* stylelint-disable selector-max-id, selector-no-qualifying-type */
      & path {
        &#bbc {
          fill: $bm-secondary;
        }

        &#maestro {
          fill: $bm-primary;
        }
      }
      /* stylelint-enable selector-max-id, selector-no-qualifying-type */
    }
  }

  &__close {
    position: absolute;
    right: 15px;

    @include media-breakpoint-up(lg) {
      display: flex;
      margin-left: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    & .bm-devise-layout__content {
      background-color: $bm-tertiary;
    }
  }

  & .bm-container-devise {
    margin-top: 1rem;
    box-shadow: $bm-shadow-modal-light;
  }

  &__title {
    font-size: (28rem / 16);

    @include media-breakpoint-up(lg) {
      font-size: (38rem / 16);
    }
  }
}
