$bm-campaign-banner-card__padding: (40rem / 16);

.bm-campaign-banner {
  padding: (60rem / 16) 0;
  background-color: $bm-secondary;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__title {
    font-family: $font-family-roboto-light;
    font-size: (27rem / 16);
    line-height: 1.5;
    text-align: center;
    letter-spacing: 6px;
  }

  &__heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: none;
    padding: 0 (15rem / 16);
    margin-bottom: map-get($spacers, 5);

    .bm-heading-with-lines {
      max-width: (300rem / 16);
      margin-bottom: map-get($spacers, 2);

      &__heading {
        font-family: $font-family-playfair;
        font-size: (22rem / 16);
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }

  &__card {
    max-width: (565rem / 16);
    padding: $bm-campaign-banner-card__padding;
    text-align: center;
    background-color: $bm-bg-grey-dark;

    &:first-child {
      margin-bottom: map-get($spacers, 2);
    }
    &:nth-of-type(2) {
      margin-top: map-get($spacers, 2);
    }
  }

  &__cta {
    position: relative;
    margin: -1.5rem 0;
  }

  @include media-breakpoint-up(lg) {
    &__content {
      flex-direction: row;
      align-items: stretch;
      max-width: (1140rem / 16);
      padding: 0 15px;
    }

    &__card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 49%;
      padding: $bm-campaign-banner-card__padding (70rem / 16);

      &:first-child {
        margin: 0 map-get($spacers, 2) 0 0;
      }

      &:nth-of-type(2) {
        margin: 0;
      }
    }

    .bm-campaign-banner--content-with-cta .bm-campaign-banner__card {
      &:first-child {
        padding-right: 140px;
      }

      &:nth-of-type(2) {
        padding-left: 140px;
      }
    }

    &__cta {
      @include abs-center;
      margin: 0;
    }
  }

  .bm-campaign-banner--content-with-one-section {
    // Slight specificity war with the `:first-child` and `:last-child`
    // of the `--with-cta`
    .bm-campaign-banner__card:nth-child(n) {
      padding: $bm-campaign-banner-card__padding
        $bm-campaign-banner-card__padding 3.5rem;
      margin-right: auto;
      margin-left: auto;
    }

    .bm-campaign-banner__cta {
      position: relative;
      margin-top: -2rem;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 100%;
        margin-top: 0;
      }
    }
  }
}

.bm-campaign-banner--with-wider-card {
  .bm-campaign-banner__card {
    // Let the card be slightly wider
    width: 100%;
    max-width: 55rem;
  }
}

.bm-campaign-banner--with-background {
  background-image: url("../images/campaign/badge-sm.jpg");
  background-position: top;

  @include media-breakpoint-up(md) {
    background-image: url("../images/campaign/badge-lg.jpg");
    background-position: center;
  }
}
