// stylelint-disable selector-class-pattern

.course-hits .ais-Hits-list {
  color-scheme: dark;
  display: flex;
  flex-flow: row nowrap;
  gap: map-get($bm_spacers, 4);
  min-height: 0;
  padding-bottom: map-get($bm_spacers, 2);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.course-hits .ais-Hits-list::-webkit-scrollbar {
  display: none;
}

.course-hits__prev,
.course-hits__next {
  background-color: $bm-primary-500;
  color: $bm-neutral-50;
  height: map-get($bm_spacers, 7);
  width: map-get($bm_spacers, 7);

  @include media-breakpoint-up(md) {
    height: map-get($bm_spacers, 9);
    width: map-get($bm_spacers, 9);
  }

  &:disabled {
    background-color: $bm-neutral-800;
    cursor: not-allowed;
  }
}

.course-hits__prev svg,
.course-hits__next svg {
  width: 6px;
  height: 10px;

  @include media-breakpoint-up(md) {
    height: 18px;
    width: 9px;
  }
}

.course-hits .ais-Hits-list li {
  flex: 0 0 auto;
  width: calc(50% - map-get($bm_spacers, 2));

  @include media-breakpoint-up(lg) {
    width: calc(25% - map-get($bm_spacers, 3));
  }
}

.lesson-hits .ais-Hits-list {
  display: grid;
  gap: map-get($bm_spacers, 4);

  @include media-breakpoint-up(md) {
    column-gap: map-get($bm_spacers, 4);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    overflow: hidden;
    row-gap: map-get($bm_spacers, 7);
  }
}

.ais-Hits-list {
  list-style-type: none;
  padding: 0;
}

.ais-Hits-item .vc-video-tile {
  display: flex;
  gap: map-get($bm_spacers, 4);

  @include media-breakpoint-up(md) {
    flex-direction: column;
    gap: 0;
  }
}

.ais-Highlight-highlighted {
  background-color: $bm-primary-400;
  color: $bm-neutral-900;
}
