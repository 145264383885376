// Slider for a single item per slide
.bm-slider {
  // Pagination controls
  &__pagination-container {
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__pagination {
    // Make a wide enough clickable button to
    // click comfortably
    width: 40px;
    height: 40px;
    @include flex-center;
    padding: 0;
    border: none;

    // Display to circle inside of it
    &::after {
      display: block;
      width: (20rem / 16);
      height: (20rem / 16);
      content: '';
      background-color: $bm-grey-light;
      border-radius: 50%;
    }

    &:not(:last-child) {
      margin-right: map-get($spacers, 2);
    }
  }

  &--active::after {
    background-color: $bm-grey-dark;
  }
}

// Slider for a list where offscreen slides can be partially
// seen to indicate the slider should be swiped
$slider-gutter: 1rem;
.bm-slider-list {
  width: 100vw;
  padding: 1rem 0;
  margin-left: -$slider-gutter;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  .bm-slider-list {
    &__container {
      overflow: visible;
    }

    &__list {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      @include rythm-x(2);
    }

    &__item {
      flex-shrink: 0;
      width: 80%;
      scroll-snap-align: start;

      &:first-child {
        width: calc(85% + #{$slider-gutter});
        padding-left: $slider-gutter;
      }

      &:last-child {
        // To give a right border to the last element, padding
        // is added reducing it's width, so adding it back on
        width: calc(80% + #{$slider-gutter});
        padding-right: $slider-gutter;
      }

      &:not(:first-child),
      &:not(:last-child) {
        scroll-margin-left: map-get($spacers, 4);
      }
    }
  }

  // Increase items as viewport increases
  /* stylelint-disable no-duplicate-selectors */
  .bm-slider-list__item {
    @include media-breakpoint-down(md) {
      width: 42%;

      &:first-child,
      &:last-child {
        width: calc(42% + #{$slider-gutter});
      }
    }

    @include media-breakpoint-down(xs) {
      width: 85%;

      &:first-child,
      &:last-child {
        width: calc(85% + #{$slider-gutter});
      }
    }
  }
  /* stylelint-enable no-duplicate-selectors */
}
