.vc-dot {
  border-radius: 50%;
  height: 0.25rem;
  width: 0.25rem;
}

.vc-dot--large {
  height: 0.375rem;
  width: 0.375rem;
}

.vc-dot--square {
  border-radius: 0;
}
