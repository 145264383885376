@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
