// stylelint-disable selector-no-qualifying-type

.vc-poster-cta {
  display: grid;

  @include media-breakpoint-up(xs) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: auto;

    @media (hover: none) {
      .vc-poster-cta__actions {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
      }

      .vc-poster-cta__intro {
        transform: translateY(-64px);
      }
    }

    @media (hover: hover) {
      &:hover .vc-poster-cta__actions,
      &:focus .vc-poster-cta__actions {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
      }

      &:hover .vc-poster-cta__intro,
      &:focus .vc-poster-cta__intro {
        transform: translateY(-64px);
      }
    }
  }

  &:focus-visible {
    outline: 1px dotted $bm-neutral-50;
    outline-offset: 2px;
  }
}

.vc-poster-cta--course {
  @include media-breakpoint-up(lg) {
    @media (hover: none) {
      .vc-poster-cta__intro {
        transform: translateY(-116px);
      }
    }

    @media (hover: hover) {
      &:hover .vc-poster-cta__intro,
      &:focus .vc-poster-cta__intro {
        transform: translateY(-116px);
      }
    }
  }

  @include media-breakpoint-up(xl) {
    @media (hover: none) {
      .vc-poster-cta__intro {
        transform: translateY(-64px);
      }
    }

    @media (hover: hover) {
      &:hover .vc-poster-cta__intro,
      &:focus .vc-poster-cta__intro {
        transform: translateY(-64px);
      }
    }
  }
}

.vc-poster-cta video {
  aspect-ratio: 9 / 16;
  object-fit: cover;
}

.vc-poster-cta .vjs-poster {
  background-size: cover;
}

.vc-poster-cta__title {
  color: $bm-white;
  font-family: $font-family-roboto-medium;
  font-size: 1.125rem;
  font-weight: 500;

  @include media-breakpoint-up(md) {
    color: $bm-neutral-50;
    font-family: $font-family-playfair;
    font-size: 1.625rem;
  }
}

.vc-poster-cta__subtitle {
  font-size: $font-size-sm;

  @include media-breakpoint-up(md) {
    font-size: $font-size-xs;
    font-family: $font-family-roboto-bold;
    letter-spacing: 1.2px;
  }
}

.vc-poster-cta__background {
  min-height: 246px;

  @include media-breakpoint-up(md) {
    height: 480px;

    &::after {
      background: linear-gradient(
          180deg,
          rgba($bm-black, 0) 56.45%,
          rgba($bm-grey-darker, 0.8) 79.57%
        ),
        linear-gradient(
          180deg,
          rgba(98, 98, 98, 0.01) 45.7%,
          $bm-grey-darker 100%
        );
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.vc-poster-cta__background picture {
  display: flex;
  height: 100%;
  width: 100%;
}

.vc-poster-cta__background img {
  aspect-ratio: 3/4;
  height: auto;
  object-fit: cover;
  width: 100%;
}

.vc-poster-cta__meta {
  margin-top: map-get($bm_spacers, 4);

  @include media-breakpoint-up(xs) {
    margin-bottom: map-get($bm_spacers, 4);
  }
}

.vc-poster-cta__intro p {
  @include media-breakpoint-up(md) {
    text-transform: uppercase;
  }
}

.vc-poster-cta__meta p {
  font-size: $font-size-xxs;

  @include media-breakpoint-up(md) {
    font-size: $font-size-xs;
  }
}

.vc-poster-cta__copy {
  background-color: $bm-neutral-800;
  padding: map-get($bm_spacers, 6) map-get($bm_spacers, 3)
    map-get($bm_spacers, 4);

  @include media-breakpoint-up(md) {
    bottom: map-get($bm_spacers, 6);
    background-color: transparent;
    padding-bottom: 0;
    position: absolute;
    left: 0;
    width: 100%;
  }
}

.vc-poster-cta__copy a {
  font-size: $font-size-xxs;
  padding: map-get($bm_spacers, 3) map-get($bm_spacers, 4);
}

/* Override the 'pill' button type button just for the default state a and not the 'active' or 'hover' states */
// stylelint-disable selector-class-pattern
.vc-poster-cta__copy a.btn-pill:not(:hover):not(:active) {
  border-color: $bm-neutral-650;
}

.vc-poster-cta__intro {
  transform: translateY(0);
  transition: transform 0.3s;
}

.vc-poster-cta__actions {
  margin-top: map-get($bm_spacers, 4);
  transition-property: opacity, transform;
  transition-duration: 0.4s;
  width: 100%;

  @include media-breakpoint-up(xs) {
    margin-top: auto;
  }

  @include media-breakpoint-up(md) {
    bottom: 0;
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%) translateY(24px);
    width: auto;
  }
}
