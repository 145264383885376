// One-line centred heading with horizontal lines either side
.bm-heading-with-lines {
  display: flex;
  align-items: center;
  width: 100%;
  //max-width: (240rem / 16);

  &__line {
    // Nudge lines down slightly to appear vertically centred with text
    position: relative;
    top: 2px;
    flex: auto 1 1;
    height: 2px;
    background-color: $bm-white;
  }

  &--primary .bm-heading-with-lines__line {
    background-color: $bm-primary;
  }

  &__heading {
    padding: 0 0.6em;
  }
}
