.vc-marquee {
  overflow: hidden;
  font-weight: bold;
  font-size: 0.9em;

  @include media-breakpoint-up(md) {
    font-size: 1em;
  }

  &__wrapper {
    --scroll-x: calc(-100% / var(--marquee-item-count));

    display: flex;
    width: max-content;
    animation-name: scroll;
    animation-duration: var(--marquee-speed-mobile);
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @include media-breakpoint-up(md) {
      animation-duration: var(--marquee-speed-desktop);
    }
  }

  &__item {
    display: flex;
    align-items: center;

    > * {
      padding: 0 0.9em;
      margin: 0;

      @include media-breakpoint-up(md) {
        padding: 0 1.2em;
      }
    }

    &__icon {
      position: relative;
      padding-right: 0;
      top: -1px;

      + * {
        padding-left: 0.7em;
      }
    }
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(var(--scroll-x));
  }
}
