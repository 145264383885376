.vc-hero {
  position: relative;

  &__background {
    position: absolute;
    inset: 0;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__overlay {
    position: relative;

    h1,
    h2,
    h3 {
      color: $bm-white;
    }

    &::before {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(
        180deg,
        rgba($bm-neutral-900, 0.1) 20%,
        rgba($bm-neutral-900, 1) 100%
      );

      @include media-breakpoint-up(md) {
        background: linear-gradient(
            90deg,
            rgba($bm-neutral-900, 0.4) 0%,
            rgba($bm-neutral-900, 0) 60%
          ),
          linear-gradient(
            180deg,
            rgba($bm-neutral-900, 0) 40%,
            rgba($bm-neutral-900, 1) 100%
          );
      }
    }

    &__container {
      position: relative;
      display: flex;
      align-items: end;
      padding-bottom: 8px;
      padding-top: 250px;

      @include media-breakpoint-up(md) {
        align-items: center;
        max-width: 510px;
      }
    }

    &__subscription-text {
      max-width: 270px;
    }
  }
}
