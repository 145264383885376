.bm-list {
  li {
    padding-left: map-get($bm_spacers, 4);
    position: relative;

    &::before {
      background-color: $bm-primary-400;
      content: "";
      height: 6px;
      left: 3px;
      position: absolute;
      top: 9px;
      width: 6px;
    }

    &:not(:last-child) {
      margin-bottom: map-get($bm_spacers, 1);
    }
  }
}

.bm-list:where(.font-xs) li::before { /* stylelint-disable */
  top: 6px;
}

.bm-list--unstyled {
  li {
    padding-left: map-get($bm_spacers, 7);

    &::before {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      padding-left: map-get($bm_spacers, 8);
    }

    &:not(:last-child) {
      margin-bottom: map-get($bm_spacers, 3);
    }
  }

  svg {
    left: 0;
    margin-right: map-get($bm_spacers, 3);
    position: absolute;
    top: 0;
  }
}

.bm-list--columns {
  @include media-breakpoint-up(md) {
    column-count: 2;
    column-gap: map-get($bm_spacers, 13);
  }
}

.bm-list--columns li {
  break-inside: avoid-column;
}
