// For use when hovering a separate element needs to
// trigger the animation
@mixin has-underline-slide {
  position: relative;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    content: '';
    background: $bm-primary;
    transition: transform 0.5s;
    transform: scaleX(0) translate3d(0, 0, 0);
    transform-origin: right;
  }
}

@mixin has-underline-slide-trigger {
  transform: scaleX(1) translate3d(0, 0, 0);
  transform-origin: left;
}
