@import "utilities/bordered_heading";
@import "utilities/focus_outline";
@import "utilities/letter_spacing";
@import "utilities/line_clamp";
@import "utilities/line_height";
@import "utilities/mobile_text";
@import "utilities/negative_margin";
@import "utilities/scroll_margin";
@import "utilities/text_wrap";
@import "utilities/media_queries";
// stylelint-disable selector-class-pattern

.d-xl-grid {
  @include media-breakpoint-up(xl) {
    display: grid !important; // stylelint-disable declaration-no-important
  }
}

.u-font-numeric {
  font-feature-settings: "lnum";
}
