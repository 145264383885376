.vc-campaign-top-banner {
  position: relative;
  text-align: center;

  &__row {
    padding: 1em 0;
    border-bottom-style: solid;
    border-bottom-width: 3px;

    &--tight {
      padding: 0.618em 0; /* golden ratio */
    }
  }

  h2 {
    font-size: 1.1em;
    margin-inline: auto;
    padding: 0 1rem;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(md) {
      font-size: 1.3em;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.5em;
    }
  }

  h2 > a {
    color: inherit;
    text-decoration: underline;

    &:focus-visible {
      outline: 2px solid currentcolor;
      outline-offset: map-get($bm_spacers, 1);
    }
  }
}

[class*="vc-campaign-top-banner__row--theme"] h2 {
  max-width: 60%;
}

.vc-campaign-top-banner__row--theme-christmas {
  padding: 1.5rem 0;
  position: relative;

  &::before,
  &::after {
    background-size: cover;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }

  &::before {
    background-image: url("../images/backgrounds/themes/xmas-mobile-left.png");
    background-position: top left;
    background-repeat: no-repeat;
    width: 85px;

    @include media-breakpoint-up(lg) {
      background-image: url("../images/backgrounds/themes/xmas-desktop-left.png");
      width: 300px;
    }
  }

  &::after {
    background-image: url("../images/backgrounds/themes/xmas-mobile-right.png");
    background-position: top right;
    background-repeat: no-repeat;
    left: auto;
    right: 0;
    width: 110px;

    @include media-breakpoint-up(lg) {
      background-image: url("../images/backgrounds/themes/xmas-desktop-right.png");
      width: 300px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 1rem 0;

    &::after,
    &::before {
      background-size: contain;
    }
  }
}
