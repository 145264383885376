// Adjusting one of Bootstrap's class
// stylelint-disable-next-line selector-class-pattern
.bm-mentioned-in__item.embed-responsive {
  // Couldn't size the aspect ratio using Bootstrap's configuration
  // so doing it here
  &::before {
    display: block;
    padding-top: 85 / 200 * 100%;
    content: '';
  }

  img {
    object-fit: contain;
    text-align: center;
  }
}
