.bbcm-checkout__grid {
  display: grid;
  gap: map-get($bm_spacers, 4);
  grid-template-columns: minmax(auto-fit, 400px);
}

@include media-breakpoint-up(xl) {
  .bbcm-checkout__grid {
    grid-template-columns: 400px 1fr;
    gap: map-get($bm_spacers, 11);
  }
}
