.vc-discount-code-input {
  width: 100%;
  justify-content: space-between;
}

.vc-discount-code-input__input {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 0.5rem;

  &__text-field {
    flex-grow: 1;
  }
}

.vc-discount-code-input input[type="text"] {
  box-shadow: inset 0 1px 0 $bm-neutral-400, inset 1px 0 0 $bm-neutral-400,
    inset 0 -1px 0 $bm-neutral-400;
}

.vc-discount-code-input input[type="text"]::placeholder {
  color: $bm-neutral-600;
}

.vc-discount-code-input button[aria-disabled="true"] {
  background-color: $bm-neutral-900 !important;
  cursor: not-allowed;
  opacity: 0.7;
}
