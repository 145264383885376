.bm-survey__question {
  padding: map-get($bm_spacers, 5) map-get($bm_spacers, 4)
    map-get($bm_spacers, 7);

  @include media-breakpoint-up(lg) {
    padding: map-get($bm_spacers, 6) map-get($bm_spacers, 12)
      map-get($bm_spacers, 7);
  }
}

.bm-survey__finish {
  padding: map-get($bm_spacers, 6) map-get($bm_spacers, 4);

  @include media-breakpoint-up(lg) {
    padding-bottom: map-get($bm_spacers, 8);
    padding-top: map-get($bm_spacers, 8);
  }
}

.bm-survey__form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: map-get($bm_spacers, 4);

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
  }
}

.bm-survey__buttons {
  display: grid;
  grid-gap: map-get($bm_spacers, 4);
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.bm-survey__button {
  @include media-breakpoint-up(sm) {
    max-width: 230px;
    min-width: 230px;
  }

  @include media-breakpoint-up(xl) {
    min-width: min(100%, 230px);
  }
}

.bm-survey__radios {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(55px, max-content));
  grid-gap: map-get($bm_spacers, 2);
}

.bm-survey__subtext {
  font-size: 0.875rem;

  @include media-breakpoint-up(md) {
    font-size: initial;
  }
}
