// stylelint-disable selector-class-pattern
// stylelint-disable declaration-no-important

// NOTE
// As this is a third party library and given that we are loading in this resource from an external site
// the only way we can override some class declarations here is through the use of important.
// The changes here should remain minimal as we still want to benefit from all of the preconfigured styling
// that ReviewsIO provides us for adding this to the site.

.CarouselWidget-prefix .CarouselWidget {
  margin-bottom: 0 !important;
}

.CarouselWidget-prefix .CarouselWidget .R-ReviewsList {
  padding-bottom: 0 !important;
}

.CarouselWidget__header {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
