.bm-icon {
  @include media-breakpoint-up(lg) {
    width: (45rem / 16);
    height: (45rem / 16);
  }
}

.bm-icon-14 {
  width: (14rem / 16);
  height: (14rem / 16);
}

.bm-icon-16 {
  width: map-get($bm_spacers, 4);
  height: map-get($bm_spacers, 4);
}

.bm-icon-20 {
  width: (20rem / 16);
  height: (20rem / 16);
}

.bm-icon-24 {
  width: (24rem / 16);
  height: (24rem / 16);
}

.bm-icon-28 {
  width: (28rem / 16);
  height: (28rem / 16);
}

.bm-icon-40 {
  width: (40rem / 16);
  height: (40rem / 16);
}

.bm-icon-70 {
  width: (70rem / 16);
  height: (70rem / 16);
}

// stylelint-disable
.lnr {
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: -0.05em;
  fill: currentColor;
}

.bm-lnr-icon-white {
  color: #fff;
}

.bm-icon--sm {
  width: (25rem / 16);
  height: (25rem / 16);

  @include media-breakpoint-up(md) {
    width: (30rem / 16);
    height: (30rem / 16);
  }
}

.bm-icon--path-primary path {
  fill: $bm-primary;
}

.bm-icon--path-tertiary path {
  fill: $bm-tertiary;
}

.bm-icon--tertiary {
  stroke: $bm-tertiary;
}

.bm-close-icon {
  width: (30rem / 16);
  height: (30rem / 16);
  padding: map-get($spacers, 2);
  background-color: $bm-bg-grey-light;
  border: none;
  transition: background-color 0.4s;
  @include flex-center;

  &:hover {
    background-color: $bm-bg-grey;
  }

  &--light {
    background-color: $bm-secondary;

    &:hover {
      background-color: $bm-secondary-darker;
    }
  }

  & > svg {
    width: 100%;
    pointer-events: none;
  }

  @include media-breakpoint-up(lg) {
    width: (40rem / 16);
    height: (40rem / 16);
    @include flex-center;

    & > svg {
      width: (18rem / 16);
      height: (18rem / 16);
    }
  }
}

.bm-icon--current-color path {
  stroke: currentColor;
}

// Remove transition, used when switching button colour without a delay
.bm-close-icon--no-transition {
  transition: none;
}

.bm-tick {
  position: relative;
  top: 0.4em;

  & svg polygon {
    fill: $bm-primary;
  }
}

.bm-icon-white {
  & * {
    fill: $bm-white;
  }
}

.bm-icon-dark {
  & * {
    stroke: #555;
  }
}

.bm-icon-primary-400 {
  color: $bm-primary-400
}
