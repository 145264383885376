// Disabling verification of classname pattern as it's mostly styling bootstrap
// stylelint-disable selector-class-pattern
@mixin bm-checkbox {
  @include sr-only;

  + label {
    position: relative;
    padding-left: map-get($spacers, 2);
    user-select: none;

    &::before,
    &::after {
      position: absolute;
      left: -24px;
      display: block;
      content: '';
    }

    &::before {
      top: 11px;
      width: 18px;
      height: 18px;
      background-color: $bm-white;
      transform: translateY(-50%);
    }

    &::after {
      top: 10px;
      left: -15px;
      box-sizing: border-box;
      width: 11px;
      height: 5.5px;
      border-bottom: solid 2px;
      border-left: solid 2px;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &:checked + label::before {
    background-color: $bm-primary;
  }

  &:checked + label::after {
    color: $bm-white;
  }

  &:not(:checked) + label::after {
    opacity: 0;
  }

  &:not([disabled]) + label {
    cursor: pointer;
  }
}

@mixin bm-checkbox-appearance {
  // Disabled state
  // Use disabled attribute instead of :disabled pseudo-class
  // Workaround for: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11295231
  // Thx bootstrap source https://github.com/twbs/bootstrap/blob/f6694b74405261ed454d409ea5251f08cdf6c51c/scss/forms/_form-check.scss#L67
  &[disabled] + label {
    //color: $gray-600;

    &::before {
      opacity: 0.8;
    }
  }

  &[disabled]:checked + label {
    &::after {
      opacity: 0.75;
    }
  }

  &.is-invalid + label::before {
    border: 2px solid $bm-red;
  }

  // Focus state
  &:focus.focus-visible + label {
    &::before {
      box-shadow: 0 0 0 1px $white, 0 0 0 3px theme-color(primary);
    }

    @media all and (-ms-high-contrast: active) {
      text-decoration: underline;
      &::before {
        border: solid 3px;
      }
    }
  }

  // Hover states
  @media (hover: hover) {
    &:not(:checked):not([disabled]) + label:hover {
      &::before {
        // Not rgba to avoid muddying color with the background of the underlying container
        background-color: mix(theme-color(primary), $white, 33%);
        border-color: mix(theme-color(primary), $input-border-color, 33%);
      }

      &::after {
        border-color: color-yiq(theme-color(primary));
        opacity: 1;
      }
    }

    &:checked:not([disabled]) + label:hover {
      &::before {
        background-color: mix(theme-color(primary), $white, 66%);
        border-color: mix(theme-color(primary), $input-border-color, 66%);
      }
    }
  }
}
