// stylelint-disable selector-class-pattern
.bm-radio-buttons-form {
  legend,
  .form-check input {
    @include sr-only;
  }

  .form-check {
    padding: 0;

    &:not(:last-child) {
      padding-bottom: 12px;
    }

    label {
      width: 100%;
      padding: 12px 16px 16px;
      margin-left: 0;
      text-align: center;
      background-color: $bm-white;
      border: 1px solid $bm-grey-lighter;

      &:hover {
        cursor: pointer;
        background-color: rgba($bm-white, 0.7);
        outline: 2px solid rgba($bm-primary, 0.4);
        outline-offset: -1px;
      }

      .bm-delivery-type-title,
      .bm-offer-selection-title {
        margin-bottom: 12px;
        font-size: 20px;
      }

      .bm-delivery-type-description,
      .bm-offer-selection-description {
        font-size: 14px;
      }
    }

    // States
    input:checked + label {
      border: 2px solid $bm-primary;
    }

    input:checked:focus {
      & + label {
        outline: 2px solid $bm-secondary;
        outline-offset: 3px;
        box-shadow: none;
      }

      &:not(.focus-visible) + label {
        outline: none;
      }
    }

    input:disabled ~ label,
    input:disabled ~ label:hover {
      cursor: not-allowed;
      background-color: $bm-bg-modal;
      border-color: $bm-grey-light;
      outline: none;
    }
  }
}
// stylelint-enable selector-class-pattern
