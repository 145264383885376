.vc-plans {
  padding-top: map-get($bm_spacers, 12);

  @include media-breakpoint-up(md) {
    padding-top: calc(map-get($bm_spacers, 13) + map-get($bm_spacers, 7));
  }
}

.vc-plans__grid {
  gap: map-get($bm_spacers, 7);

  @include media-breakpoint-up(lg) {
    gap: map-get($bm_spacers, 11);
  }
}

.vc-plans__list {
  margin: map-get($bm_spacers, 6) 0;

  @include media-breakpoint-up(lg) {
    margin: map-get($bm_spacers, 8) 0;
  }
}

.vc-plans__benefit {
  font-size: 0.875rem;

  @include media-breakpoint-up(lg) {
    font-size: initial;
  }
}
