.vc-course-stats {
  --course-stats-image-dimension: 3.5rem;

  margin-left: -(map-get($bm_spacers, 4));
  margin-right: -(map-get($bm_spacers, 4));

  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}

.vc-course-stats__image {
  height: var(--course-stats-image-dimension);
  object-fit: cover;
  width: var(--course-stats-image-dimension);
}

.vc-course-stats__container {
  column-gap: map-get($bm_spacers, 4);
  display: grid;
  grid-template-columns: var(--course-stats-image-dimension) 1fr;
  grid-template-rows: 1fr max-content;
}

.vc-course-stats__content {
  @include media-breakpoint-up(sm) {
    grid-column: 2;
    grid-row: 1 / 1;
  }
}

.vc-course-stats__title {
  font-size: 1.125rem;
  line-height: 1;
}

.vc-course-stats__meta {
  grid-column: span 2;
  scrollbar-width: thin;

  @include media-breakpoint-up(sm) {
    grid-row: 2;
  }
}
