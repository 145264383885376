@mixin button-text {
  font-size: $font-size-xs;
  line-height: 1.7;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
