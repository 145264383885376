.vc-campaign-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  position: relative;
  background: $bm-grey-lighter;
  padding: 30px;

  --text-color: inherit;
  --font-size-multiplier: 0.75;

  @include media-breakpoint-up(md) {
    --font-size-multiplier: 0.85;
  }

  @include media-breakpoint-up(lg) {
    --font-size-multiplier: 1;
  }

  &__background {
    picture {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  &__background--cover picture img {
    object-fit: cover;
  }

  &__content {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    &__name {
      color: var(--text-color);
      font-size: calc(2.5em * var(--font-size-multiplier));
    }

    &__title {
      color: var(--text-color);
      font-size: calc(2.5em * var(--font-size-multiplier));
      font-family: $font-family-roboto-regular;
      text-transform: uppercase;
      letter-spacing: 1.3;
      line-height: 1;
      overflow-wrap: anywhere;

      @include media-breakpoint-up(lg) {
        font-size: calc(3.75em * var(--font-size-multiplier));
      }

      @include media-breakpoint-up(xl) {
        font-size: calc(4em * var(--font-size-multiplier));
      }
    }

    &__subtitle {
      color: var(--text-color);
      font-size: calc(1.5em * var(--font-size-multiplier));
      font-family: $font-family-roboto-regular;
      text-transform: uppercase;
      letter-spacing: 1.3;
    }

    &__terms {
      margin-top: 2em;
      color: var(--text-color);

      a {
        color: var(--text-color);
        text-decoration: underline;
      }
    }
  }
}
