// stylelint-disable
body.scroll-disabled {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

@mixin breakpoint-sm {
  @media only screen and (min-width: (700rem / 16)) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media only screen and (min-width: (900rem /16)) {
    @content;
  }
}

@mixin breakpoint-xl {
  @media only screen and (min-width: (1280rem / 16)) {
    @content;
  }
}

#CybotCookiebotDialog.CybotEdge {
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  max-width: none !important;
  color: $bm-tertiary !important;
  letter-spacing: 0 !important;
  background-color: $bm-secondary !important;
  border-radius: 0 !important;
  transform: none !important;

  &::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    pointer-events: none;
  }

  @include breakpoint-xl {
    padding: 0 !important;
  }

  * {
    font-family: $font-family-roboto-regular !important;
  }

  .CybotCookiebotDialogContentWrapper {
    max-width: (1260rem / 16) !important;
    padding: map-get($bm_spacers, 7) map-get($bm_spacers, 7) !important;
    margin: 0 auto !important;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      flex-direction: row !important;
      align-items: stretch !important;
    }

    &::before {
      content: "";
      background-color: $bm-secondary;
      position: absolute;
      top: 0;
      left: -50vw;
      right: -50vw;
      bottom: 0;
      border-bottom: 3px solid darken($color: $bm-secondary, $amount: 5%);
    }
  }

  .CybotCookiebotScrollContainer {
    min-height: 0 !important;
    padding: 0 !important;
    border-bottom: none !important;
  }

  #CybotCookiebotDialogBodyButtonDecline {
    background-color: transparent !important;
    border: 2px solid $bm-neutral-50 !important;
    padding: 0.9rem 2rem !important;
    color: $bm-neutral-50 !important;
    box-shadow: 0 !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    font-size: 14px;
    order: 2;
    letter-spacing: 1.2px;

    &:focus-visible {
      outline-color: $bm-neutral-50;
    }
  }

  #CybotCookiebotDialogBody {
    @include breakpoint-xl {
      justify-content: space-around !important;
    }

    .CybotCookiebotScrollContainer .CybotCookiebotScrollContainer {
      width: 2px;
    }
  }

  #CybotCookiebotDialog .CybotCookiebotFader {
    display: none;
  }

  #CybotCookiebotDialogBodyContent {
    padding: 0 !important;

    @include media-breakpoint-up(sm) {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      padding-right: map-get($spacers, 3) !important;
    }

    @include breakpoint-sm {
      // flex-direction: row !important;
      justify-content: flex-start !important;
    }
  }

  #CybotCookiebotDialogBodyContentTitle,
  #CybotCookiebotDialogBodyContentText {
    @include breakpoint-sm {
      display: flex !important;
      align-items: center !important;
    }
  }

  #CybotCookiebotDialogBodyContentTitle {
    font-family: $font-family-playfair !important;
    font-size: (22rem / 16) !important;
    line-height: 1.3 !important;

    @include breakpoint-sm {
      width: 100% !important;
      margin: 0 !important;
    }
  }

  #CybotCookiebotDialogBodyContentText {
    font-size: (16rem / 16) !important;
    line-height: 1.3 !important;

    @include breakpoint-sm {
      font-size: 14px !important;
      margin-top: 1rem;
      width: 90%;
    }
  }

  #CybotCookiebotDialogBodyButtons {
    @include media-breakpoint-up(md) {
      justify-content: center !important;
      max-width: none !important;
    }
  }

  #CybotCookiebotDialogBodyButtonsWrapper {
    flex-direction: column !important;
    align-items: flex-start !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    margin-top: 1.25rem !important;

    @include media-breakpoint-up(sm) {
      min-width: (175rem / 16) !important;
      flex-direction: row !important;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0 !important;
    }

    @include breakpoint-xl {
      min-width: (340rem / 16) !important;
    }
  }

  .CybotCookiebotDialogBodyButton {
    justify-content: flex-start !important;
    width: auto !important;
    padding: 0 !important;
    color: $bm-primary !important;
    background: none !important;
    border: none !important;
  }

  #CybotCookiebotDialogBodyButtons {
    flex: 1;
  }

  #CybotCookiebotDialog.CybotEdge
    #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyButtonsWrapper {
    flex-wrap: wrap !important;
  }

  .CybotCookiebotScrollContainer .CybotCookiebotScrollbarContainer {
    width: 2px !important;
  }

  &:hover .CybotCookiebotScrollContainer .CybotCookiebotScrollbarContainer {
    opacity: 1;
  }

  #CybotCookiebotDialogBodyLevelButtonCustomize {
    color: $bm-neutral-50 !important;
    font-size: 1rem !important;
    flex: 1 1 100%;
    order: 3;
    text-align: start;
    text-decoration: underline;
    text-underline-offset: 2px;

    @include breakpoint-xl {
      margin-bottom: 0 !important;
    }
  }

  #CybotCookiebotDialogFooter {
    flex: 1;
    width: auto !important;
    padding: 0 !important;
    margin-top: map-get($spacers, 3) !important;
    z-index: 1;

    @include media-breakpoint-up(sm) {
      display: flex !important;
      padding-top: 0 !important;
      margin: (6rem / 16) 0 (1rem / 16) !important;
    }

    @include breakpoint-lg {
      min-width: (350rem / 16) !important;
      margin: 0 !important;
    }
  }

  #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    width: fit-content !important;
  }

  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
    gap: 1rem;
  }

  #CybotCookiebotDialog.CybotEdge
    #CybotCookiebotDialogFooter
    .CybotCookiebotDialogBodyButton:not(:first-of-type) {
    text-align: start;
  }

  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    display: flex !important;
    justify-content: center !important;
    padding: 1rem 2rem !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    color: $bm-white !important;
    background-color: $bm-primary !important;
    text-transform: uppercase !important;
    letter-spacing: 1.2px;
    order: 1;

    // Forcing the transparency of the outline for now, until
    // we can find a fix for the banner stealing focus with js
    &:focus {
      outline-color: transparent !important;
    }

    &:focus-visible {
      outline-color: $bm-primary;
      outline-offset: 2px;
    }

    @include media-breakpoint-up(sm) {
      margin-right: 0 !important;
    }
  }

  #CybotCookiebotDialogHeader,
  .CybotCookiebotDialogBodyBottomWrapper,
  .CybotCookiebotDialogArrow {
    display: none !important;
  }
}

#CybotCookiebotDialogHeader {
  display: none !important;
}

#CybotCookiebotDialog {
  input[type="radio"],
  input[type="checkbox"] {
    position: static !important;
  }
}

// Safari won't scroll the container as an explicit height
// isn't set, so filling available space
#CybotCookiebotDialogTabContentDetails {
  height: fill-available !important;
}

#CybotCookiebotDialogDetail {
  display: inline-block !important;
}

#CybotCookiebotDialogPoweredByText {
  display: none !important;
}

// stylelint-enable
