.vc-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    position: relative;
    border: 5px solid white;
    width: 95%;
    max-width: 1100px;
    min-height: 55px;
    background: $bm-grey-lighter;

    @include media-breakpoint-up(lg) {
      width: 60%;
    }

    &__close {
      position: absolute;
      top: 0;
      right: 0;
      width: 45px;
      height: 45px;
      padding: 12px;
      z-index: 1;
      background: rgba(0, 0, 0, 0.5);
      border: none;
    }
  }
}
