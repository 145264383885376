.vc-featured-course {
  position: relative;
  height: calc((100vw / 16) * 9);
  min-height: 400px;
  max-height: 800px;
  display: flex;
  overflow: hidden;
  max-width: 1920px;
  margin: auto;

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: top center;
    transition: transform 1.25s ease-in-out;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(
          90deg,
          rgba(rgba($bm-neutral-900, 0), 1) 0%,
          rgba(rgba($bm-neutral-900, 0), 0) 5%
        ),
        linear-gradient(
          90deg,
          rgba(rgba($bm-neutral-900, 0), 0) 95%,
          rgba(rgba($bm-neutral-900, 0), 1) 100%
        );
    }

    &::before {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(
          0deg,
          rgba($bm-neutral-900, 0.5) 0%,
          rgba($bm-neutral-900, 0.4) 100%
        ),
        linear-gradient(
          180deg,
          rgba($bm-neutral-900, 0) 40%,
          rgba($bm-neutral-900, 1) 100%
        );

      @include media-breakpoint-up(md) {
        background: linear-gradient(
            90deg,
            rgba($bm-neutral-900, 0.8) 0%,
            rgba($bm-neutral-900, 0) 60%
          ),
          linear-gradient(
            180deg,
            rgba($bm-neutral-900, 0) 40%,
            rgba($bm-neutral-900, 1) 100%
          );
      }
    }
  }

  &:hover {
    .vc-featured-course__background {
      transform: scale(1.025);
    }
  }

  &__content {
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex: 1;
    align-items: center;
    padding-left: $padding-sm / 4;
    max-width: 90%;

    @include media-breakpoint-up(sm) {
      max-width: 80%;
    }

    @include media-breakpoint-up(md) {
      max-width: 66%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 50%;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 40%;
    }

    * {
      color: $bm-white;
    }

    &__title {
      font-size: 2rem;
      margin-bottom: $padding-sm / 4;

      @include media-breakpoint-up(md) {
        font-size: 3rem;
        margin-top: $padding-sm / 4;
        margin-bottom: $padding-sm / 2;
      }

      @include media-breakpoint-up(lg) {
        font-size: 4rem;
      }
    }

    &__maestro,
    &__course {
      @include media-breakpoint-up(md) {
        font-size: $font-size-lg;
      }

      font-family: $font-family-roboto-medium;
      font-weight: 400;
    }

    &__course {
      font-size: $font-size-sm;
      display: inline-block;
      letter-spacing: 1.1;
      text-transform: uppercase;
      text-decoration-color: $bm-primary;
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 5px;
      padding-bottom: 3px;
      line-height: 1.7;

      @include media-breakpoint-up(md) {
        font-size: $font-size-md;
      }
    }
  }
}
