// Vertical padding used for even layouts
// stylelint-disable selector-class-pattern
// stylelint-disable declaration-no-important
.padding-y-sm {
  padding-bottom: map-get($spacers, 5);

  @include media-breakpoint-up(md) {
    padding: map-get($spacers, 3) 0 map-get($spacers, 5);
  }

  @include media-breakpoint-up(lg) {
    padding: $padding-sm 0;
  }
}

.padding-t-md {
  padding-top: $padding-xs;

  @include media-breakpoint-up(md) {
    padding-top: $padding-md;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $padding-lg;
  }
}

.padding-t-xl {
  padding-top: $padding-sm !important;

  @include media-breakpoint-up(md) {
    padding-top: $padding-md !important;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $padding-lg !important;
  }
}

.padding-b-md {
  padding-bottom: $padding-xs;

  @include media-breakpoint-up(md) {
    padding-bottom: $padding-md;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $padding-lg;
  }
}

.padding-b-xl {
  padding-bottom: $padding-sm;

  @include media-breakpoint-up(md) {
    padding-bottom: $padding-md;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $padding-lg;
  }
}

.padding-y-md {
  padding: $padding-xs 0;

  @include media-breakpoint-up(md) {
    padding: $padding-md 0;
  }

  @include media-breakpoint-up(lg) {
    padding: $padding-lg 0;
  }
}

.padding-y-lg {
  padding: $padding-sm 0;

  @include media-breakpoint-up(md) {
    padding: $padding-md 0;
  }
}

.padding-y-xl {
  padding: $padding-sm 0;

  @include media-breakpoint-up(md) {
    padding: $padding-md 0;
  }

  @include media-breakpoint-up(lg) {
    padding: $padding-xs 0;
    margin: $padding-xs 0;
  }
}

.padding-y-xxl {
  padding: $padding-sm 0;

  @include media-breakpoint-up(md) {
    padding: $padding-md 0;
  }

  @include media-breakpoint-up(lg) {
    padding: $padding-lg 0;
  }
}

.full-width {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw;
  margin-right: -50vw;
  margin-left: -50vw;
  overflow: hidden;
}

.u-section-padding-top-sm {
  padding-top: map-get($bm_spacers, 8) !important;

  @include media-breakpoint-up(lg) {
    padding-top: map-get($bm_spacers, 10) !important;
  }
}

.u-section-padding-top {
  padding-top: map-get($bm_spacers, 8) !important;

  @include media-breakpoint-up(md) {
    padding-top: map-get($bm_spacers, 11) !important;
  }

  @include media-breakpoint-up(lg) {
    padding-top: map-get($bm_spacers, 12) !important;
  }
}

.u-section-padding-top-lg {
  padding-top: map-get($bm_spacers, 11) !important;

  @include media-breakpoint-up(lg) {
    padding-top: map-get($bm_spacers, 12) !important;
  }
}

.u-section-padding-top-xl {
  padding-top: map-get($bm_spacers, 12) !important;

  @include media-breakpoint-up(lg) {
    padding-top: map-get($bm_spacers, 13) !important;
  }
}

.u-section-padding-bottom {
  padding-bottom: map-get($bm_spacers, 8) !important;

  @include media-breakpoint-up(md) {
    padding-bottom: map-get($bm_spacers, 11) !important;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: map-get($bm_spacers, 12) !important;
  }
}

.u-section-padding-bottom-sm {
  padding-bottom: map-get($bm_spacers, 7) !important;
}

.u-section-margin-top {
  margin-top: map-get($bm_spacers, 4) !important;

  @include media-breakpoint-up(lg) {
    margin-top: map-get($bm_spacers, 7) !important;
  }
}

/*
  This is a temporary solution to what will be utility generator mixins based on the tokens
  in the _tokens.scss file however before we purge the CSS it doesn't make sense to do this
  right now before we have the ability to exclude classes that we aren't using from what these
  mixins will generate
*/

.u-mt-4 {
  margin-top: map-get($bm_spacers, 4);
}

.u-mt-6 {
  margin-top: map-get($bm_spacers, 6);
}

.u-mt-7 {
  margin-top: map-get($bm_spacers, 7);
}

.u-mt-8 {
  margin-top: map-get($bm_spacers, 8);
}

.u-mt-lg-6 {
  margin-top: map-get($bm_spacers, 6);
}
