// stylelint-disable selector-class-pattern
// stylelint-disable declaration-no-important
.datepicker {
  width: 100% !important;
  margin-bottom: 16px;
}

.datepicker--day-name {
  font-weight: bold !important; /* stylelint-disable-line font-weight-notation */
  color: $bm-primary !important;
}

.datepicker--cell.-current- {
  font-weight: bold !important; /* stylelint-disable-line font-weight-notation */
}

.datepicker--cell.-selected- {
  background-color: $bm-primary !important;
}

.datepicker--cell.-selected-.-focus- {
  background-color: $bm-primary !important;
}
// stylelint-enable selector-class-pattern
