.bm-bg-white-md-tertiary {
  background-color: $bm-white;

  @include media-breakpoint-up(md) {
    background-color: $bm-tertiary;
  }
}

.bm-bg-md-grey {
  @include media-breakpoint-up(md) {
    background-color: $bm-grey-lighter;
  }
}
