// Disabling verification of classname pattern as it's mostly styling bootstrap
/* stylelint-disable selector-class-pattern */
.btn {
  box-sizing: border-box;
  padding: 1rem 2rem;
  font-family: $font-family-roboto-medium;
  font-size: $font-size-xs;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  white-space: nowrap;
  border: none;
  border-radius: 0;
  transition: background-color 0.4s, box-shadow 0.4s;

  &:not(:disabled) {
    &:focus,
    &:active {
      outline: 2px solid currentcolor;
      outline-offset: map-get($bm_spacers, 1);
    }

    &:focus:not(.focus-visible) {
      outline: 2px solid currentcolor;
      outline-offset: map-get($bm_spacers, 1);
    }
  }

  &:focus {
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: transparent;
    box-shadow: none;
  }

  &-cta:not(:disabled) {
    transition: transform 0.4s, background-color 0.4s, box-shadow 0.4s;
    transform: translateY(0);

    &:hover {
      transform: translateY(-0.25rem);
    }
  }

  & > span {
    pointer-events: none;
  }

  &-inline {
    font-family: $font-family-roboto-regular;
    letter-spacing: 0;
    padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    text-transform: none;

    &:hover,
    &:active {
      color: initial;
      text-decoration: underline;
    }

    &:focus {
      box-shadow: none;
      outline: 2px solid $bm-neutral-50;
      outline-offset: 2px;
    }
  }

  // PRIMARY BUTTONS
  &-primary {
    background-color: $bm-primary;

    &:hover,
    &:focus,
    &:active {
      border-color: transparent;
      background-color: $bm-primary-dark;
      outline: none !important; /* stylelint-disable-line declaration-no-important */
    }

    &:focus-visible {
      outline: 2px solid $bm-primary-500 !important; /* stylelint-disable-line declaration-no-important */
      outline-offset: map-get(
        $bm_spacers,
        1
      ) !important; /* stylelint-disable-line declaration-no-important */
    }

    &:disabled {
      background-color: rgba(
        $bm-primary-500,
        0.3
      ) !important; /* stylelint-disable-line declaration-no-important */

      color: $bm-white !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &-dark {
    background-color: $bm-secondary;
    &:hover {
      background-color: $bm-grey-darker;
    }
    &:active {
      background-color: $bm-secondary !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &-grey-dark {
    padding: 1rem 1.6rem;
    color: $bm-tertiary;
    background-color: $bm-grey-dark;

    &:hover {
      color: $bm-secondary;
      background-color: $bm-tertiary;
      &:not(:disabled) {
        box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
      }
    }
    &:active {
      background-color: $bm-grey-light !important; /* stylelint-disable-line declaration-no-important */
    }
    &:focus {
      outline: 2px solid $bm-tertiary !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &-light {
    background-color: $bm-tertiary;
    &:hover {
      background-color: $bm-grey-lighter;
    }
    &:active {
      background-color: $bm-grey-medium !important; /* stylelint-disable-line declaration-no-important */
    }
    &:focus {
      outline: 2px solid $bm-primary !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &-icon-stacked {
    border-radius: map-get(
      $bm_spacers,
      1
    ) !important; /* stylelint-disable-line declaration-no-important */

    min-width: 6.5rem;
    padding: map-get($bm_spacers, 1) map-get($bm_spacers, 4) !important; /* stylelint-disable-line declaration-no-important */
    text-transform: none;

    span {
      color: $bm-neutral-300;
      font-family: $font-family-roboto-regular;
      margin-left: 0;
      letter-spacing: 0;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $bm-neutral-800;
        box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    &:active,
    &:focus,
    &:hover {
      box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
    }

    &:focus,
    &:focus-visible {
      outline: 2px solid $bm-neutral-50 !important; /* stylelint-disable-line declaration-no-important */
      outline-offset: map-get($bm_spacers, 1);
    }

    @include media-breakpoint-up(xs) {
      min-width: 7.15rem;
    }
  }

  &-icon-stacked[data-status="true"] > svg,
  &--icon-only[data-status="true"] svg,
  &--toggle[data-status="true"] svg {
    fill: $bm-white;
  }

  &-pill {
    border: 1px solid $bm-neutral-100;
    color: $bm-neutral-50;
    font-family: $font-family-roboto-bold;
    padding: map-get($bm_spacers, 3) map-get($bm_spacers, 4);

    &:hover,
    &:active,
    &:focus {
      border-color: $bm-neutral-250;
      background-color: $bm-neutral-250;
      color: $bm-neutral-950;
      outline: none !important; /* stylelint-disable-line declaration-no-important */
    }

    &:focus-visible {
      outline: 1px solid $bm-neutral-100 !important; /* stylelint-disable-line declaration-no-important */
    }

    &--active {
      border-color: $bm-neutral-100;
      background-color: $bm-neutral-150;
      color: $bm-neutral-950;

      &:hover,
      &:active,
      &:focus {
        background-color: $bm-neutral-900;
        color: $bm-neutral-50;
        outline: none !important; /* stylelint-disable-line declaration-no-important */
      }

      &:focus-visible {
        outline: 1px solid $bm-neutral-100 !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    @include media-breakpoint-up(md) {
      padding: map-get($bm_spacers, 4) map-get($bm_spacers, 6);
    }
  }

  &-secondary {
    background-color: transparent;
    border: 2px solid $bm-neutral-800;
    color: $bm-neutral-800;
    padding: map-get($bm_spacers, 4) map-get($bm_spacers, 6);

    &:hover,
    &:focus,
    &:active {
      background-color: $bm-neutral-50 !important; /* stylelint-disable-line declaration-no-important */
      border-color: inherit !important; /* stylelint-disable-line declaration-no-important */
      color: inherit !important; /* stylelint-disable-line declaration-no-important */
      outline: none !important; /* stylelint-disable-line declaration-no-important */
    }

    &:focus-visible {
      outline-offset: map-get($bm_spacers, 1);
      outline: 2px solid $bm-neutral-800 !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &-transparent {
    border: 2px solid $bm-bg-grey-light;
    background-color: transparent;
    color: $bm-white;

    &:hover,
    &:focus,
    &:active {
      background-color: $bm-white;
      color: $bm-secondary;
      outline: none !important; /* stylelint-disable-line declaration-no-important */
    }

    &:focus-visible {
      outline-offset: map-get($bm_spacers, 1);
      outline: 2px solid $bm-neutral-50;
    }
  }

  &.btn-sign-up {
    background-color: $bm-primary-500;
    color: $bm-neutral-50;

    &:disabled {
      background-color: $bm-neutral-700;
      color: $bm-bg-grey-light;
    }

    &:not(:disabled) {
      &:hover {
        background-color: $bm-primary-600;
      }

      &:focus {
        outline: 2px solid $bm-primary-500 !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }

  &:disabled {
    color: $bm-grey-light;
    background-color: $bm-grey-lighter;
    opacity: 1;

    &:hover {
      cursor: not-allowed;
      box-shadow: none;
    }
  }

  // GHOST BUTTONS
  &-ghost {
    // Minus border-width from padding to ensure
    // buttons are same size
    padding: calc(1rem - 2px) calc(2rem - 2px);
    border-style: solid;
    border-width: 2px;

    &-primary {
      color: $bm-primary;
      background-color: $bm-tertiary;
      border: 2px solid $bm-primary;

      &:hover,
      &:active {
        background-color: $bm-primary;
        color: $bm-white;
      }

      &:focus-visible {
        outline: 2px solid currentcolor;
        outline-offset: 2px;
      }
    }

    &-white {
      color: $bm-neutral-50;
      background-color: transparent;
      border: 2px solid $bm-neutral-50;
      padding: 0.9rem 2rem;

      &:hover,
      &:focus {
        background-color: $bm-neutral-50;
        color: $bm-neutral-900;
      }

      &:focus-visible {
        outline: 2px solid $bm-neutral-50 !important; /* stylelint-disable-line declaration-no-important */
        outline-offset: map-get(
          $bm_spacers,
          1
        ) !important; /* stylelint-disable-line declaration-no-important */
      }

      @media (hover: hover) {
        &:hover {
          background-color: $bm-neutral-50;
          color: $bm-neutral-900;
        }
      }
    }

    &-dark {
      color: $bm-tertiary;
      background-color: $bm-secondary;
      border: 2px solid $bm-tertiary;

      &:hover {
        color: $bm-tertiary;
      }
      &:focus {
        outline: 2px solid $bm-primary;
      }
      &:focus:not(.focus-visible) {
        outline: none;
        box-shadow: $bm-shadow-dark;
      }
    }

    &-light {
      background-color: $bm-tertiary;
      border: 2px solid $bm-secondary;
    }

    &-transparent {
      color: $bm-tertiary;
      border-color: $bm-tertiary;
      &:hover,
      &:focus {
        color: $bm-tertiary;
        text-decoration: underline;
      }
    }
  }

  &--tiny {
    padding: 0.8rem 1.25rem;

    @include media-breakpoint-up(md) {
      padding: 1rem 2rem;
    }
  }

  &--tight {
    padding: map-get($bm-spacers, 3) map-get($bm_spacers, 6);

    @include media-breakpoint-up(lg) {
      padding: map-get($bm-spacers, 4) map-get($bm_spacers, 7);
    }
  }

  &--small {
    padding: 0.8rem 1.75rem;

    @include media-breakpoint-up(md) {
      padding: 1rem 2rem;
    }
  }

  // PLAY BUTTONS
  &-play {
    width: (50rem / 16);
    height: (50rem / 16);
    padding: 0;

    @include media-breakpoint-up(md) {
      width: (70rem / 16);
      height: (70rem / 16);
    }

    &--primary {
      background-color: $bm-primary;

      &:hover {
        background-color: $bm-primary-dark;
      }

      &:active {
        background-color: $bm-secondary !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    &:disabled {
      &:hover {
        box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    &--small {
      @include flex-center;
      display: inline-flex;
      width: (20rem / 16);
      min-width: 20px;
      height: (20rem / 16);
      padding: 0;

      & > svg {
        width: 7px;
        height: 6px;
      }

      &.bm-btn-align-fix > svg {
        position: relative;
        left: 1px;
      }
    }
  }

  &-text-xs {
    font-size: $font-size-xs;
  }

  &--icon {
    align-items: center;
    display: inline-flex;
    padding: 1rem 1.5rem;

    span {
      margin-left: 0.75rem;
    }

    &--tight {
      padding: 0.75rem 1rem;
    }
  }

  &--icon-only {
    background-color: $bm-neutral-900 !important; /* stylelint-disable-line declaration-no-important */
    padding: 0.5rem;

    @include media-breakpoint-up(md) {
      padding: 0.75rem;
    }

    &:hover {
      background-color: rgba(
        $bm-secondary,
        0.8
      ) !important; /* stylelint-disable-line declaration-no-important */

      color: $bm-white;
      box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  // SOCIAL BUTTONS
  &-social-icon {
    border-radius: map-get($bm_spacers, 1);
    min-width: 5.625rem;
    padding: map-get($bm_spacers, 3) 0;

    &--light {
      border-color: $bm-tertiary;
    }

    & > * {
      pointer-events: none;
    }

    &__facebook {
      background-color: $bm-facebook;
    }

    &__twitter {
      background-color: $bm-twitter;
    }
  }

  // UTILITY BUTTONS
  &-chevron {
    width: (50rem / 16);
    height: (50rem / 16);
    padding: 0;
    background-color: $bm-primary;

    & > svg {
      pointer-events: none;
    }

    &:hover {
      background-color: $bm-primary-dark;
    }
    &:active {
      background-color: $bm-secondary !important; /* stylelint-disable-line declaration-no-important */
    }

    &:disabled {
      &,
      &:hover,
      &:active {
        background-color: rgba($bm-primary, 0.5);
      }
    }
  }

  // Reduced content for postsales sticky btns
  &-cta--copy-reduce {
    & span::before {
      display: block;
    }

    &-workbook span::before {
      content: 'Course Notes';
    }

    &-gift span::before {
      content: 'Gift course';

      @media only screen and (min-width: 440px) {
        content: 'Gift this course';
      }
    }
  }
}

.btn-reset {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  padding: 0;

  &:focus-visible {
    outline: 2px solid $bm-primary-400;
  }
}

// Container for 2 cta buttons that will be equally sized
.bm-btn-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: center;
  }

  /* stylelint-disable */
  & > a:not(.has-underline--slide),
  & > input[type='submit'] {
    width: (190rem / 16);
    margin: 0;

    &:first-child {
      margin-bottom: map-get($spacers, 4);

      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }
    }

    &:nth-of-type(2) {
      margin-left: 0;

      @include media-breakpoint-up(md) {
        margin-left: map-get($spacers, 4);
      }
    }
  }

  &--column {
    width: (220rem / 16);
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
      flex-direction: column;
    }

    & > a,
    & > input[type='submit'] {
      width: 100%;
      text-align: center;

      @include media-breakpoint-up(md) {
        width: 100%;
      }

      &:last-child {
        margin-top: map-get($spacers, 4);
      }
    }

    @media only screen and (min-width: 1530px) {
      flex-direction: row;
      width: auto;

      & > a,
      & > input[type='submit'] {
        &:last-child {
          margin-top: 0;
          margin-left: map-get($spacers, 4);
        }
      }
    }
  }
}

.bg-semiopaque {
  background-color: rgba(black, 0.25);
}

/* stylelint-enable selector-class-pattern */
