// Vertical padding used for layouts
// stylelint-disable selector-class-pattern
$padding-xs: (60rem / 16);
$padding-sm: (90rem / 16);
$padding-md: (110rem / 16);
$padding-lg: (130rem / 16);

/*
  Mixin flow-utilities

  This mixin allows us to loop over the $bm_spacers sass map that is located in
  _tokens.scss file and will allow us to create classes such as "u-vflow-${token}" or "u-hflow-${token}"

  Example:
  .u-vflow-4 {
    margin-block-start: 1rem
  }

  @attribute String, default: "margin-block-start"
  @prefix String, default: "-"
*/

@mixin flow-utilities($attribute: "margin-block-start", $prefix: "-") {
  @each $name, $value in $bm_spacers {
    &#{$prefix}#{$name} > * + * {
      #{$attribute}: $value;
    }
  }
}

.u-vflow {
  @include flow-utilities();
}

.u-hflow {
  @include flow-utilities("margin-inline-start");
}
