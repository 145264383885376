$quote-size: (40rem / 16);

.bm-cms-quote {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100vw - (#{$quote-size} * 2) - 1rem);
  padding: map-get($spacers, 3) 0;
  margin: 0 auto;

  &__quote-icon {
    position: absolute;
    z-index: 1;
    width: $quote-size;
    height: $quote-size;
    opacity: 0.75;

    &:first-of-type,
    &:last-of-type {
      top: (-7rem / 16);
    }

    &:first-of-type {
      left: (-40rem / 16);
    }

    &:last-of-type {
      right: (-40rem / 16);
      transform: rotate(180deg);
    }
  }

  blockquote {
    position: relative;
    z-index: 2;
  }
}
