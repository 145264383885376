// Old hero styling. This has been mostly refactored as
// .bm-hero-new, but a some of this code is still in use
// (gift redemption page). Once no longer used we need to
// clean this up

.bm-hero {
  position: relative;
  padding: 0 (15rem / 16);

  &__content {
    position: relative;
    max-width: 15rem;
    text-align: center;

    @include media-breakpoint-up(sm) {
      max-width: 19rem;
    }

    @include media-breakpoint-up(lg) {
      max-width: 38rem;
    }
  }

  &__bg-video,
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    // Default vjs width & height are applied if the user
    // navigates away & navigates back before next page load,
    // so forcing it here
    width: 100% !important; /* stylelint-disable-line declaration-no-important */
    height: 100% !important; /* stylelint-disable-line declaration-no-important */
    overflow: hidden;

    & > video {
      object-fit: cover;
      object-position: top;
    }

    & .vjs-loading-spinner {
      display: none;
    }
  }

  &__overlay {
    background-color: $bm-hero-overlay;

    &--light {
      background-color: rgba(225, 225, 225, 0.65);
    }
  }

  .bm-campaign-badge {
    top: 5px;
    right: 5px;

    @media only screen and (orientation: landscape) {
      top: 50%;
      right: 25px;
      left: auto;
      transform: translate(0, -50%);
    }

    @include media-breakpoint-up(md) {
      top: 50px;
      right: 50px;
      left: auto;
      transform: none;
    }
  }

  .bm-campaign-badge--as-banner-sm {
    @media only screen and (max-width: 574px) and (orientation: portrait) {
      .bm-campaign-badge {
        transition: none;
        width: calc(100vw - 4em);
        top: -120px;
        left: 50%;
        transform: translateX(-50%);
      }

      // Hide bullets when badge is in banner form
      + .bm-hero__bullets {
        display: none;
      }
    }

    @include media-breakpoint-down(xxs) {
      .bm-campaign-badge {
        top: -100px;
      }
    }
  }

  // GRADIENT LAYOUT
  &--layout-with-gradient .bm-hero {
    &__container {
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }

    &__video,
    &__img {
      position: relative;
      flex-grow: 1;
    }

    &__img {
      picture {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }

    &,
    &__content > * {
      color: $bm-white;
    }

    &__content-gradient-container {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 1rem 0;
      background-color: $bm-secondary-darker;

      &::before {
        position: absolute;
        top: -199px;
        display: block;
        width: 100%;
        height: 200px;
        content: '';
        @include scrimGradient($bm-secondary-darker);
      }
    }

    &__content {
      width: 80%;
      max-width: (370rem / 16);
      text-align: center;
    }

    // ***** Landscape mobiles & xs desktops ****** \\
    @media only screen and (min-width: 320px) and (orientation: landscape) {
      &__video,
      &__img {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      &__video {
        left: 23%;
      }

      &__content-gradient-container {
        height: 100%;
        background: transparent;

        &::before {
          top: 0;
          left: 0;
          width: 100vw;
          height: 100%;
          background: $bm-gradient-hero-mobile;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin: 0 auto 0 15px;
        text-align: left;
      }

      @media only screen and (max-width: 840px) {
        &__scroll-to {
          display: none;
        }
      }
    }

    // Ensure height for desktop screens
    @media only screen and (min-device-width: 992px) {
      &__container {
        height: calc(100vh - 75px);
      }
    }

    // ***** Left & right layout for desktop ****** \\
    @media only screen and (min-width: 992px) and (orientation: landscape) {
      &__container {
        position: relative;
        flex-direction: row;
        min-height: 25rem;
        padding: 0;
      }

      &__img {
        position: absolute;
        width: 100%;
        height: 100%;

        &::after {
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          height: 30%;
          content: '';
          @include scrimGradient($bm-secondary-darker);
        }

        &-img {
          object-position: right top;
        }
      }

      &__video {
        left: (185rem / 16);
      }

      &__content-gradient-container {
        padding: 0;
        background: transparent;

        &::before {
          background: $bm-gradient-hero-tablet-up;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        width: auto;
        min-width: 500px;
        max-width: 580px;
        height: 100%;
        padding: 3rem;
        text-align: left;
      }
    }

    @include media-breakpoint-up(xl) {
      &__content {
        max-width: 635px;

        &__video {
          left: (240rem / 16);
        }
      }
    }

    @include media-breakpoint-up(xxl) {
      &__content {
        max-width: 720px;
      }
    }
  }
}
