.bm-divider {
  &-horizontal {
    width: (75rem / 16);
    height: 2px;
    margin: map-get($spacers, 4) auto;

    @include media-breakpoint-up(lg) {
      width: (120rem / 16);
      // Adding more space to account for descenders above
      margin-top: 2.3rem;
    }
  }

  &-vertical {
    width: 2px;
    height: (75rem / 16);
  }

  &-primary {
    background-color: $bm-primary;
  }

  &-light {
    background-color: $bm-tertiary;
  }
}

.bm-divider-horizontal--sm {
  width: (75rem / 16);
  height: (2rem / 16);
}

.bm-divider-and-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: theme-color('bg-custom-grey-light');

  .bm-divider-horizontal {
    flex-grow: 1;
    height: 1px;
    margin: (36rem / 16) 0;
  }
}
