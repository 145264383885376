.bm-insight {
  @include media-breakpoint-down(xs) {
    margin-bottom: map-get($spacers, 2);
  }

  &__img-container {
    position: relative;
    height: 0;
    // Defines height of img container
    padding-bottom: 104.4%;
    overflow: hidden;

    & img {
      @include abs-center;
      height: 112%;
      transition: height 0.3s linear;
      transform: translate3d(-50%, -50%, 0);
      // Prefix required for chrome
      // stylelint-disable-next-line
      -webkit-perspective: 1000;
      perspective: 1000;
      backface-visibility: hidden;
      will-change: height;
    }
  }

  &--right,
  &--left {
    .bm-insight__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: map-get($spacers, 4) 0;

      .bm-divider-horizontal {
        margin-top: map-get($spacers, 1);
        margin-left: 0;

        @include media-breakpoint-up(lg) {
          margin-top: map-get($spacers, 3);
        }
      }

      .bm-btn-box {
        justify-content: flex-start;
        margin-top: map-get($spacers, 4);
      }
    }
  }

  &--right {
    .bm-insight__content {
      @include media-breakpoint-up(sm) {
        padding: 0 map-get($spacers, 4) 0 map-get($spacers, 1);
      }
    }
  }

  &--left {
    flex-direction: row-reverse;
    justify-content: space-between;

    @include media-breakpoint-up(sm) {
      margin-bottom: map-get($spacers, 5);
    }

    .bm-insight__content {
      @include media-breakpoint-up(sm) {
        padding: 0 map-get($spacers, 4);
      }

      @include media-breakpoint-up(md) {
        padding-right: map-get($spacers, 5);
        padding-left: 0;
      }
    }
  }

  &--4-3 {
    .bm-insight__img-container {
      padding-bottom: (100% * 3 / 4);

      > * {
        width: 100%;
        height: auto;
      }
    }
  }
}
