.bm-card {
  display: block;
  background-color: $bm-white;

  &:hover {
    &,
    & a {
      text-decoration: none;
    }

    & a .bm-card__link-text {
      text-decoration: underline;
    }
  }

  &-link {
    transition: box-shadow 0.4s ease-out, transform 0.4s ease-out;

    &:hover:not(.bm-disabled) {
      box-shadow: $bm-shadow;
      transform: scale(1.03);
    }
  }

  &__link-text {
    max-width: calc(100% - 28px);
    @include text-ellipsis;
  }

  &__img-container {
    flex-shrink: 0;
    height: auto;
  }

  &__img {
    // Ensure img is always square
    position: relative;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;

    & img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }

  // IE fix to force text to wrap
  &__body {
    position: relative;
    width: 100%;
    min-width: 0;
  }

  @include media-breakpoint-up(sm) {
    &__img {
      height: (280rem / 16);
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    &:not(.bm-card--lesson) {
      display: flex;

      .bm-card__img-container {
        width: 33.33%;
      }

      .bm-card__img {
        height: 0;
        padding-bottom: 100%;
      }

      .bm-card__body {
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        justify-content: center;
      }
    }
  }

  @media (min-width: 820px) {
    &__course .bm-card__img-container {
      width: 37%;
    }
  }

  // Styles specific to lesson cards, which need to
  // stack for tablet as well as mobile
  &--lesson {
    max-width: 520px;
    margin: 0 auto;

    .bm-card__body {
      padding: 1rem;

      @include media-breakpoint-up(md) {
        padding: map-get($spacers, 4);
      }

      @include media-breakpoint-up(lg) {
        padding: 0.4rem map-get($spacers, 5);
      }
    }

    h4 {
      @include text-ellipsis;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      max-width: none;

      .bm-card__img-container {
        width: 33.33%;
      }

      .bm-card__img {
        height: 0;
        padding-bottom: 100%;
      }

      .bm-card__body {
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        justify-content: center;
      }
    }
  }
}

.bm-card-in-slider {
  @include media-breakpoint-up(xs) {
    .bm-maestro-card__maestro {
      margin-bottom: map-get($spacers, 2);
      font-size: (24rem / 16);
    }

    .bm-maestro-card__course-title {
      font-size: (12rem / 16);
      line-height: 1.3;
    }
  }

  @include media-breakpoint-up(md) {
    .bm-maestro-card__maestro {
      font-size: (27rem / 16);
    }

    .bm-maestro-card__course-title {
      font-size: (15rem / 16);
    }
  }

  @include media-breakpoint-up(lg) {
    .bm-maestro-card__maestro {
      font-size: (24rem / 16);
    }

    .bm-maestro-card__course-title {
      font-size: (12rem / 16);
    }
  }

  @include media-breakpoint-up(xl) {
    .bm-maestro-card__maestro {
      font-size: (26rem / 16);
    }

    .bm-maestro-card__course-title {
      font-size: (14rem / 16);
    }
  }
}

.bm-article-card-link {
  &:hover {
    text-decoration: none;

    .bm-article-card-link__title {
      text-decoration: underline;
    }
  }

  .bm-article-card-link__title {
    font-size: (24rem / 16);
  }

  &--inline {
    .bm-article-card-link__title {
      @include media-breakpoint-up(md) {
        font-size: (34rem / 16);
      }

      @include media-breakpoint-up(lg) {
        font-size: (52rem / 16);
      }
    }
  }
}
