.bm-nav-toggle--new {
  color: var(--hamburger-menu-text-color);

  &:focus,
  &:focus-visible {
    outline: 2px solid currentcolor !important; // stylelint-disable declaration-no-important
    outline-offset: 2px;
  }
}

.bm-nav-toggle__icon--new {
  height: map-get($bm_spacers, 5);
  position: relative;
  width: map-get($bm_spacers, 5);

  &::before,
  &::after {
    background-color: var(--hamburger-menu-color);
    content: " ";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    transition: transform 0.3s;
    width: map-get($bm_spacers, 5);
  }

  &::before {
    top: 6px;
  }

  &::after {
    top: 12px;
  }
}

.js-nav-toggled .bm-nav-toggle__icon--new::before,
.js-nav-toggled .bm-nav-toggle__icon--new::after {
  background-color: var(--hamburger-menu-color-open);
}

.js-nav-toggled .bm-nav-toggle__icon--new::after {
  transform: translate(-10px, -3px) rotate(-45deg);
}

.js-nav-toggled .bm-nav-toggle__icon--new::before {
  transform: translate(-10px, 3px) rotate(45deg);
}
