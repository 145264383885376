// stylelint-disable value-no-vendor-prefix
.u-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.u-line-clamp--one {
  -webkit-line-clamp: 1;
}
