.bm-loader,
.bm-loader::after {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.bm-loader {
  position: relative;
  margin: 60px auto;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(30, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #fff;
  transform: translateZ(0);
  animation: spin 1.1s infinite linear;

  &--dark {
    border-top: 1.1em solid rgba(30, 30, 30, 0.2);
    border-right: 1.1em solid rgba(30, 30, 30, 0.2);
    border-bottom: 1.1em solid rgba(30, 30, 30, 0.2);
    border-left: 1.1em solid rgba(30, 30, 30);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
