.u-bordered-heading {
  position: relative;
  margin: auto;
  width: max-content;

  &::after {
    background-color: $bm-neutral-200;
    bottom: -(map-get($bm_spacers, 3));
    content: "";
    height: 2px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2;
  }
}

.u-bordered-heading--primary {
  &::after {
    background-color: $bm-primary-500;
  }
}
