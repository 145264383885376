.vc-dialog {
  box-shadow: 4px 4px 14px rgba($bm-black, 0.25);
  color-scheme: dark;
  left: 50%;
  max-height: 90vh;
  max-width: 30rem;
  top: 20%;
  transform: translate(-50%, -5%);
  width: 95%;

  &::backdrop {
    background-color: $bm-neutral-850;
    opacity: 0.85;
  }
}

.vc-dialog[open] {
  animation: fadein 0.5s ease-in forwards;
}

.vc-dialog__close {
  appearance: none;
  right: map-get($bm_spacers, 5);
  top: map-get($bm_spacers, 5);

  &:focus {
    outline-color: $bm-white;
  }
}

.vc-dialog__header {
  padding: map-get($bm_spacers, 10) map-get($bm_spacers, 8)
    map-get($bm_spacers, 6) map-get($bm_spacers, 6);

  @include media-breakpoint-up(lg) {
    padding-left: map-get($bm_spacers, 8);
    padding-right: map-get($bm_spacers, 8);
  }
}

.vc-dialog__body {
  padding: map-get($bm_spacers, 6);

  @include media-breakpoint-up(lg) {
    padding: map-get($bm_spacers, 7) map-get($bm_spacers, 8);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translate(-50%, -5%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 20%);
  }
}
