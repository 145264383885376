.vc-playlist-item {
  --image-height: 3.5rem;
  --image-width: 5rem;

  align-items: center;
  display: grid;
  grid-gap: map-get($bm_spacers, 4);
  grid-template-columns: max-content 1fr;
  padding: map-get($bm_spacers, 2) map-get($bm_spacers, 4);

  @include media-breakpoint-up(lg) {
    padding: map-get($bm_spacers, 4) map-get($bm_spacers, 6);
  }
}

.vc-playlist-item--hover {
  &:hover {
    background-color: $bm-neutral-850 !important; // stylelint-disable
  }
}

.vc-playlist-item__image {
  height: var(--image-height);
  object-fit: cover;
  width: var(--image-width);
}

.vc-playlist-item__faded {
  opacity: 0.6;
}

.vc-playlist-item__locked {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
