.vc-notice {
  position: relative;
  background: $bm-secondary;
  color: $bm-white;
  padding: $padding-xs;
  text-align: center;
  z-index: 100;

  h3 {
    color: $bm-white;
  }

  p {
    color: $bm-grey-light;
    font-size: $font-size-md;
  }

  h3 + p {
    margin-top: 0.5em;
  }

  p + a {
    margin-top: 1.5em;
  }

  &__dismiss {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    line-height: 0.7em;
    font-size: 2em;
    color: $bm-white;
    background: none;
    border: none;
    padding: $padding-xs / 3;
  }
}
