.bm-info-card {
  padding: map-get($bm_spacers, 7) map-get($bm_spacers, 4)
    map-get($bm_spacers, 7);

  @include media-breakpoint-up(lg) {
    padding: map-get($bm_spacers, 9);
  }
}

.bm-info-card__list {
  margin-top: map-get($bm_spacers, 6);

  @include media-breakpoint-up(lg) {
    margin-top: map-get($bm_spacers, 7);
  }
}

.bm-info-card__list > * + * {
  margin-block-start: map-get($bm_spacers, 6);

  @include media-breakpoint-up(lg) {
    margin-block-start: map-get($bm_spacers, 7);
  }
}
