.bm-navbar--new {
  @include media-breakpoint-up(lg) {
    max-width: 300px;
    position: absolute;
    right: 0.9375rem;
    top: 100%;
    width: inherit;
    z-index: 1001;
  }
}

.bm-navbar-content--new {
  @include media-breakpoint-up(lg) {
    background-color: $bm-neutral-750;
    border: 1px solid $bm-neutral-600;
    display: block;
    padding: map-get($bm-spacers, 7);
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 100%;
  }
}

.bm-navbar-content--new.js-nav-expanded {
  @include media-breakpoint-up(lg) {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}

.navbar-expand-lg .bm-navbar-content--new .navbar-nav {
  @include media-breakpoint-up(lg) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
}

.navbar-expand-lg .bm-navbar-content--new .navbar-nav .bm-nav-item {
  @include media-breakpoint-up(lg) {
    margin-right: 0;
    text-align: left;
  }
}

.navbar-expand-lg
  .bm-navbar-content--new
  .navbar-nav
  .bm-nav-item:not(:first-child) {
  margin-top: map-get($bm_spacers, 4);
}

.bm-nav-toggle--new.js-nav-toggled {
  color: var(--search-bar-text-color-open);
}

.bm-nav-toggle--new__button-text {
  color: var(--nav-item-color);
  line-height: 1;
}

.bm-nav-toggle__icon--inverse {
  color: var(--search-bar-text-color);
}

.bm-navbar-content--new .bm-nav-item a:not(.bm-nav-item--cta) {
  color: var(--hamburger-menu-item-text-color);
}
